import { Col, Row, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDivLeft2 from "../../../components/Boton/BotonDivLeft2";
import { ModalCita } from "./ModalCita";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startQuitarCita } from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";

export const SeccionCita = () => {
  const dispatch = useDispatch();
  const [showModalCita, setShowModalCita] = useState(false);
  const { asistenciaID } = useParams();
  const { esCita, fechaCita } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const quitarCita = () => {
    dispatch(startQuitarCita(asistenciaID));
  };


  ////////////////////////////////
  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <BotonDivLeft2
            tituloBoton={esCita === true ? "Modificar Cita" : "Establecer Cita"}
            onClick={() => setShowModalCita(true)}
          />
        </Col>
        <Col span={12} style={{ paddingTop: 2 }}>
          {dateFromString(fechaCita).dateStep}
          {esCita === true && (
            <Tooltip title="Eliminar la cita">
              <CloseCircleOutlined
                onClick={quitarCita}
                style={{ color: "#ca2228", marginLeft: 2, marginTop: 4 }}
                className="eyeIcon"
              />
            </Tooltip>
          )}
        </Col>
      </Row>

      <ModalCita show={showModalCita} close={() => setShowModalCita(false)} />
    </>
  );
};
