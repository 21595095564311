import { Col, Row } from "antd";
import React from "react";

export const HistorialAsignacionItem = ({ historial }) => {
  const { fecha, proveedor, comentarios, costo, estatus, usuario } = historial;
  return (
    <>
      <Row>
        <Col span={8}>
          <b>Fecha:</b>
          <br />
          {fecha}
        </Col>
        <Col span={8}>
          <b>Proveedor:</b>
          <br />
          {proveedor}
        </Col>
        <Col span={8}>
          <b>operador:</b> <br />
          {usuario}
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Costo:</b> <br />
          {costo}
        </Col>
        <Col span={8}>
          <b>Estatus:</b> <br />
          {estatus}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <b>Comentarios:</b>
          <br /> {comentarios}
        </Col>
      </Row>
      <hr />
    </>
  );
};
