import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, message, notification } from "antd";
import axios from "axios";
import { URL_BASE_ASISTENCIAS } from "../../../../constants/url";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const AddLlamada = () => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);

  const {asistenciaID} = useSelector(
    (state) => state.detalleReducer.asistencia
  );
  const {usuarioID} = useSelector((state) => state.userReducer.user);

  const onFinish = async (values) => {
    const llamadas = values.llamadas.map((x) => ({
      asistenciaId: asistenciaID,
      tipoLlamada: x.tipoLlamada,
      usuarioId: usuarioID,
      nombreLlamada: x.nombre,
    }));

    await axios
      .post(`${URL_BASE_ASISTENCIAS}/PostLlamada`, llamadas)
      .then((response) => {
        notification.success({
          message: 'Llamada guardada correctamente',
          description: 'La llamada se guardo correctamente',
          placement: 'bottomRight'
        })
      })
      .catch((error) => {
        notification.error({
          message: "Hubo un error",
          description: "No se puedo agregar la llamada, intentalo mas tarde",
          placement: 'bottomRight'
        });
      });

    form.resetFields();
  };

  useEffect(() => {
    const get = async () => {
      await axios
        .get(`${URL_BASE_ASISTENCIAS}/getCatalogoLlamadas`)
        .then((response) => {
          setOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    get()

  }, []);


  return (
    <Form name='formularioLlamadas' form={form} onFinish={onFinish}>
      <Form.List
        name='llamadas'
        rules={[
          {
            validator: async (_, llamadas) => {
              if (!llamadas || llamadas.length < 1) {
                return Promise.reject(new Error("Al menos una llamada"));
              }
            },
          },
        ]}
      >
        {(fields, {add, remove}, {errors}) => (
          <>
            {fields.map(({key, name, ...restField}) => (
              <Form.Item
                key={key}
                style={{width: "100%", marginBottom: "-28px"}}
              >
                <div style={{display: "flex", gap: "20px"}}>
                  <Form.Item style={{width: "50%"}}>
                    <div className='inputLabel'>Tipo llamada</div>

                    <Form.Item
                      {...restField}
                      name={[name, "tipoLlamada"]}
                      rules={[
                        {
                          required: true,
                          message: "El tipo de la llamada es requerido",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        fieldNames={{label: "text", value: "id"}}
                        allowClear
                        placeholder='Selecciona el tipo de llamada'
                        options={options}
                        filterOption={(input, option) =>
                          (option?.tipo ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item style={{width: "45%"}}>
                    <div className='inputLabel'>Etiqueta llamada</div>

                    <Form.Item
                      {...restField}
                      name={[name, "nombre"]}
                      rules={[
                        {
                          required: true,
                          message: "El nombre de la llamada es requerido",
                        },
                      ]}
                    >
                      <Input placeholder='Nombre llamada' />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <MinusCircleOutlined
                      style={{marginTop: 28}}
                      onClick={() => remove(name)}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            ))}

            <Form.Item>
              <Button type='dashed' onClick={() => add()} block>
                <PlusOutlined /> Agregar llamada
              </Button>
            </Form.Item>

            <Form.Item style={{marginBottom: "-4px"}}>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item>
        <Button type='primary' htmlType='submit' style={{width: "100%"}}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddLlamada;
