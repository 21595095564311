import { Col, Row, Spin } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetDetalle } from "../../../../../context/actions/polizarioActions";

export const ListaPolizas = () => {
  const dispatch = useDispatch();
  const { elAguilaLista, loadingPolizario } = useSelector(
    (state) => state.polizarioReducer
  );

  if (loadingPolizario)
    return (
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <Spin />
      </div>
    );

  const openPoliza = (elaguilaID) => dispatch(startGetDetalle(elaguilaID));

  return (
    <>
      <div
        style={{
          fontSize: 11,
          marginTop: 15,
          maxHeight: 235,
          overflowY: "auto",
        }}
      >
        {elAguilaLista.map((a, i) => (
          <Row
            onClick={() => openPoliza(a.elaguilaID)}
            key={i}
            className="resultadoPolziario"
          >
            <Col span={12}>
              <span className="labelPoliza">Póliza:</span> {a.poliza}
            </Col>

            <Col span={12}>
              <span className="labelPoliza">Contrato: </span> {a.contrato}
            </Col>
            <Col span={24}>
              <span className="labelPoliza">Nombre:</span> {a.nombre}
            </Col>

            <Col span={12}>
              <span className="labelPoliza">Placas:</span> {a.placas}
            </Col>

            <Col span={12}>
              <span className="labelPoliza">Cobertura:</span> {a.cobertura}
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};
