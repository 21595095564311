import { Button, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";
import { ListaIncidencias } from "./_ListaIncidencias";

export const BotonListadoIncidencias = () => {
  const [open, setOpen] = React.useState(false);
  const { asistencia } = useSelector((state) => state.detalleReducer);

  const texto = `Ver Incidencias (${asistencia.incidencias.length})`;
  //
  if (asistencia.incidencias.length === 0)
    return <div style={{ paddingTop: 23, paddingLeft: 10 }}></div>;

  //
  return (
    <>
      <div style={{ paddingTop: 20, paddingLeft: 10 }}>
        <Button onClick={() => setOpen(true)} type="link">
          {texto}
        </Button>
      </div>

      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button size="small" onClick={() => setOpen(false)}>
            Cerrar
          </Button>,
        ]}
        title={<TituloModal titulo="Incidencias en la Asistencia" />}
      >
        <ListaIncidencias />
      </Modal>
    </>
  );
};
