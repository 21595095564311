import { CheckCircleOutlined, PullRequestOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { LocalCarretero } from "./LocalCarretero";

export const RenglonCita = ({
  asistencia: {
    estatusID,
    campania,
    ubicacion,
    servicio,
    operador,
    asistenciaID,
    proveedor,
    localCarretero,
    esCita,
    esConexion,
    esGnpApi,
    fechaSugiereProveedor,
    fechaProveedorValidado
  },
  fecha,
}) => {
  ///////



  const history = useHistory();
  const handleClick = () => history.push(`/detalleAsistencia/${asistenciaID}`);
  return (
    <div onClick={handleClick} className={`semaforo__renglon`}>
      <Row>
        <Col span="21">
          <div>
            <span className={esGnpApi && "esGnpApi"}>{asistenciaID}</span>  | {operador} | {servicio}
          </div>
          <div>
            <span></span>
          </div>
          {estatusID > 1 && <div>{ubicacion}</div>}
          {estatusID > 2 && <div>{proveedor}</div>}
          <div>{campania}</div>
        </Col>
        <Col span="3" style={{ textAlign: "center" }}>
          <LocalCarretero
            localCarretero={localCarretero}
            estatusID={estatusID}
          />


          {esConexion && (
            <PullRequestOutlined
              style={{ marginTop: 2, fontSize: 25, color: "#999" }}
            />
          )}

          {estatusID > 2 && fechaProveedorValidado && <CheckCircleOutlined style={{ fontSize: 22, color: "#999" }} />}
        </Col>
      </Row>


    </div >
  );
};
