import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startLoadServicios } from "../../context/actions/asistenciasActions";
import CardPage from "../../layout/CardPage";
import { Listado } from "./Listado";

export const TodosServicios = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const d = new Date();
    const mes = Number(d.getMonth()) + 1;
    const anio = d.getFullYear();

    dispatch(startLoadServicios({ mes, anio }));
  }, []);

  return (
    <CardPage titulo="Servicios">
      <Listado />
    </CardPage>
  );
};
