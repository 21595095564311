import CardPage from "../../../layout/CardPage";
import { CuerpoDetalle } from "./CuerpoDetalle";
import MenuDetalle from "./MenuDetalle";

export const Folder = () => {
  return (
    <>
{/*
       <CardPage tituloBoton='Agregar llamada' accionBoton={() => setOpen(true)}>
        <MenuDetalle />
        <CuerpoDetalle />
      </CardPage>
*/}

      <CardPage>
        <MenuDetalle />
        <CuerpoDetalle />
      </CardPage>
    </>
  );
};
