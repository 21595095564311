import { notificationInitial } from "../initial/notificationInitial";
import { types } from "../types/types";

const initial = {
  elAguilaLista: [],
  elAguilaPoliza: {},
  clienteID: "",
  loadingPolizario: false,
  vistaResultados: 0, // 0 sin busqueda, 1 lista, 2 detalle
  qualitasResponse: {}
};
export const polizarioReducer = (state = initial, action) => {
  switch (action.type) {
    case types.setQualitasResponse:
      return {
        ...state,
        qualitasResponse: action.payload,
      };
    case types.setVistaResultados:
      return {
        ...state,
        vistaResultados: action.payload,
      };
    case types.setLoadingPolizario:
      return {
        ...state,
        loadingPolizario: action.payload,
      };
    case types.setClienteID:
      return {
        ...state,
        clienteID: action.payload,
      };

    case types.storeElAguilaPoliza:
      return {
        ...state,
        elAguilaPoliza: action.payload,
      };
    case types.storeElAguilaLista:
      return {
        ...state,
        elAguilaLista: action.payload,
      };
    default:
      return state;
  }
};
