import React from "react";
import { useSelector } from "react-redux";
import { Item } from "./Item";

export const Listado = () => {
  const { asistenciaHistorial } = useSelector((state) => state.detalleReducer);
  return (
    <div style={{ maxHeight: 400, overflowY: "auto" }}>
      {asistenciaHistorial.map((a, i) => (
        <Item key={i} historial={a} />
      ))}
    </div>
  );
};
