import { Col, Row, Tooltip } from "antd";
import { CopyOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import {
  startTerminaCaptura,
  stratSendSMS,
} from "../../../../context/actions/capturaActions";
import { SimboloSMS } from "./SimboloSMS";

export const BotonFase3 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { campaniaID } = useParams();
  const [err, setErr] = useState("");

  const { latOrigen, latDestino, asistenciaID } = useSelector(
    (state) => state.asistenciasReducer.captura
  );
  const [url, setUrl] = useState(
    `https://latlng.escotel.mx/read/${asistenciaID}`
  );
  const [textoCopiar, setTextoCopiar] = useState("copiar");
  const handleClick = () => {
    setErr("");
    if (!latOrigen || latDestino) {
      setErr("Falta origen");
      return;
    }
    dispatch(startTerminaCaptura(campaniaID)).then((result) => {
      if (result.code === 0)
        history.push(`/detalleAsistencia/${result.data.asistenciaID}`);
    });
  };

  const handleSMS = () => {
    //  setDisabled(true);
    dispatch(stratSendSMS());
  };


  const handleCopiar = () => {
    navigator.clipboard.writeText(url);
    setTextoCopiar("copiado...");
    setTimeout(() => {
      setTextoCopiar("copiar");
    }, 1000);
  };
  ////////////
  return (
    <>
      <div style={{ padding: 10 }}>
        {url}
        <span
          style={{ marginLeft: 8 }}
          className="copiar"
          onClick={handleCopiar}
        >
          {textoCopiar === "copiar" ? (
            <Tooltip title="Copiar URL para enviar al usuario">
              <CopyOutlined style={{ color: "#ca2228", fontSize: 15 }} />
            </Tooltip>
          ) : (
            <CheckCircleOutlined style={{ color: "#ca2228", fontSize: 15 }} />
          )}
        </span>
      </div>
      <Row gutter={10}>
        <Col span={6}>
          <BotonSubmit
            disabled={disabled}
            tituloBoton="Enviar Página x SMS"
            onClick={handleSMS}
          />
        </Col>
        <Col span={1}>
          <SimboloSMS />
        </Col>
        <Col span={4}>
          {/* <BotonSubmit tituloBoton="Enviar Email" onClick={handleEmail} /> */}
        </Col>
        <Col span={4} style={{ textAlign: "right", paddingTop: 3 }}>
          <span style={{ color: "#ca2228" }}>{err}</span>
        </Col>
        <Col xs={4} lg={3} xl={2}>
          <BotonSubmit tituloBoton="Generar Servicio" onClick={handleClick} />
        </Col>
      </Row>
    </>
  );
};
