import { Select } from "antd";

const { Option } = Select;

export const TipoHistorialSelect = ({ value, onChange }) => {
  return (
    <>
      <div className="labelImput">Tipo de historial</div>
      <Select
        value={value}
        name="tipoPersona"
        onChange={onChange}
        style={{ width: "100%" }}
      >
        <Option value="Proveedor">Proveedor</Option>
        <Option value="Usuario">Usuario</Option>
        <Option value="Corporativo">Corporativo</Option>
        <Option value="Cliente">Cliente</Option>
        <Option value="Sistema">Sistema</Option>

      </Select>
    </>
  );
};
