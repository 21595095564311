import { Col, Row } from "antd";
import { LabelValor } from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";

export const DatosGeneral = () => {
  const {
    folioAsignacion,
    numeroPoliza,
    nombreReportante,
    apellidoPaternoReportante,
    apellidoMaternoReportante,
    cuentaEspecial,
    clienteVip,
    numeroContacto,
    email,
    fechaReporte,
    horaReporte,
    coberturaAfectada,
    tipoServicio,
    estatusServicio,
    folioInternoGNP,
    limitesCobertura,
    descripcionServicio,
  } = useSelector((state) => state.gnpApiReducer.siniestro);

  return (
    <>
      <div style={{ marginLeft: 5 }} className="subTitulo">
        Generales
      </div>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={4}>
          <LabelValor label="Folio asignación" valor={folioAsignacion} />
        </Col>
        <Col span={4}>
          <LabelValor label="Número de póliza" valor={numeroPoliza} />
        </Col>
        <Col span={4}>
          <LabelValor label="Fecha reporte" valor={fechaReporte} />
        </Col>
        <Col span={4}>
          <LabelValor label="Hora reporte" valor={horaReporte} />
        </Col>
        <Col span={4}>
          <LabelValor label="Cuenta especial" valor={cuentaEspecial} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor
            label="Nombre"
            valor={`${nombreReportante} ${apellidoPaternoReportante} ${apellidoMaternoReportante}`}
          />
        </Col>
        <Col span={4}>
          <LabelValor label="Teléfono" valor={numeroContacto} />
        </Col>
        <Col span={4}>
          <LabelValor label="Email" valor={email} />
        </Col>
        <Col span={4}>
          <LabelValor label="VIP" valor={clienteVip} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={4}>
          <LabelValor label="Cobertura afectada" valor={coberturaAfectada} />
        </Col>
        <Col span={4}>
          <LabelValor label="Tipo de servicio" valor={tipoServicio} />
        </Col>
        <Col span={4}>
          <LabelValor label="Estatus" valor={estatusServicio} />
        </Col>
        <Col span={4}>
          <LabelValor label="Folio interno GNP" valor={folioInternoGNP} />
        </Col>
        <Col span={4}>
          <LabelValor label="Limite cobertura" valor={limitesCobertura} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={20}>
          <LabelValor label="Descripción" valor={descripcionServicio} />
        </Col>
      </Row>
    </>
  );
};
