import { URL_BASE_ASISTENCIAS } from "../../constants/url";
import { composeErrorNotification } from "../../utils/composeNotification";
import { get } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetServicioParaSemaforo = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetServicioParaSemaforo`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAsistenciasSemaforo(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get semaforo",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const storeAsistenciasSemaforo = (asistencias) => ({
  type: types.storeAsistenciasSemaforo,
  payload: asistencias,
});

/////////////////////////////

export const startGetAsistenciasCitas = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciasCitas`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeAsistenciasCitas(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAsistenciasCitas", `${result.error}`)
        )
      );
    }
  };
};

export const storeAsistenciasCitas = (asistencias) => ({
  type: types.storeAsistenciasCitas,
  payload: asistencias,
});
