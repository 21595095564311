export const getAhora = () => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const h = agregaCero(today.getHours());
  const m = agregaCero(today.getMinutes());
  const s = agregaCero(today.getSeconds());

  return `${h}:${m}:${s}`;
};

const agregaCero = (numero) => (numero < 10 ? `0${numero}` : `${numero}`);
