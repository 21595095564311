import { Button, Col, Input, Row, Select } from "antd";
import React from "react";
import { useForm } from "./useForm";
import { useFormIncidencia } from "./useFormIncidencia";

//
export const ServicioAgregarActividad = ({ servicio }) => {
  const { Option } = Select;

  const { body, handleChange, handleChangeSelect, handleClick } =
    useForm(servicio);
  const { incidencia, changeIncidencia, submitIncidencia } =
    useFormIncidencia(servicio);
  //
  return (
    <Row gutter={10}>
      <Col span={4}>
        <Select
          value={body.tipoActividadSeguimientoID}
          name="tipoActividadSeguimientoID"
          onChange={(value) =>
            handleChangeSelect("tipoActividadSeguimientoID", value)
          }
          style={{ width: "100%" }}
          placeholder="Tipo de actividad"
        >
          <Option value={1}>Llamada</Option>
          <Option value={2}>Whatsapp</Option>
          <Option value={3}>Correo</Option>
          <Option value={4}>SMS</Option>
          <Option value={5}>Otro</Option>
        </Select>
      </Col>
      <Col span={6}>
        <Input
          onChange={handleChange}
          name="comentarios"
          value={body.comentarios}
          placeholder="Comentarios"
        />
      </Col>
      <Col span={4}>
        <Button onClick={handleClick} type="primary">
          Agregar
        </Button>
      </Col>
      <Col span={4}>
        <Select
          value={incidencia}
          name="tipoActividadSeguimientoID"
          onChange={(value) =>
            changeIncidencia("tipoActividadSeguimientoID", value)
          }
          style={{ width: "100%" }}
          placeholder="Incidencia"
        >
          <Option value={"Pendiente autorización"}>Pendiente autorización</Option>
          <Option value={"Zona roja"}>Zona roja</Option>
          <Option value={"Servicio especial"}>Servicio especial</Option>
        </Select>
      </Col>
      <Col span={4}>
        <Button onClick={submitIncidencia} type="primary" danger>
          Agregar
        </Button>
      </Col>
    </Row>
  );
};
