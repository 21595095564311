import { types } from "../types/types";

export const setErrBuscaValidador = (elemento) => ({
  type: types.setErrBuscaValidador,
  payload: elemento,
});

export const tieneErroresBuscaValidador = () => {
  return (dispatch, getState) => {
    dispatch(cleanErroresBuscaValidador());
    let erroresCont = 0;
    const { nombre, apellidoPaterno, telefono, validador1 } =
      getState().asistenciasReducer.captura;

    if (nombre === "" || nombre === null || nombre === undefined) {
      dispatch(setErrBuscaValidador({ nombreErr: "Nombre necesario" }));
      erroresCont++;
    }

    if (
      apellidoPaterno === "" ||
      apellidoPaterno === null ||
      apellidoPaterno === undefined
    ) {
      dispatch(
        setErrBuscaValidador({ apellidoPaternoErr: "Apellido necesario" })
      );
      erroresCont++;
    }

    if (validador1 === "" || validador1 === null || validador1 === undefined) {
      dispatch(setErrBuscaValidador({ validador1Err: "Validador necesario" }));
      erroresCont++;
    }

    if (telefono === "" || telefono === null || telefono === undefined) {
      dispatch(setErrBuscaValidador({ telefonoErr: "Teléfono necesario" }));
      erroresCont++;
    } else if (telefono.length !== 10) {
      dispatch(setErrBuscaValidador({ telefonoErr: "Teléfono 10 dígitos" }));
      erroresCont++;
    }
    return erroresCont !== 0;
  };
};

const cleanErroresBuscaValidador = () => ({
  type: types.cleanErroresBuscaValidador,
  payload: null,
});
