import { Row, Col } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startLoadGnpEstatus } from "../../context/actions/gnpApiActions";
import CardPage from "../../layout/CardPage";
import { BotonGnpApi } from "./BotonGnpApi";
import { BotonMapaServicios } from "./MapServicios/BotonMapaServicios";

export const Recursos = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadGnpEstatus());
  }, []);

  return (
    <CardPage titulo="Recursos">
      <Row gutter={16}>
        <Col span={3}>
          <BotonGnpApi />
        </Col>

        {/* <Col span={3}>
          <BotonMapaServicios/ >
        </Col> */}
      </Row>
    </CardPage>
  );
};
