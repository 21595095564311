import { Col, Row } from "antd";
import React from "react";

export const Item = ({
  asistencia: { asistenciaID, fechaLlamadaIniciada, servicio, estatusFinal },
}) => {
  return (
    <Row>
      <Col span={6}>{asistenciaID}</Col>
      <Col span={6}>{fechaLlamadaIniciada}</Col>
      <Col span={6}>{servicio}</Col>
      <Col span={6}>{estatusFinal}</Col>
    </Row>
  );
};
