import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useColumns } from "./useColumns";

export const Listado = () => {
  const history = useHistory();
  const { citasAbiertas } = useSelector((state) => state.asistenciasReducer);
  const { columns } = useColumns();
  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) =>
            history.push(`/detalleAsistencia/${record.asistenciaID}`),
        };
      }}
      rowClassName="renglonTabla"
      size="small"
      rowKey="asistenciaID"
      dataSource={citasAbiertas}
      columns={columns}
    />
  );
};
