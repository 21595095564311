import React from "react";
import { useHistory } from "react-router-dom";
import CardPage from "../../layout/CardPage";
import { Col, Row } from "antd";

export const SemaforoHome = () => {
  const history = useHistory();

  return (
    <CardPage titulo="Semáforos">
      <Row gutter={10}>
        <Col xs={12} xl={8}>
          <div
            className="botonRecursos"
            onClick={() => history.push("/semaforo")}
          >
            Semáforo General
          </div>
        </Col>
        <Col xs={12} xl={8}>
          <div
            className="botonRecursos"
            onClick={() => history.push("/semaforoSeguimiento")}
          >
            Semáforo Seguimiento
          </div>
        </Col>
      </Row>
    </CardPage>
  );
};
