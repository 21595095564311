import { URL_ASISTENCIA_INCIDENCIA } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { startLoadAsistencia } from "./detalleActions";
import { notification } from "./notificationActions";

export const startSetIncidencia = (body) => {
  return async (dispatch) => {
    const result = await post(
      `${URL_ASISTENCIA_INCIDENCIA}/SetIncidencia`,
      body
    );
    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false))
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
};
