import { Col, Row } from "antd";
import { BuscandoProveedor } from "./buscandoProveedor/BuscandoProveedor";
import { CapturandoDatos } from "./capturandoDatos/CapturandoDatos";
import { Citas } from "./citas/Citas";
import { PorCerrarServicio } from "./porCerrarServicio/PorCerrarServicio";
import { PorContactarUsuario } from "./porContactarUsuario/PorContactarUsuario";
import { useGetSemaforo } from "../semaforoHome/semaforoSeguimiento/useGetSemaforo";

export const Semaforo = () => {
  useGetSemaforo()

  return (
    <Row gutter={10}>
      <Col span={6}>
        <CapturandoDatos />
        <div style={{ marginTop: 10 }}>
          <Citas />
        </div>
      </Col>
      <Col span={6}>
        <BuscandoProveedor />
      </Col>
      <Col span={6}>
        <PorContactarUsuario />
      </Col>
      <Col span={6}>
        <PorCerrarServicio />
      </Col>
    </Row>
  );
};
