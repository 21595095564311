import React from "react";
import { CAMPO_TIPO } from "../../../../constants/consLogged";
import { CampoLista } from "./CampoLista";
import { CampoTexto } from "./CampoTexto";

export const Campo = ({ campo, i }) => {
  if (campo.tipo === CAMPO_TIPO.TEXTO)
    return <CampoTexto campo={campo} i={i} />;
  if (campo.tipo === CAMPO_TIPO.LISTA)
    return <CampoLista campo={campo} i={i} />;

  return <div>err</div>;
};
