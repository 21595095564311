import { useState } from "react"
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks"
import { MAP_KEY, mapOptions } from "../../constants/cons"
//
import { Marker } from "./Marker"
import { AutoComplete } from "./AutoComplete"
import { LatLng } from "./LatLng"
import { Direccion } from "./Direccion"
import { Col, Row } from "antd"


//
export const MapaGoogle = ({getDatos, latLng, setLatLng}) => {
  const [mapContainer, setMapContainer] = useState()

  return (
    <GoogleMapsProvider
      libraries={['places']}
      googleMapsAPIKey={MAP_KEY}
      mapOptions={mapOptions}
      mapContainer={mapContainer}
    >

      <div className="parent">
        <div className="childfix">
          <Row gutter={10}>
            <Col><AutoComplete latLng={latLng} setLatLng={setLatLng}/></Col>
            <Col><LatLng latLng={latLng} setLatLng={setLatLng}/></Col>
          </Row>
        </div>

        <div style={{height: '78vh',}} ref={(node) => setMapContainer(node)}>
        </div>
        <Marker latLng={latLng} setLatLng={setLatLng}/>
        <div>
          <Direccion latLng={latLng} setLatLng={setLatLng} getDatos={getDatos}/>
        </div>
      </div>
    </GoogleMapsProvider>

  )
}




