import React from "react";

export const useFormIncidencia = (servicio) => {
  const [incidencia, setIncidencia] = React.useState({
    asistenciaID: servicio.asistenciaID,
  });

  const submitIncidencia = () => {
    console.log(incidencia);
  };

  const changeIncidencia = (name, value) => {
    setIncidencia({
      ...incidencia,
      [name]: value,
    });
  };

  return { submitIncidencia, changeIncidencia, setIncidencia };
};
