import { Col, Row } from "antd";
import { MapaImagen } from "./MapaImagen";

export const Mapa = () => {
  return (
    <Row>
      <Col span={18}>
        <MapaImagen />
      </Col>
      <Col span={6}></Col>
    </Row>
  );
};
