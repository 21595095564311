import { useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import {
  startGetCatServiciosGNP,
  startLoadSiniestrosByEstatus,
} from "../../../context/actions/gnpApiActions";
import CardPage from "../../../layout/CardPage";
import { SiniestrosLista } from "./SiniestrosLista";
import { SiniestrosNumero } from "./SiniestrosNumero";
import { gnp_estatus } from "../../../constants/gnp_cons";

export const GnpApi = () => {
  const { clave } = gnp_estatus.ASIGNADO;
  //const { clave } = gnp_estatus.ARRIBO;
  const dispatch = useDispatch();

  useEffect(() => {
   void dispatch(startLoadSiniestrosByEstatus(clave));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      void dispatch(startLoadSiniestrosByEstatus(clave));
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <CardPage titulo="Servicios GNP - API">
      <Row gutter={20}>
        <Col span={6}>
          <SiniestrosNumero clave={clave} />
        </Col>
        <Col span={18}>
          <SiniestrosLista />
        </Col>
      </Row>
    </CardPage>
  );
};
