import { useDispatch, useSelector } from "react-redux";
import { ClockCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import {
  startDownloadPlacas,
  startSetStep,
  startUpdateFecha,
} from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";
import { DatePicker, Modal } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const Contacto = ({ asistencia: { estatusID, fechaContacto } }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState("");
  const { asistenciaID } = useParams();
  const { estatusFinalID, tienePlacas, nombrePlacas } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const handleClick = () => dispatch(startSetStep());

  const handleModalOK = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 2, // contacto

    };
    dispatch(startUpdateFecha(body));
    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);
  const handlePlacas = () => {
    dispatch(startDownloadPlacas(asistenciaID, nombrePlacas))
  }
  ////////////////////////////
  if (estatusID === 4 && estatusFinalID === 1)
    return (<div>

      <div onClick={handleClick} className="marcarStep">
        <PauseCircleOutlined /> Marcar contacto
      </div>
      {tienePlacas && <div onClick={handlePlacas} style={{ marginTop: 3 }} className="marcarStep">ver placas</div>}
    </div>
    );

  ////////////////////////////
  const date = dateFromString(fechaContacto);

  if (estatusID > 4)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Contacto"
          open={showModal}
          onOk={handleModalOK}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
        {tienePlacas && <div onClick={handlePlacas} style={{ marginTop: 3 }} className="marcarStep">ver placas</div>}
      </div>
    );

  ////////////////////////////
  return <div></div>;
};
