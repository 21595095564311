import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startLoadMisServicios } from "../../context/actions/asistenciasActions";
import CardPage from "../../layout/CardPage";
import { Listado } from "./Listado";

export const MisServicios = () => {
  const dispatch = useDispatch()
  
  useEffect(()=>{
    dispatch(startLoadMisServicios())
  },[])
  
  
  return (
    <CardPage titulo="Mis Servicios Abiertos">
      <Listado />
    </CardPage>
  );
};
