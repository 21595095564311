import { Select } from "antd";

const { Option } = Select;

export const TipoPersonaSelect = ({ value, onChange }) => {
  return (
    <>
      <div className="labelImput">Tipo de persona</div>
      <Select
        value={value}
        name="tipoPersona"
        onChange={onChange}
        style={{ width: "100%" }}
      >
        <Option value="Asegurado">Asegurado</Option>
        <Option value="Beneficiario">Beneficiario</Option>
      </Select>
    </>
  );
};
