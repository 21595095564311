import { MapaGoogle } from '../../components/mapaGoogle/MapaGoogle';
import React, { useState } from 'react';

export const Mapa = ({setGeoDatos}) => {

  const [latLng, setLatLng] = useState({ lat: 19.364612026633605, lng: -99.18838994738087 })

  const getDatos = datos => {
    const body = {
      direccionOrigen: datos.direccionCompleta,
      municipioOrigen: datos.municipio,
      estadoOrigen: datos.estado,
      cpOrigen: datos.cpOrigen,
      latOrigen: datos.lat.toString(),
      lngOrigen: datos.lng.toString(),
    }
    setGeoDatos(body)
  }

  return (
    <>

      <MapaGoogle
        getDatos={getDatos}
        latLng={latLng}
        setLatLng={setLatLng}
      />
    </>
  )
};
