import { SendOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useSelector } from "react-redux";
import "./formularioMensaje.css";

const FormularioMensajes = ({
  messages,
  setMessages,
  asistenciaID,
  width = 1000,
}) => {
  const { connection } = useSelector((state) => state.chatReducer);
  const proveedorSucursal = useSelector(
    (state) => state.detalleReducer.asistencia.proveedorSucursal
  );

  const { usuarioID, nombre } = useSelector((state) => state.userReducer.user);
  const [form] = Form.useForm();

  const onFinish = ({ mensaje }) => {
    const message = {
      asistenciaId: asistenciaID,
      emisorId: usuarioID,
      emisorNombre: nombre,
      tipoMensaje: 1,
      texto: mensaje,
      tipoUsuario: 1,
      fecha: new Date()
    };

    if (messages.length > 0) {
      connection.send("SendMensajeCabina", message);
    }

    if (messages.length === 0) {
      const newChat = {
        asistenciaId: asistenciaID,
        proveedorId: proveedorSucursal?.proveedorID,
        mensajesProveedorNoLeidos: true,
        mensajesCabinaNoLeidos: false,
        chatActivo: true,
        mensajes: [
          {
            emisorId: usuarioID,
            emisorNombre: nombre,
            texto: mensaje,
            tipoUsuario: 1
          },
        ],
      };

      connection.send('NuevoChat', newChat);
    }

    setTimeout(() => {
      connection.send("StopTyping", asistenciaID);
    }, 500);

    setMessages([...messages, message]);

    const final = document.getElementById("principio");
    final.scrollTop = final.scrollHeight;

    form.resetFields();
  };

  const onChangeInput = (e) => {
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      connection.send("StopTyping", asistenciaID);
    } else if (e.nativeEvent.inputType === "insertText") {
      connection.send("AlguienEscribiendo", nombre, asistenciaID);
    }
  };

  function debonce(func, timeout = 200) {
    let timer;

    return (...arg) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, arg);
      }, timeout);
    };
  }

  const change = debonce(onChangeInput);

  return (
    <>
      <div className="main">
        <Form
          form={form}
          name="formularioMensajes"
          layout="inline"
          onFinish={onFinish}
        >
          <Form.Item
            name="mensaje"
            rules={[
              {
                required: true,
                message: "El mensaje es necesario",
              },
            ]}
          >
            <Input
              autoFocus
              style={{ width }}
              placeholder="Escribe un mensaje aqui"
              onChange={change}
            />
          </Form.Item>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              shape="circle"
              icon={<SendOutlined />}
            />
          </FormItem>
        </Form>
      </div>
    </>
  );
};

export default FormularioMensajes;
