import React from "react";
import { useSelector } from "react-redux";
import { Item } from "./Item";

export const ModalRelacionarDatos = () => {
  const { asistenciasByValidador1 } = useSelector(
    (state) => state.detalleReducer
  );

  if (asistenciasByValidador1.length === 0)
    return <>Sin asistencias relacionadas</>;

  return (
    <div>
      {asistenciasByValidador1.map((a, i) => (
        <Item key={i} asistencia={a} />
      ))}
    </div>
  );
  return <div>ModalRelacionarDatos</div>;
};
