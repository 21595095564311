import { Button, Form, message, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { URL_BASE } from "../../../constants/url";
import { get } from "../../../utils/fetch";
import { getConditions } from "../../../context/actions/libraryActions";

export const ModalEliminarPDF = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const conditions = useSelector((state) => state.libraryReducer.conditions);
  const conditionsArray = conditions.reduce(
    (acc, option) => acc.concat(option.condiciones),
    []
  );
  const options = conditionsArray.map((option) => ({
    label: option.nombreArchivo,
    value: option.id,
  }));

  const deletePDF = async (values) => {
    const result = await get(
      `${URL_BASE}/api/Clientes/DeleteCondicionesDocumento/` + values.idPDF
    );

    if (result.code === 0) {
      message.success(result.data);
    } else {
      message.error(result.error);
    }
    setOpen(false);
    dispatch(getConditions());
  };

  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <Form
          onFinish={deletePDF}
          layout={"vertical"}
          style={{ marginTop: "30px" }}
        >
          <Form.Item
            name={"idPDF"}
            label={"Seleccione un archivo"}
            rules={[
              { required: true, message: "Seleccione un archivo a eliminar" },
            ]}
          >
            <Select options={options} />
          </Form.Item>

          <Button danger type={"primary"} htmlType={"submit"}>
            Eliminar
          </Button>
        </Form>
      </Modal>
    </>
  );
};
