import { Button, Col, Row, Select } from "antd";
import React from "react";
import { motivoIncidenciaAsignacionOpciones } from "../../../../constants/cons";
import { useIncidenciaForm } from "./useIncidenciaForm";

export const IncidenciasAsignacion = () => {


  const { body, handleChange, handleSubmit } = useIncidenciaForm(1);



  return (
    <Row gutter={5}>
      <Col span={16}>
        <div className="inputLabel">Incidencia Asignando</div>
        <Select
          onChange={handleChange}
          value={body.motivoIncidenciaAsignacion}
          size="small"
          style={{ width: "100%" }}
          options={motivoIncidenciaAsignacionOpciones}
        />
      </Col>
      <Col span={8} style={{ paddingTop: 22 }}>
        <Button onClick={handleSubmit} size="small" type="primary">
          Agregar
        </Button>
      </Col>
    </Row>
  );
};
