import { useState, useRef } from "react"
import { useAutocomplete } from "@ubilabs/google-maps-react-hooks"


export const AutoComplete = ({ setLatLng }) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const onPlaceChanged = place => {
        setLatLng({ 
                lat: Number(place.geometry.location.lat()), 
                lng: Number(place.geometry.location.lng())
            })


        if (place) 
            setInputValue(place.formatted_address || place.name);

        inputRef.current && inputRef.current.focus();
    };

    useAutocomplete({
        inputField: inputRef && inputRef.current,
        onPlaceChanged
    });

    const handleInputChange = event => {
        setInputValue(event.target.value);
    };

    return (

        <input
            className="inputBusca _550"
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Buscar en Google Maps"
        />

    );
};
