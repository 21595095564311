import React from "react";

export const LabelValor = ({ label, valor, style }) => {
  return (
    <div
      style={{...style}}
    >
      <div className="label">{label}</div>
      <div className="valor">{valor}</div>
    </div>
  );
};
