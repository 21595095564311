import React from "react";

export const Item = ({ historial: { fecha, nombre, comentarios, tipo } }) => {
  return (
    <div style={{}}>
      <span style={{ color: "#ca2228" }}>
        {fecha} {nombre}:{" "}
      </span>{" "}
      <span style={{ color: "teal" }}>{tipo}</span> <span>{comentarios}</span>
    </div>
  );
};
