import { Divider, Form, Image, Select } from "antd";
import Create from "./Create";
import { UploadPlacas } from "../../../../components/uploadPlacas/UploadPlacas";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL_BASE_ASISTENCIAS } from '../../../../constants/url';
import { useParams } from 'react-router-dom';

const options = [
  { value: "otro", label: "Otro" },
  { value: "placas", label: "Placas" }
]

function FormUploadFiles() {
  const [ typeFile, setTypeFile ] = useState('placas')
  const [ url, setUrl ] = useState(null)
  const { asistenciaID} = useParams();

  const { urlPlaca } = useSelector(state => state.detalleReducer.asistencia)
  return (
    <div style={{ display: "flex", gap: "30px", alignItems: "end", marginBottom: "30px" }}>
      <div>

        <Select
          options={options}
          style={{ width: "200px" }}
          onChange={(value) => setTypeFile(value)}
          defaultValue={typeFile}
          placeholder={'Seleccione el tipo de archivo'}
        />

      </div>

      {typeFile === 'placas' && <UploadPlacas url={url} setUrl={setUrl} />}

      {typeFile === 'otro' && <Create />}


      <div style={{ width: 'auto' }}>
        <Image
          src={url ?? `${URL_BASE_ASISTENCIAS}/getPlacaImage/${asistenciaID}`} width={250} height={150}
        />
      </div>
    </div>
  )
}

export default FormUploadFiles;