import React from "react";
import { useSelector } from "react-redux";
import { PAGINA_DETALLE } from "../../../constants/cons";
import { Archivos } from "./archivos/Archivos";
import { Historial } from "./hisotiral/Historial";
import { Mapa } from "./mapa/Mapa";
import { Proveedor } from "./proveedor/Proveedor";
import Chat from "./chat/Chat";
import AddLlamada from "./llamadas/AddLlamada";


export const CuerpoDetalle = () => {
  const { folderPagina } = useSelector((state) => state.detalleReducer);
  if (folderPagina === PAGINA_DETALLE.proveedor) return <Proveedor />
  if (folderPagina === PAGINA_DETALLE.mapa) return <Mapa />
  if (folderPagina === PAGINA_DETALLE.archivos) return <Archivos />
  if (folderPagina === PAGINA_DETALLE.hisotrial) return <Historial />
  if (folderPagina === PAGINA_DETALLE.chat) return <Chat />
  if (folderPagina === PAGINA_DETALLE.llamadas) return <AddLlamada />
  // if (folderPagina === PAGINA_DETALLE.placas) return <UploadPlacas />


  return <>Err</>;
};
