import React, { useState } from "react";
import BotonDivLeft2 from "../../../components/Boton/BotonDivLeft2";
import { ModalRelacionar } from "./ModalRelacionar";

export const SeccionRelacionar = () => {
  const [showModalRelacionar, setShowModalRelacionar] = useState(false);

  return (
    <>
      <div style={{ maxWidth: 200 }}>
        <BotonDivLeft2
          tituloBoton="Servicios Relacionados"
          onClick={() => setShowModalRelacionar(true)}
        />
      </div>
      <ModalRelacionar
        show={showModalRelacionar}
        close={() => setShowModalRelacionar(false)}
      />
    </>
  );
};
