import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { startLoadServicios } from "../../../context/actions/capturaActions";

import { Fase1 } from "./fase1/Fase1";
import { Fase2 } from "./fase2/Fase2";
import { Fase3 } from "./fase3/Fase3";

export const Captura = () => {
  const dispatch = useDispatch();

  const { campaniaID } = useParams();
  const { capturaStep } = useSelector(
    (state) => state.asistenciasReducer
  );
  useEffect(() => {
    dispatch(startLoadServicios(campaniaID));
  }, []);

  return (
    <>
      <Fase1 />
      {capturaStep > 1 && <Fase2 />}
      {capturaStep > 2 && <Fase3 />}
    </>
  );
};
