import { exceedTime } from "../../views/semaforo/helpers";

export const getColor = (
  diferenciaFecha,
  estatusID,
  localCarretero,
  esCita,
  fechaTiempoLlegada
) => {
  let salida = "";

  if (esCita) return "semaforo__blanco";

  if (estatusID === 1) {
    if (diferenciaFecha < 3) {
      salida = "semaforo__verde";
    } else if (diferenciaFecha >= 3 && diferenciaFecha < 5) {
      salida = "semaforo__amarillo";
    } else {
      salida = "semaforo__rojo";
    }
  }

  if (estatusID === 2) {
    if (localCarretero === "carretero") {
      if (diferenciaFecha < 5) {
        salida = "semaforo__verde";
      } else if (diferenciaFecha >= 5 && diferenciaFecha < 7) {
        salida = "semaforo__amarillo";
      } else {
        salida = "semaforo__rojo";
      }
    } else {
      if (diferenciaFecha < 3) {
        salida = "semaforo__verde";
      } else if (diferenciaFecha >= 3 && diferenciaFecha < 5) {
        salida = "semaforo__amarillo";
      } else {
        salida = "semaforo__rojo";
      }
    }
  }

  if (estatusID === 3 || estatusID === 4) {

    if (localCarretero === "carretero") {

      if (diferenciaFecha < 60) {
        salida = "semaforo__verde";

      }

      else if (diferenciaFecha >= 60 && diferenciaFecha < 90) {
        salida = "";
      }
      else {
        salida = "semaforo__rojo";
      }

    } else {
      if (diferenciaFecha < 45) {
        salida = "semaforo__verde";

      } else if (diferenciaFecha >= 45 && diferenciaFecha < 60) {
        salida = "semaforo__amarillo";
      }
    /*     else if (exceedTime( fechaTiempoLlegada) ) {
        salida = "semaforo__renglon__blink";
      } */
       else {
        salida = "semaforo__rojo";
      }

    }

  }


  if (estatusID === 5) {
    salida = "semaforo__blanco";
  }

  return salida;
};
