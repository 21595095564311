import { Alert } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export const Conclusion = () => {
  const { estatusFinalID, bloqueadoPor } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  if (estatusFinalID === 2)
    return (
      <Alert
        style={{ marginBottom: 10 }}
        message="Servicio concluido"
        type="success"
      />
    );
  if (estatusFinalID === 3)
    return (
      <Alert
        style={{ marginBottom: 10 }}
        message="Servicio otorgado"
        type="success"
      />
    );
  if (estatusFinalID === 4)
    return (
      <Alert
        style={{ marginBottom: 10 }}
        message="Servicio cancelado con costo"
        type="error"
      />
    );
  if (estatusFinalID === 5)
    return (
      <Alert
        style={{ marginBottom: 10 }}
        message="Servicio cancelado sin costo"
        type="error"
      />
    );

  if (estatusFinalID === 1)
    return (
      <Alert
        style={{ marginBottom: 10 }}
        message="Servicio abierto"
        type="warning"
      />
    );

  return <></>;
};
