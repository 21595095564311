import { Modal, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPDFUrl, minimizedFile,  removeMinimizedFile } from "../../../context/actions/libraryActions";
import { useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";

export const ModalTag = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')

  const filesMinimized = useSelector(state => state.libraryReducer.filesMinimized)

  const urlPdf = useSelector(state => state.libraryReducer.currenPdfUrl)


  const handleMinimieze = () => {
    void dispatch(minimizedFile())
    setOpen(false)
  }

  const minimize = <div onClick={handleMinimieze} style={{display: 'inline', marginRight: '30px'}}><MinusCircleFilled className={'eyeIcon'}/></div>

  const handleRemove = (id) => {
    void dispatch(removeMinimizedFile(id))
  }
  const handleClick = (title, id) => {
    setOpen(true)
    setTitle(title)
    void dispatch(getPDFUrl(id))
  }


  // uploadCondiciones/id
  return (
    <>
      <div style={{position: 'fixed', bottom: '0', right: '30px', zIndex: '999'}}>
        {filesMinimized.map((file) => (
          <Tag color="magenta" closable onClick={() => handleClick(file.title, file.id)} onClose={() => handleRemove(file.id)}
               style={{padding: '5px', borderTopRightRadius: '10px', borderTopLeftRadius: '10px'}}
               key={file.id}>{file.title}</Tag>
        ))}
      </div>
      <Modal width={1000} centered open={open} title={<>{minimize} {title}</>} onCancel={() => setOpen(false)} footer={null}>

        <iframe
          src={urlPdf}
          style={{width: '100%', height: '80vh'}}
        />
      </Modal>
    </>
  )
}