import React from "react";
import { useSelector } from "react-redux";
import { RenglonSemaforo } from "../RenglonSemaforo";
import { TituloSemaforo } from "../TituloSemaforo";

export const BuscandoProveedor = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);
  const asistencias = asistenciasSemaforo.filter((a) => a.estatusID === 2);
  return (
    <>
      <TituloSemaforo titulo="Buscando Proveedor" count={asistencias.length} />
      {asistencias.map((a, i) => (
        <RenglonSemaforo
          key={i}
          asistencia={a}
          fecha={a.fechaCapturaTerminada}
          tipo='BuscandoProveedor'
        />
      ))}
    </>
  );
};
