import { DeleteOutlined } from "@ant-design/icons";
import styles from "./FormDetalleCosto.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDesglose } from "../../context/actions/desgloseActions";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../utils/formatCurrency";
import { post } from "../../utils/fetch";
import { URL_BASE } from "../../constants/url";
import { notification } from "antd";

export function ItemsCosto() {
  const dispatch = useDispatch();
  const {asistenciaID} = useParams()
  const items = useSelector(state => state.desgloseReducer.desgloseDeCostos)
  useEffect(() => {
    void dispatch(getDesglose(asistenciaID))
  }, []);

  const onDelete = async (id) => {
    const result = await post(`${URL_BASE}/api/DesgloseCabina/DeleteDesglose/${id}`)
    if (result.code === 0) {
      notification.success({message: result.data})
      void dispatch(getDesglose(asistenciaID))
    } else {
      notification.error({message: result.error})
    }
  }

  return (
    <>
      <section
        style={{marginTop: "40px"}}
      >
        {items?.map(costo => (

          <div
            key={costo.id}
            style={{display: "grid", gap: '15px', width: '100%', gridTemplateColumns: '1fr 1fr 1fr'}}
          >
            <p style={{textAlign: "center", margin: "0"}}> {costo.tipoCosto}  </p>

            <p style={{textAlign: 'end', width: '100px'}}> {formatCurrency(costo.costo)}</p>

            <DeleteOutlined className={styles.deleteIcon} style={{justifyContent: 'flex-end'}}
                            onClick={() => onDelete(costo.id)}/>
          </div>
        ))}


      </section>
    </>
  )
}