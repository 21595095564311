import { useSelector } from "react-redux";
import { DatosGeneral } from "./DatosGeneral";
import { DatosVehiculo } from "./DatosVehiculo";
import { DatosOrigen } from "./DatosOrigen";
import { Spinner } from "../../../../layout/Spinner";
import { DatosDestino } from "./DatosDestino";
import { BotonConfirmar } from "./BotonConfirmar";

export const Datos = () => {
  const { vehiculo } = useSelector((state) => state.gnpApiReducer.siniestro);

  if (!vehiculo) return <Spinner />;

  ///////////
  return (
    <>
      <DatosGeneral />
      <DatosVehiculo />
      <DatosOrigen />
      <DatosDestino />
      <BotonConfirmar />
    </>
  );
};
