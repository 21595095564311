import { URL_ACTIONS_GNP } from "../../constants/url";
import {
  URL_GNP_GET_ESTATUS,
  URL_GNP_GET_SERVICIO,
  URL_GNP_GET_SERVICIOS,
  URL_GNP_POST_SERVICIO,
} from "../../constants/urlGnp";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get,  post } from "../../utils/fetch";
import { getAhora } from "../../utils/getAhora";
import { types } from "../types/types";
import { notification } from "./notificationActions";
import { GNP_GET, GNP_POST } from '../../utils/fetchGNP';

export const startLoadSiniestrosByEstatus = (estatus) => {
  return async (dispatch) => {
    const url = `${URL_GNP_GET_SERVICIOS}/${estatus}`;
    const data = await GNP_GET(url);
    console.log(url);
    console.log(data);
    if (data.code !== -1) {
      dispatch(storeSiniestros(data.data));
      dispatch(setUltimaVerificacion(getAhora()));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Load siniestros gnp",
            `${data.error}`
          )
        )
      );
    }
  };
};

const setUltimaVerificacion = (fecha) => ({
  type: types.setUltimaVerificacion,
  payload: fecha,
});

const storeSiniestros = (servicios) => ({
  type: types.storeSiniestros,
  payload: servicios,
});

///////

export const startLoadGnpEstatus = () => {
  return async (dispatch) => {
    const data = await GNP_GET(URL_GNP_GET_ESTATUS);

    if (data.code !== -1) {
      dispatch(storeEstatusList(data.data.catalogo));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Load estatus gnp",
            `${data.error}`
          )
        )
      );
    }
  };
};

const storeEstatusList = (estatus) => ({
  type: types.storeEstatusList,
  payload: estatus,
});

////////////////////

export const startLoadServicio = (folioAsignacion) => {
  return async (dispatch) => {
    const url = `${URL_GNP_GET_SERVICIO}/${folioAsignacion}/detalle`;
    const resultado = await GNP_GET(url);

    console.log(resultado.data);
    if (resultado.code !== -1) {
      dispatch(storeSiniestro(resultado.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Load siniestro gnp",
            `${resultado.error}`
          )
        )
      );
    }
  };
};

const storeSiniestro = (servicio) => ({
  type: types.storeSiniestro,
  payload: servicio,
});

///////////

export const startCrearServicioSigsa = (servicioID) => {
  return async (dispatch, getState) => {
    const { siniestro } = getState().gnpApiReducer;
    const { usuarioID } = getState().userReducer.user;
    const body = {
      ...siniestro,
      usuarioID,
      servicioID,
      limitesCobertura: siniestro.limitesCobertura
        ? siniestro.limitesCobertura.toString()
        : "",
    };
    const url = `${URL_ACTIONS_GNP}/CrearServicioGnpSigsa`;
    const resultado = await post(url, body);

    console.log(body);

    if (resultado.code !== -1) {
      dispatch(
        startUpdateServicioGnp({
          folioAsignacion: siniestro.folioAsignacion,
          estatusActual: "CO",
        })
      );

      return resultado.data.asistenciaID;
    } else {
      dispatch(
        notification(
          composeErrorNotification("CrearServicioSigsa", `${resultado.error}`)
        )
      );
    }
  };
};

////////////////////////

export const startGetCatServiciosGNP = () => {
  return async (dispatch) => {
    const url = `${URL_ACTIONS_GNP}/GetCatServiciosGNP`;
    const resultado = await get(url);

    if (resultado.code !== -1) {
      dispatch(storeCatServiciosGNP(resultado.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetCatServiciosGNP", `${resultado.error}`)
        )
      );
    }
  };
};

const storeCatServiciosGNP = (servicios) => ({
  type: types.storeCatServiciosGNP,
  payload: servicios,
});

/////////////////////////

export const startUpdateServicioGnp = ({ folioAsignacion, estatusActual }) => {
  return async (dispatch) => {
    const body = {
      nombrePrestadorServicio: "Escotel",
      telefonoPrestadorServicio: "5588805516",
      especialidad: "Plataforma",
      idMotivo: "CACL",
      comentarios: "",
      fechaHoraEstatus: Date.now(),
      estatusActual,
      folioAsignacion,
    };

    console.log(body);
    const resultado = await GNP_POST(URL_GNP_POST_SERVICIO, body);

    if (resultado.code !== -1) {
      dispatch(
        notification(composeSuccessNotification("Servicio actualizado en GNP"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error al actualizar GNP: " + estatusActual,
            `${resultado.error}`
          )
        )
      );
    }
  };
};
