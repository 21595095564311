export const getAlcadiaByCP = (cp) => {
    const prefijo = cp.slice(0, 2)

    switch (prefijo) {
        case "01": return "Álvaro Obregón";
        case "02": return "Azcapotzalco";
        case "03": return "Benito Juárez";
        case "04": return "Coyoacán";
        case "05": return "Cuajimalpa de Morelos";
        case "06": return "Cuauhtémoc";
        case "07": return "Gustavo A. Madero";
        case "08": return "Iztacalco";
        case "09": return "Iztapalapa";
        case "10": return "La Magdalena Contreras";
        case "11": return "Miguel Hidalgo";
        case "12": return "Milpa Alta";
        case "13": return "Tláhuac";
        case "14": return "Tlalpan";
        case "15": return "Venustiano Carranza";
        case "16": return "Xochimilco";

        default: return "err: " + prefijo
    }



}