import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { minimizedFile, openModalMain, removeMinimizedFile } from "../../../context/actions/libraryActions";
import { MinusCircleFilled } from "@ant-design/icons";

export const ModalPdf = () => {

  const dispatch = useDispatch()


  const open = useSelector(state => state.libraryReducer.modalMainOpen)

  const titlePdf = useSelector(state => state.libraryReducer.currentPDFTitleInScreen)

  const urlPdf = useSelector(state => state.libraryReducer.currenPdfUrl)

  const handleMinimieze = () => {
    void dispatch(minimizedFile())
    void dispatch(openModalMain(false))
  }
  const idPdf = useSelector(state => state.libraryReducer.currentPDFId)
  const onCancel = () => {
    void dispatch(openModalMain(false))
    void dispatch(removeMinimizedFile(idPdf))
  }
 const minimize = <div onClick={handleMinimieze} style={{display: 'inline', marginRight: '30px'}}><MinusCircleFilled className={'eyeIcon'}/></div>


  return (
    <>

      <Modal
        centered={true}
        width={1000}
        onCancel={onCancel}
        confirmLoading={false}
        title={<>{minimize} {titlePdf}</>} open={open} footer={null}>
        <iframe
          src={urlPdf}
          style={{width: '100%', height: '80vh'}}
        />
      </Modal>
    </>
  )
}