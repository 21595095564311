import React from 'react'

export const LatLng = ({setLatLng}) => {

    const handleCompleto = ({target}) => {
        if (!target.value.includes(",")) return;
        const [latitud, longitud] = target.value.split(",")
        
        
        if (!(Number(latitud) && Number(longitud))) {
            return;
        }
        if (Number(latitud) > 90 || Number(latitud) < -90) return;
        if (Number(longitud) > 180 || Number(longitud) < -180) return;
        setLatLng({lat: Number(latitud), lng: Number(longitud)})

    }

    return (
        <input placeholder='lat,-lng' className="inputBusca _300" type="text" onChange={handleCompleto}/>
    )
}
