import {
  getPDFUrl,
  openModalMain,
  setCurrentPDFId,
  setPDFInScreen,
} from "../../../context/actions/libraryActions";
import { Tree } from "antd";
import { ModalPdf } from "./ModalPdf";

import { FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
const { DirectoryTree } = Tree;

///
export const ConditionsList = () => {
  const dispatch = useDispatch();
  const conditionsList = useSelector(
    (state) => state.libraryReducer.conditions
  );

  const itemsDirectory = conditionsList.map((item) => ({
    title: item.nombreComercial,
    clickable: false,
    selectable: false,
    key: item.key,
    children: item.condiciones.map((condition) => ({
      icon: <FilePdfOutlined />,
      title: condition.nombreArchivo,
      key: condition.id,
    })),
  }));

  const onSelect = (a, b) => {
    void dispatch(setPDFInScreen(b.node.title));
    void dispatch(setCurrentPDFId(b.node.key));
    void dispatch(getPDFUrl(b.node.key));
    void dispatch(openModalMain(true));
  };

  return (
    <>
      <DirectoryTree
        multiple
        defaultExpandAll
        onSelect={(a, b) => onSelect(a, b)}
        treeData={itemsDirectory}
      />
      <ModalPdf />
    </>
  );
};
