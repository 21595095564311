import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useGetServicios = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);
  const [serviciosPorArribar, setServiciosPorArribar] = React.useState([]);

  useEffect(() => {
    if (asistenciasSemaforo) {
      const serviciosPorArribar = asistenciasSemaforo.filter(
        (servicio) => servicio.estatusID === 3 // por arribar
      );

      setServiciosPorArribar(serviciosPorArribar);
    }
  }, [asistenciasSemaforo]);
  return { serviciosPorArribar };
};
