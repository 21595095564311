import React from "react";
import { useSelector } from "react-redux";
import { RenglonSemaforo } from "../RenglonSemaforo";
import { TituloSemaforo } from "../TituloSemaforo";

export const PorCerrarServicio = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);
  const asistencias = asistenciasSemaforo.filter((a) => a.estatusID === 5);
  return (
    <>
      <TituloSemaforo titulo="Por Cerrar Servicio" count={asistencias.length} />
      {asistencias.map((a, i) => (
        <RenglonSemaforo key={i} asistencia={a} fecha={a.fechaContacto} />
      ))}
    </>
  );
};
