import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BotonDivLeft2 from "../../../components/Boton/BotonDivLeft2";
import { PreguntasGNP } from "./PreguntasGNP";

export const SeccionProvieneApi = () => {
  const [show, setShow] = useState(false)
  const { esGnpApi } = useSelector((state) => state.detalleReducer.asistencia);


  if (esGnpApi) return (<div style={{ width: 130 }}>
    <BotonDivLeft2 onClick={() => setShow(true)} tituloBoton="Preguntas GNP" />
    <Modal title="Preguntas GNP" open={show} onOk={() => setShow(false)} onCancel={() => setShow(false)}>
      <PreguntasGNP />
    </Modal>
  </div>
  )


  return <></>;
};
