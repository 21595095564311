import {
  URL_BASE_ASISTENCIAS,
  URL_BASE_SMS
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";

import { types } from "../types/types";
import { tieneErroresBuscaValidador } from "./erroresActions";
import { notification } from "./notificationActions";

export const startLoadClientesCampanias = () => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_ASISTENCIAS}/GetClientesCampanias`);

    if (result.code === 0) {
      dispatch(storeClientesCampanias(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get cliente campanias",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeClientesCampanias = (payload) => ({
  type: types.storeClientesCampanias,
  payload,
});

//////////////////////

export const startLoadCampaniaData = () => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_ASISTENCIAS}/GetClientesCampanias`);

    if (result.code === 0) {
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get campania data",
            `${result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////////////////

export const startPostStep3 = (step) => {
  return async (dispatch, getState) => {

    const url = `${URL_BASE_ASISTENCIAS}/PostFase3`;
    const { captura } = getState().asistenciasReducer
    const result = await post(url, captura);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(setCapturaStep(step))
    } else {
      dispatch(
        notification(
          composeErrorNotification("PostFase3")
        )
      );
    }

  }
}

export const setCapturaStep = (step) => ({
  type: types.setCapturaStep,
  payload: step,
});

///

const getAhoraCompleto = () => {
  return () => {
    const agregaCero = (numero) => (numero < 10 ? `0${numero}` : `${numero}`);
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const y = today.getFullYear();
    const d = agregaCero(today.getDate());
    const M = agregaCero(today.getMonth() + 1);

    const h = agregaCero(today.getHours());
    const m = agregaCero(today.getMinutes());
    const s = agregaCero(today.getSeconds());

    const salida = `${y}-${M}-${d} ${h}:${m}:${s}`;

    return salida;
  };
};

export const createFechaIncio = () => {
  return async (dispatch) => {
    const fechaLlamadaIniciada = dispatch(getAhoraCompleto());
    dispatch(setCaptura({ fechaLlamadaIniciada }));
  };
};

////////////////////
export const setCaptura = (target) => ({
  type: types.setCaptura,
  payload: target,
});

////////////////

export const avanzaStep2 = () => {
  return (dispatch, getState) => {
    // trae los datos

    // get servicioID
    const { servicioID } = getState().asistenciasReducer.captura;

    // get campos
    dispatch(startLoadTipoAsistenciaServicios(servicioID));
    // avanza step
    dispatch(setCapturaStep(2));
  };
};

export const startLoadTipoAsistenciaServicios = (servicioID) => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_ASISTENCIAS}/GetCampos/${servicioID}`);

    if (result.code === 0) {
      dispatch(setCapturaCampos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Get campos",
            `${result.error}`
          )
        )
      );
    }
  };
};

const setCapturaCampos = (campos) => ({
  type: types.setCapturaCampos,
  payload: campos,
});

export const setGeoDataOrigen = (data) => ({
  type: types.setGeoDataOrigen,
  payload: data,
});

//////////

export const startLoadServicios = (campaniaID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/ServiciosByCampaniaID/${campaniaID}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeServicios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Get campos",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const storeServicios = (servicios) => ({
  type: types.storeServicios,
  payload: servicios,
});

//////////////////////////////

export const startLoadValidadorInfo = (validador1, campaniaID) => {
  return async (dispatch) => {
    const tieneErrores = await dispatch(tieneErroresBuscaValidador());
    if (tieneErrores) return false;

    const url = `${URL_BASE_ASISTENCIAS}/ConsultaValidador/${validador1}/${campaniaID}`;
    const result = await get(url);

    //datos si ya tiene algún servicio con nosotros
    if (result.code === 0) {
      dispatch(storeValidadorInfo(result.data));
      return true;
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Get campos",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeValidadorInfo = (info) => ({
  type: types.storeValidadorInfo,
  payload: info,
});

export const cleanCaptura = () => ({
  type: types.cleanCaptura,
  payload: null,
});

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export const startCreaAsistenciaInicial = (campaniaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const { captura } = getState().asistenciasReducer;
    const body = {
      ...captura,
      usuarioID,
      campaniaID,
    };

    const url = `${URL_BASE_ASISTENCIAS}/CreaAsistenciaInicial`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(storeAsistenciaID(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexión - CreaAsistenciaInicial")
        )
      );
    }
  };
};
const storeAsistenciaID = (asistenciaID) => ({
  type: types.storeAsistenciaID,
  payload: asistenciaID,
});

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

export const startTerminaCaptura = (campaniaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const { captura } = getState().asistenciasReducer;
    const body = {
      ...captura,
      usuarioID,
      campaniaID,
    };

    const url = `${URL_BASE_ASISTENCIAS}/TerminaCaptura`;
    const result = await post(url, body);
    console.log(result);
    if (result.code === 0) {
      return result;
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Post Asistencia")
        )
      );
    }
  };
};

//////////////////////////////////////////////////////////////////////
////////////////drepeciado//////////////////////////////////////////////////////
// export const startPostAsistencia = (campaniaID) => {
//   return async (dispatch, getState) => {
//     const { usuarioID } = getState().userReducer.user;
//     const { captura } = getState().asistenciasReducer;
//     const body = {
//       ...captura,
//       usuarioID,
//       campaniaID,
//     };

//     const url = `${URL_BASE_ASISTENCIAS}/PostAsistencia`;
//     const result = await post(url, body);

//     if (result.code === 0) {
//       return result;
//     } else {
//       dispatch(
//         notification(
//           composeErrorNotification("Error de conexion - Post Asistencia")
//         )
//       );
//     }
//   };
// };

//////////////
export const stratSendSMS = () => {
  return async (dispatch, getState) => {
    const { telefono, asistenciaID } = getState().asistenciasReducer.captura;
    const url = `${URL_BASE_SMS}/Send/`;
    const body = {
      telefono,
      asistenciaID,
    };
    const result = await post(url, body);
    console.log("SMS solicitado");

    //////////////Esperamos 
    if (result.code === 0) {
      dispatch(setEsperandoSMS(1));
      dispatch(notification(composeSuccessNotification("SMS Enviado")));
      let contador = 0;
      var interval = setInterval(async () => {
        const url2 = `${URL_BASE_SMS}/GetLatLng/${asistenciaID}/`;
        const result2 = await get(url2);

        if (result2.code === 0) {
          dispatch(setNewLatLng(result2.data));
          console.log(result2.data)
          dispatch(setEsperandoSMS(2));
          clearInterval(interval);
        } else {
          console.log("nada");
          contador++;
          if (contador > 13) {
            clearInterval(interval);
          }
        }
      }, 5000);
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetLatLng",
            `Error en SMS`
          )
        )
      );
    }
  };
};

const setNewLatLng = (newLatLng) => ({
  type: types.setNewLatLng,
  payload: newLatLng,
});

export const setEsperandoSMS = (status) => ({
  type: types.setEsperandoSMS,
  payload: status,
});

////////////////////////////


////////////////////

export const startAutorizarServicio = claveAutorizacion => {
  return async (dispatch) => {

    const url = `${URL_BASE_ASISTENCIAS}/AutorizarServicio`;
    const body = { claveAutorizacion }
    const result = await post(url, body);
    if (result.code === 0) {
      dispatch(setCaptura(result.data))
      return result;
    } else {
      dispatch(
        notification(
          composeErrorNotification("Autorización Error", result.error)
        )
      );
    }

  }
}