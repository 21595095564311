import ChatIndividual from "./chatIndividual/ChatIndividual";
import Chat from "../detalleServicio/folder/chat/Chat";
import "./listadoChats.css";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLayoutEffect} from "react";

const ListadoChats = () => {
    const [chats, setChats] = useState();
    const [asistencia, setAsistencia] = useState();
    const [subtituloChat, setSubtituloChat] = useState();
    const [id, setId] = useState();

    const {connection} = useSelector((state) => state.chatReducer);

    // useEffect(() => {
    //     connection?.send("LoadChatsCabina");
    // }, []);

    // useLayoutEffect(() => {
    //     const cargarChats = (listaChats) => {
    //         setChats(listaChats);
    //     };
    //     connection?.on("loadChatsCabina", cargarChats);

    //     return () => {
    //         connection?.off("loadChatsCabina", cargarChats);
    //     };
    // }, []);

    useEffect(() => {
        const nuevoChat = (chat) => {
            setChats([...chats, chat]);
        };
        connection?.on("nuevoChat", nuevoChat);

        const alguienEscribiendo = (userName, asistenciaId) => {
            if (asistenciaId !== asistencia) {
                return;
            }

            setSubtituloChat(`${userName} esta escibiendo...`);
        };
        connection?.on("alguienEscribiendo", alguienEscribiendo);

        const stopTyping = (asistenciaId) => {
            if (asistenciaId !== asistencia) {
                return;
            }

            setSubtituloChat('');
        };
        connection?.on("stopTyping", stopTyping);

        return () => {
            connection?.off("nuevoChat", nuevoChat);
            connection?.off("alguienEscribiendo", alguienEscribiendo);
            connection?.off("stopTyping", stopTyping);
        };
    });

    return (
        <>
            <div className="titulo">Listado Chats</div>

            <div className="container-listado">
                <div className="card__contenido listado-chats">
                    {chats?.map((m) => {
                        return (
                            <ChatIndividual
                                key={m.id}
                                message={m}
                                setAsistencia={setAsistencia}
                                setSubtituloChat={setSubtituloChat}
                                setId={setId}
                                leido={m.mensajesCabinaNoLeidos}
                            />
                        );
                    })}
                </div>

                <div style={{width: "75%"}}>
                    {asistencia ? (
                        <Chat titulo={asistencia} subtitulo={subtituloChat} id={id} width={770}/>
                    ) : (
                        <div
                            style={{maxHeight: 620, minHeight: 620}}
                            className="messages-chat card__contenido"
                        ></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ListadoChats;
