import { Divider } from "antd";
import { useSelector } from "react-redux";
import { ItemsCosto } from "./ItemsCosto";
import { formatCurrency } from "../../utils/formatCurrency";

export function DetalleTotal() {

  const items = useSelector(state => state.desgloseReducer.desgloseDeCostos)
  const total = items.reduce((total, item) =>  total + item.costo, 0)
  return (
    <>
      <ItemsCosto/>
      <Divider style={{backgroundColor: "red"}}/>
      <div
        style={{display: "flex", justifyContent: 'center', gap: "15px", marginBottom: "15px"}}
      >
        <p style={{textAlign: "center", margin: "0", fontSize: "16px", fontWeight: 'bold'}}>Total : </p>
        <span style={{fontSize: "16px"}}>{formatCurrency(total)}</span>
        <span></span>
      </div>
    </>
  )
}