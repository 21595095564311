import { useEffect, useState } from "react"
import { useGeocodingService } from "@ubilabs/google-maps-react-hooks"
import { getAlcadiaByCP } from "../../utils/getAlcadiaByCP";


export const Direccion = ({ latLng, getDatos }) => {
    const [direccion, setDireccion] = useState({})
    const geocoder = useGeocodingService();

    useEffect(() => {


        geocoder?.geocode({ location: latLng }, (results, status) => {

            if (status !== 'OK' || !results) {
                console.error(`Error al geolocalizar: ${status}`);
                return;
            }

            const resultados = results[0];
            const arregloDireccion = resultados.address_components
            const tamano = arregloDireccion.length

            if (arregloDireccion[tamano - 1].long_name.length === 5) { // tiene Cp
                const salida = {
                    direccionCompleta: resultados.formatted_address,
                    cp: arregloDireccion[tamano - 1].long_name,
                    pais: arregloDireccion[tamano - 2].long_name,
                    estado: arregloDireccion[tamano - 3].long_name,
                    municipio: arregloDireccion[tamano - 4].long_name,
                }

                if (arregloDireccion[tamano - 3].long_name === "Ciudad de México")
                    salida.municipio = getAlcadiaByCP(arregloDireccion[tamano - 1].long_name)

                setDireccion(salida)
                getDatos({ ...salida, ...latLng })

            } else {
                const salida = {
                    direccionCompleta: resultados.formatted_address,
                    pais: arregloDireccion[tamano - 1].long_name,
                    estado: arregloDireccion[tamano - 2].long_name,
                    municipio: arregloDireccion[tamano - 3].long_name,
                }

                setDireccion(salida)
                getDatos({ ...salida, ...latLng })
            }

        })
    }, [latLng])


    ///
    return (
        <div style={{ fontSize: 18, marginBottom: 50 }}>
            <div><b>LatLng: </b>{latLng.lat}, {latLng.lng}</div>
            <div>
                <div><b>Dirección completa: </b>{direccion.direccionCompleta}</div>
                {/* <div><b>País: </b>{direccion.pais}</div> */}
                <div><b>Estado: </b>{direccion.estado} | <b>Municipio: </b>{direccion.municipio} | <b>cp: </b>{direccion.cp}</div>

            </div>
        </div>
    )
}
