import { Drawer } from "antd";
import { FormAddCosto } from "../../../../components/ModalDetalleCosto/FormAddCosto";
import { DetalleTotal } from "../../../../components/ModalDetalleCosto/DetalleTotal";

export const DrawerDesgloseCostos = ({open, setOpen}) => {
  return (
    <>
      <Drawer onClose={() => setOpen(false)} open={open} width="30%">
        <FormAddCosto/>
        <DetalleTotal/>

      </Drawer>
    </>
  )
}