import { Button, Col, Row, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import BotonDiv from "../../../../components/Boton/BotonDiv";
import LabelText from "../../../../components/input/LabelText";

import { postFile } from "../../../../utils/fetch";

import { useParams } from "react-router-dom";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../utils/composeNotification";
import { notification } from "../../../../context/actions/notificationActions.js";
import { URL_BASE_ASISTENCIAS } from "../../../../constants/url";
import { startGetAsistenciaFiles } from "../../../../context/actions/detalleActions";

export default function Create() {
  const dispatch = useDispatch();
  const [ error, showError ] = useState(false);
  const [ body, setBody ] = useState({});
  const { asistenciaID } = useParams();

  //
  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });
  const userId = useSelector(state => state.userReducer.user.usuarioID)
  const camposEror = () => {
    let salida = false;
    if (
      estaVacioNull(body.nombre, "nombre") ||
      estaVacioNull(body.file, "file")
    )
      salida = true;

    return salida;
  };

  const estaVacioNull = (valor, campo) => {
    console.log(campo, valor);
    if (valor === "" || valor === null || valor === undefined) {
      return true;
    } else return false;
  };

  const handleClick = async () => {
    showError(false);
    if (camposEror()) {
      showError(true);
      return;
    }

    let formdata = new FormData();
    formdata.append("file", body.file);
    formdata.append("nombre", body.nombre);
    formdata.append("asistenciaID", asistenciaID);
    formdata.append("usuarioID", userId);

    const url = `${URL_BASE_ASISTENCIAS}/PostDocumento`;
    const result = await postFile(url, formdata);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetAsistenciaFiles(asistenciaID));
      setBody({});
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Post documento A",
            `${result.error}`
          )
        )
      );
    }
  };

  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <>

      <div>
        <LabelText
          name='nombre'
          value={body.nombre}
          onChange={handleChange}
          label='Nombre'
        />

      </div>

      <div>
        <Upload {...props} style={{ width: "100%" }}>
          <Button
            style={{ textAlign: "left" }}
            icon={<UploadOutlined />}
          >
            Seleccione documento
          </Button>
        </Upload>
      </div>

      <div>

        <BotonDiv tituloBoton='Agregar' onClick={handleClick} />
      </div>

      <div>

        <Col span={2} style={{ paddingTop: 22 }}>
          {error && <span style={{ color: "#ca2228" }}>Faltan datos</span>}
        </Col>

      </div>

      <div style={{ minHeight: 5, marginTop: 10 }}>
        {" "}
        {body.file && body.file.name}
      </div>
    </>
  );
}
