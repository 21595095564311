import { types } from "../types/types";

const init = {
  erroresBuscaValidador: {},
};

export const erroresReducer = (state = init, action) => {

  switch (action.type) {
    case types.cleanErroresBuscaValidador:
      return {
        ...state,
        erroresBuscaValidador: {},
      };

    case types.setErrBuscaValidador:
      return {
        ...state,
        erroresBuscaValidador: {
          ...state.erroresBuscaValidador,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
