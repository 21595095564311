import { CheckCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import BotonDivLeft2 from '../../../components/Boton/BotonDivLeft2'
import { startValidaProveedor } from '../../../context/actions/detalleActions'

export const SeccionProveedorValidado = () => {
    const dispatch = useDispatch()
    const { asistenciaID } = useParams()
    const { fechaProveedorValidado } = useSelector(state => state.detalleReducer.asistencia)

    const handleValidar = () => {
        dispatch(startValidaProveedor(asistenciaID))
    }

    if (!fechaProveedorValidado)
        return <BotonDivLeft2
            tituloBoton="Validar Proveedor"
            onClick={() => handleValidar()}
        />
    return (
        <div>Proveedor Validado <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} /></div>
    )
}
