import { ClockCircleOutlined } from "@ant-design/icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import BotonDiv from "../../../components/Boton/BotonDiv";
import { dateFromString } from "../../../utils/dateFromString";

export const CapturaTerminada = ({
  asistencia: { estatusID, fechaCapturaTerminada },
}) => {
  ////////////////////////////
  const history = useHistory();
  const { asistenciaID } = useParams();
  const date = dateFromString(fechaCapturaTerminada);

  if (estatusID > 1)
    return (
      <div>
        <ClockCircleOutlined /> {date.dateStep}
      </div>
    );

  ////////////////////////////
  return (
    <BotonDiv
      tituloBoton="Destino"
      onClick={() => history.push(`/mapaDestino/${asistenciaID}`)}
    />
  );
};
