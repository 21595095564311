import React from "react";
import { useDispatch } from "react-redux";
import LabelText from "../../../../components/input/LabelText";
import { setCaptura } from "../../../../context/actions/capturaActions";
import { getNombreDato } from "../../../../utils/getNombreDato";

export const CampoTexto = ({ campo, i }) => {
  const dispatch = useDispatch();
  const nombreCampo = getNombreDato(i);

  //
  const handleChange = ({ target }) => {
    const body = { [nombreCampo]: `${campo.label}: ${target.value}` };
    dispatch(setCaptura(body));
  };

  return <LabelText onChange={handleChange} label={campo.label} />;
};
