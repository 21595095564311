import {
  URL_BASE_ASISTENCIAS,
  URL_ELAGUILA_ACTIONS,
} from "../../constants/url";
import { composeErrorNotification } from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startLoadMisServicios = () => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const result = await get(
      `${URL_BASE_ASISTENCIAS}/GetAsistenciasAbiertasByUserID/${usuarioID}`
    );
    if (result.code === 0) {
      dispatch(storeMisServicios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeMisServicios = (servicios) => ({
  type: types.storeMisServicios,
  payload: servicios,
});

////////////////////////////////////////////////

export const startLoadServicios = () => {
  return async (dispatch, getState) => {
    const { filtroAsistencia } = getState().asistenciasReducer;
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciasByMes`;

    const result = await post(url, filtroAsistencia);
    if (result.code === 0) {
      dispatch(storeServiciosByMes(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencias",
            `${result.error}`
          )
        )
      );
    }
  };
};


export const startGetAsistenciasByDia = () => {
  return async (dispatch, getState) => {
    const { filtroAsistencia } = getState().asistenciasReducer;
    const url = `${URL_BASE_ASISTENCIAS}/GetAsistenciasByDia`;

    const result = await post(url, filtroAsistencia);
    if (result.code === 0) {
      dispatch(storeServiciosByMes(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencias",
            `${result.error}`
          )
        )
      );
    }
  };
};


const storeServiciosByMes = (servicios) => ({
  type: types.storeServiciosByMes,
  payload: servicios,
});

//////////

export const setFiltroAsistencia = (filtro) => ({
  type: types.setFiltroAsistencia,
  payload: filtro,
});

/////////////////

export const startGetCitasAbiertas = () => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/GetCitasAbiertas`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeCitasAbiertas(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencias",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeCitasAbiertas = (citas) => ({
  type: types.storeCitasAbiertas,
  payload: citas,
});

export const setInicioAsignacionAsistencia = (asistenciaId) => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_ASISTENCIAS}/SetInicioAsignacion/${asistenciaId}`;

    const { nombre } = getState().userReducer.user;

    const body = {
      nombreUsuario: nombre,
    };

    const result = await post(url, body);

    if (result.code !== 0) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - SetInicioAsignacion",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const sendSms = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/SendEnlaceTracking`;
    const result = await post(url, body);
    if (result.code !== 0) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - SetInicioAsignacion",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const confirmarProveedor = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ASISTENCIAS}/confirmarProveedor`;
    const result = await post(url, body);
    if (result.code !== 0) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - SetInicioAsignacion",
            `${result.error}`
          )
        )
      );
    }
  };
};

//
export const startValidaSupervisorElAguila = (claveAutorizacion) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const { validador1: poliza } = getState().asistenciasReducer.captura;
    const url = `${URL_ELAGUILA_ACTIONS}/validaSupervisorElAguila`;

    const body = { claveAutorizacion, poliza, usuarioID };
    const result = await post(url, body);
    console.log({ result });
    if (result.code === 0) {
      dispatch(setElAguilaAuth(true));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
};

export const setElAguilaAuth = (auth) => ({
  type: types.setElAguilaAuth,
  payload: auth,
});
