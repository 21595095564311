import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFiltroAsistencias = () => {
  const { serviciosByMes } = useSelector((state) => state.asistenciasReducer);
  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(serviciosByMes);
  }, [serviciosByMes]);

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = serviciosByMes.filter(
        (a) =>
          (a.asistenciaID &&
            a.asistenciaID
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.validador1 &&
            a.validador1.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.validador2 &&
            a.validador2.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.nombre &&
            a.nombre.toLowerCase().includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(serviciosByMes);
    }
  };

  return { listaFinal, filter };
};
