import { Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Items } from "./ClientesCampaniasItems";

export const ClientesCampanias = () => {
  const { clientesCampanias } = useSelector(
    (state) => state.asistenciasReducer
  );
  return (
    <Row gutter={[ 10, 10 ]}>
      {clientesCampanias.map((c, i) => (
        <Items key={i} cliente={c} />
      
      ))}
    </Row>
  );
};
