import { Badge } from "antd";
import React from "react";

export const TituloSemaforo = ({ titulo, count }) => {
  return (
    <div className="semaforo__titulo">
      {titulo} <Badge count={count}>...</Badge>
    </div>
  );
};
