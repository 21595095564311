import { DatePicker, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startEstablecerCita } from "../../../context/actions/detalleActions";
import { hdiTipoFechas, putNotification } from "../../../context/actions/hdiActions";

export const ModalCita = ({ show, close }) => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const [fechaCita, setFechaCita] = useState("");
  const handleChange = (f, fString) => setFechaCita(fString);
  const handleOk = () => {
    if (fechaCita === "") {
      close();
      return;
    }
    const body = {
      fechaCita,
      asistenciaID,
    };
    dispatch(startEstablecerCita(body));
    dispatch(putNotification(hdiTipoFechas.fechaCita, body))
    close();
  };
  return (
    <Modal
      title="Establecer Cita"
      open={show}
      onOk={handleOk}
      onCancel={() => close()}
    >
      <DatePicker onChange={handleChange} showTime style={{ width: "100%" }} />
    </Modal>
  );
};
