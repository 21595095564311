import { URL_BASE_ASISTENCIAS } from "../../constants/url";
import { get } from "../../utils/fetch";
import { types } from "../types/types";

export const getCarreteros = () => {
  return async (dispatch) => {
    try {
      const d = await get(
        `${URL_BASE_ASISTENCIAS}/asistenciaCarretero`
      );
      dispatch(setCarr(d.data));
    } catch (e) {
      throw new Error(e);
    }
  };
};

export const setCarr = (payload) => ({
  type: types.setCarreteros,
  payload,
});
