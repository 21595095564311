import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LabelSelect from "../../../../components/select/LabelSelect";
import { URL_BASE_ASISTENCIAS } from "../../../../constants/url";
import { setCaptura } from "../../../../context/actions/capturaActions";
import { notification } from "../../../../context/actions/notificationActions";
import { composeErrorNotification } from "../../../../utils/composeNotification";
import { get } from "../../../../utils/fetch";
import { getNombreDato } from "../../../../utils/getNombreDato";

export const CampoLista = ({ campo, i }) => {
  const [opciones, setOpciones] = useState([]);
  const dispatch = useDispatch();

  const nombreCampo = getNombreDato(i);

  useEffect(() => {
    getOpciones();
  }, []);

  const getOpciones = async () => {
    const result = await get(
      `${URL_BASE_ASISTENCIAS}/GetListaItemsByID/${campo.listaID}`
    );
    if (result.code === 0) {
      setOpciones(result.data);
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Clientes",
            `${result.error}`
          )
        )
      );
    }
  };

  const handleChange = (valor) => {
    const body = { [nombreCampo]: `${campo.label}: ${valor}` };
    dispatch(setCaptura(body));
  };

  return (
    <LabelSelect
      onChange={handleChange}
      label={campo.label}
      opciones={opciones}
    />
  );
};
