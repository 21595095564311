import axios from "axios";
import { BASE_URL_GNP } from '../constants/urlGnp';

export function interceptorToken() {
    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem('token');

        if (token && !config.url.includes(BASE_URL_GNP)) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
}

export function interceptor401() {

    axios.interceptors.response.use(undefined, function (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            if (window.location.pathname !== "/login") {

                window.location = "/login";
            }
        }
        return Promise.reject(error);
    });
}