import { types } from "../types/types";

const initialState = {
  desgloseDeCostos: []
}

export const desgloseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.saveCostos:
      return {
        ...state,
        desgloseDeCostos: action.payload
      }
    default:
      return state
  }
}