import React from 'react'
import BotonSubmit from '../../../components/Boton/BotonSubmit'

export const NoRequiereAuth = ({ handleNuevo }) => {
    return (<>
        <div>NoRequiereAuth</div>

        <div style={{ textAlign: "center", marginTop: 30 }}>
            <BotonSubmit
                onClick={handleNuevo}
                tituloBoton="Generar Servicio"
            />
        </div>
    </>
    )
}
