import { types } from "../types/types";

const initialState = {
  isValidVersion: true ,
  showModal:  'show'
};
export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.changeValidationVersion:
      return {
        ...state,
        isValidVersion: action.payload
      }
    case types.showModalValidVersion:
      return {
        ...state,
        showModal: action.payload
      }
    default:
      return state;
  }
};
