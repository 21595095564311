import { types } from "../types/types";

const init = {
  siniestro: {},
  siniestros: [],
  estatusList: [],
  ultimaVerificacion: "",
  catServiciosGNP: [],
};

export const gnpApiReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeCatServiciosGNP:
      return {
        ...state,
        catServiciosGNP: action.payload,
      };

    case types.setUltimaVerificacion:
      return {
        ...state,
        ultimaVerificacion: action.payload,
      };

    case types.storeEstatusList:
      return {
        ...state,
        estatusList: action.payload,
      };

    case types.storeSiniestros:
      return {
        ...state,
        siniestros: action.payload,
      };
    case types.storeSiniestro:
      return {
        ...state,
        siniestro: action.payload,
      };

    default:
      return state;
  }
};
