import { Button, Col, Row, Select } from "antd";
import React from "react";
import { motivoIncidenciaArriboOpciones } from "../../../../constants/cons";
import { useIncidenciaForm } from "./useIncidenciaForm";
import { useSelector } from "react-redux";

export const IncidenciasArribo = () => {
  const {asistencia} = useSelector((state) => state.detalleReducer);
  const { body, handleChange, handleSubmit } = useIncidenciaForm(2);

  if(asistencia.estatusID < 3) return <></>;
  return (
    <Row gutter={5}>
      <Col span={16}>
        <div className="inputLabel">Incidencia Arribo</div>
        <Select
          onChange={handleChange}
          value={body.motivoIncidenciaArribo}
          size="small"
          style={{ width: "100%" }}
          options={motivoIncidenciaArriboOpciones}
        />
      </Col>
      <Col span={8} style={{ paddingTop: 22 }}>
        <Button onClick={handleSubmit} size="small" type="primary">
          Agregar
        </Button>
      </Col>
    </Row>
  );
};
