import { ClockCircleOutlined } from "@ant-design/icons";
import { dateFromString } from "../../../utils/dateFromString";

export const LlamadaIniciada = ({ asistencia: { fechaLlamadaIniciada } }) => {
  const date = dateFromString(fechaLlamadaIniciada);

  return (
    <div>
      <ClockCircleOutlined /> {date.dateStep}
    </div>
  );
};
