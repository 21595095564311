import React from 'react'
import CardPage from '../../../../layout/CardPage'
import { BotonFase3 } from './BotonFase3'
import { Fase3Forma } from './Fase3Forma'

export const Fase3 = () => {
  return (
    <CardPage>
    <Fase3Forma />
    <BotonFase3 />
  </CardPage>
  )
}
