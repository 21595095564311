import { Provider } from "react-redux";
import { store } from "./context/store/store";
import Routes from "./routes/Routes";
import "mapbox-gl/dist/mapbox-gl.css";
import { message } from "antd";

function App() {
  const [_messageApi, contextHolder] = message.useMessage();
  return (
    <Provider store={store}>
      {/* {contextHolder} */}
      <Routes />
    </Provider>
  );
}

export default App;
