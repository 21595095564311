import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { useHistory } from "react-router-dom";
import { ResultadosLista } from "./ResultadosLista";
import { useDispatch } from "react-redux";
import { createFechaIncio } from "../../../context/actions/capturaActions";
import { ContadorResultados } from "./ContadorResultados";

export const Resultados = ({ campaniaID }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { validaInfo, captura } = useSelector(
    (state) => state.asistenciasReducer
  );

  const handleNuevo = () => {

    dispatch(createFechaIncio());

    setTimeout(() => history.push(`/captura/${campaniaID}`), 200);

  };

  ///
  if (validaInfo.loading)
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <Spin size='large' />
        </div>
      </>
    );

  //
  if (!validaInfo.existe)
    return (
      <div className='sinServicios'>
        <div style={{ marginBottom: 15 }}>
          No hay servicios registrados para este validador
        </div>
        <BotonSubmit
          onClick={handleNuevo}
          tituloBoton='Generar Servicio'
        />
      </div>
    );

  //
  return <>
    <ContadorResultados />
    <ResultadosLista handleNuevo={handleNuevo} campaniaID={campaniaID} />

  </>
};
