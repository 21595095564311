import { Col, Row } from 'antd'
import React from 'react'
import { SeccionCita } from './SeccionCita'
import { SeccionProveedorValidado } from './SeccionProveedorValidado'
import { SeccionProvieneApi } from './SeccionProvieneApi'
import { SeccionRelacionar } from './SeccionRelacionar'
import { useSelector } from "react-redux";
import { SeccionConcluir } from "./SeccionConcluir";

export const Secciones = () => {
  const asistencia = useSelector(state => state.detalleReducer.asistencia)
  const statusId = asistencia.estatus.estatusID // si es 6
  const estatusFinalId = asistencia.estatusFinal.estatusFinalID // si es 1
  return (
    <Row>
      <Col span={6}>
        <SeccionCita/>
      </Col>
      <Col span={5}>
        <SeccionProveedorValidado/>
      </Col>
      <Col span={5}>
        <SeccionRelacionar/>
      </Col>
      <Col span={5}>
        <SeccionProvieneApi/>
      </Col>

      {statusId === 6 && estatusFinalId === 1 && (<Col span={4}>
        <SeccionConcluir/>
      </Col>)}
    </Row>)
}
