import axios from "axios";
import FileDownload from "js-file-download";
import { interceptor401, interceptorToken } from "../interceptor/tokenInterceptor";

///////////////////////////////////////////////////////////////////////////////////////////
interceptorToken();
interceptor401();

export async function get(url) {
    try {
        const {data} = await axios.get(url);
        return {code: 0, data};
    } catch (e) {
        if (e.response) {
            return {code: -1, error: e.response.data};
        } else {
            return {code: -1, error: "Error desconocido"};
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function post(url, body) {
    try {
        const {data} = await axios.post(url, body);
        return {code: 0, data};
    } catch (e) {
        if (e.response) {
            console.log(e.response);
            return {code: -1, error: e.response.data};
        } else {
            console.log(e);
            return {code: -1, error: "Error desconocido"};
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function put(url, body) {
    try {
        const {data} = await axios.put(url, body);
        return data;
    } catch (e) {
        console.log(e);
        return -1;
    }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function dele(url) {
    try {
        const {data} = await axios.delete(url);
        return {code: 1, data};
    } catch (e) {
        return {code: -1, data: e.response.data};
    }
}

///////////////////////////////////////////////////////////////////////////////////////////

export async function getFile(url, name) {
    try {
        await axios({
            url,
            method: "GET",
            responseType: "blob", // Important
        }).then((response) => {
            FileDownload(response.data, name);
        });
        return {code: 0}
    } catch (e) {
        console.log("Error")
        console.log(e)
        return {code: -1, data: e.response.data};
    }
}

////////// GNP API



////////////////////////////////////////

export const postFile = async (url, formData) => {
    try {
        const {data} = await axios({
            method: "post",
            url,
            data: formData,
            headers: {
                "Content-Type": `multipart/form-data`,
            },
        });
        return {code: 0, data};
    } catch (e) {
        if (e.response) {
            return {code: -1, error: e.response.data};
        } else {
            console.log(e);
            return {code: -1, error: "Error desconocido"};
        }
    }
};
