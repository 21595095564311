
import { types } from "../types/types";

const init = {
  carreteros: []
};

export const carreteroReducer = (state = init, action) => {
  switch (action.type) {

    case types.setCarreteros:
      return {
        ...state,
        carreteros: action.payload,
      };

    default:
      return state;
  }
};