import { Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

export const PreguntasGNP = () => {
    const { preguntasGNP } = useSelector(state => state.detalleReducer.asistencia)

    if (!preguntasGNP) return <></>


    return (
        <div>{preguntasGNP.map((p, i) => (
            <div key={i} style={{ borderBottom: '1px solid #ccc', padding: '5px 0' }}>
                <div style={{ color: "#ca2228" }}>{p.pregunta}</div>
                <div >{p.respuesta}</div>
            </div>
        ))}</div>
    )
}
