import { TagSelector } from "../../components/tagSelector/TagSelector";
import { limitString } from "../../utils/limitString";

export const useColumns = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "asistenciaID",
      key: "asistenciaID",
      width: 110,
    },
    {
      title: "Fecha",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
      width: 80,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (row, record) => <div>{limitString(row, 25)}</div>,
    },
    {
      title: "Validador",
      dataIndex: "validador1",
      key: "validador1",
      render: (row, record) => <div>{limitString(row, 12)}</div>,
    },
    {
      title: "Validador2",
      dataIndex: "validador2",
      key: "validador2",
      render: (row, record) => <div>{limitString(row, 12)}</div>,
    },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Cuenta",
      dataIndex: "cuenta",
      key: "cuenta",
    },

    {
      title: "Estatus",
      width: 155,
      render: (tag, record) => <TagSelector tag={tag} />,
    },
  ];

  return { columns };
};
