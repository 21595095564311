export const gnp_estatus = {
  ASIGNADO: {
    nombre: "Asignado",
    clave: "AS",
    colorFondo: "#c2c2c2",
    colorTexto: "#fff",
  },
  CONFIRMADO: {
    nombre: "Confirmado",
    clave: "CO",
    colorFondo: "#95bf9f",
    colorTexto: "#fff",
  },
  EN_ATENCION: {
    nombre: "En Atención",
    clave: "EA",
    colorFondo: "#9db6e3",
    colorTexto: "#fff",
  },
  ARRIBO: {
    nombre: "Arribo",
    clave: "AR",
    colorFondo: "#3b67b8",
    colorTexto: "#fff",
  },
  TERMINADO: {
    nombre: "Terminado",
    clave: "TE",
    colorFondo: "#129632",
    colorTexto: "#fff",
  },
  NO_LOCALZIADO: {
    nombre: "No localizado",
    clave: "NL",
    colorFondo: "#626363",
    colorTexto: "#fff",
  },
  CANCELADO: {
    nombre: "Cancelado",
    clave: "CA",
    colorFondo: "#911b03",
    colorTexto: "#fff",
  },
};
