import { getOnlyMinutes, getTimeLeft } from "../../utils/dateWithName";
import styles from "./Card.module.css";
import { Button, Card, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { confirmarProveedor } from "../../context/actions/asistenciasActions";
import { getCarreteros } from "../../context/actions/carreteroActions";
import { URL_TRACKING } from "../../constants/url";
export const CardCarreteo = ({
  proveedor,
  proveedorId,
  tiempo,
  costo,
  asistenciaId,
  proveedorSucursalId,
}) => {
  const dispatch = useDispatch();

  //const { connection } = useSelector((state) => state.chatReducer);
  
  const onClick = async () => {
    // console.log({asistenciaId, proveedorSucursalId,costo, tiempoLLegada: getOnlyMinutes(tiempo)})
    dispatch(
      confirmarProveedor({
        asistenciaId,
        proveedorSucursalId,
        costo,
        tiempoLLegada: getOnlyMinutes(tiempo),
      })
    ).then( ()=> {
      dispatch(getCarreteros())
    })

    // const newChat = {
    //   asistenciaId,
    //   proveedorId,
    //   mensajesProveedorNoLeidos: true,
    //   mensajesCabinaNoLeidos: false,
    //   chatActivo: true,
    //   mensajes: [
    //     {
    //       emisorId: "Cabina",
    //       emisorNombre: "Cabina",
    //       texto: `${URL_TRACKING}/${asistenciaId}`,
    //       tipoUsuario: 1
    //     },
    //   ],
    // };
    //
    // connection?.send('RecibirMensajeTrackingProveedor', newChat)
  };

  return (
    <>
      <Card
        size="small"
        className={styles["card__carretero"]}
        // title="AsistenciID"
        // extra={<Link to="/">Ver mas</Link>}
      >
        <section className={styles["card__contend"]}>
          <p>{proveedor}</p>
          <p>{`${tiempo} minutos`}</p>
          <p>${costo}</p>
        </section>
        {/* <Divider /> */}
        <Button type="primary" size="small" onClick={onClick}>
          {" "}
          Asignar
        </Button>
      </Card>
    </>
  );
};
