import { Button, Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateFromString } from "../../../../../utils/dateFromString";
import { setVistaResultados } from "../../../../../context/actions/polizarioActions";

export const DetallePoliza = () => {
  const dispatch = useDispatch();
  const {
    poliza,
    contrato,
    nombre,
    apellido1,
    apellido2,
    telefono,
    rfc,
    fechaIni,
    fechaFin,
    direccion,
    marca,
    modelo,
    anio,
    placas,
    vin,
    cobertura,
  } = useSelector((state) => state.polizarioReducer.elAguilaPoliza);

  //
  return (
    <>
      <Row gutter={[10, 5]} style={{ marginTop: 15, marginBottom: 5 }}>
        <Col span={12}>
          <span className="labelPoliza">Póliza: </span>
          {poliza}
        </Col>

        <Col span={12}>
          <span className="labelPoliza">Cobertura:</span> {cobertura}
        </Col>

        <Col span={12}>
          <span className="labelPoliza">Contrato:</span> {contrato}
        </Col>

        <Col span={24}>
          <span className="labelPoliza">Nombre: </span>
          {apellido1} {apellido2}, {nombre}
        </Col>

        <Col span={12}>
          <span className="labelPoliza">Teléfono: </span>
          {telefono}
        </Col>
        <Col span={12}>
          <span className="labelPoliza">RFC:</span> {rfc}
        </Col>
      </Row>

      <Row gutter={[10, 5]}>
        <Col span={12}>
          <span className="labelPoliza">Inicio:</span>{" "}
          {dateFromString(fechaIni).soloFecha}
        </Col>
        <Col span={12}>
          <span className="labelPoliza">Fin:</span>{" "}
          {dateFromString(fechaFin).soloFecha}
        </Col>

        <Col span={12}>
          <span className="labelPoliza">Placas:</span> {placas}
        </Col>
        <Col span={12}>
          <span className="labelPoliza">Año:</span> {anio}
        </Col>
        <Col span={12}>
          <span className="labelPoliza">Marca:</span> {marca}
        </Col>
        <Col span={24}>
          <span className="labelPoliza">Modelo: </span>
          {modelo}
        </Col>

        <Col span={24}>
          <span className="labelPoliza">Direccion: </span> {direccion}
        </Col>
        <Col span={24}>
          <span className="labelPoliza">VIN: </span>
          {vin}
        </Col>
      </Row>
      <Button
        type="primary"
        style={{ width: "100%", marginTop: 10 }}
        onClick={() => dispatch(setVistaResultados(1))}
      >
        Regresar
      </Button>
    </>
  );
};
