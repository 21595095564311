import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadAsistencia } from "../context/actions/detalleActions";

export const useReloadDetalle = () => {
  const dispatch = useDispatch();
  const { asistenciaID, estatusID, proveedorSugeridoID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  useEffect(() => {
    if (asistenciaID && estatusID === 2 && proveedorSugeridoID) {
      let interval = setInterval(() => {
        //   console.log("startLoadAsistencia ", asistenciaID, estatusID)
        dispatch(startLoadAsistencia(asistenciaID, false));
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [estatusID]);
};
