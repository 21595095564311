import axios from 'axios';
import { GNP_API_KEY } from '../constants/urlGnp';


export async function GNP_GET(url) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.defaults.headers.common = {
      "X-API-Key": GNP_API_KEY,
    };

    const {data} = await axios({
      method: "get",
      url: url,
      config,
    });
    return {code: 0, data};
  } catch (e) {
    console.log(e);
    if (e.response) {
      return {code: -1, error: e.response.data};
    } else {
      return {code: -1, error: "Error desconocido"};
    }
  }
}


export async function GNP_POST(url, body) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.defaults.headers.common = {
      "X-API-Key": GNP_API_KEY,
    };

    const {data} = await axios({
      method: "post",
      url: url,
      config,
      data: body,
    });
    return {code: 0, data};
  } catch (e) {
    console.log(e.response.data.errors);
    if (e.response) {
      return {code: -1, error: e.response.data.errors[0].descripcion};
    } else {
      return {code: -1, error: "Error desconocido"};
    }
  }
}
