import { notificationInitial } from "../initial/notificationInitial";
import { types } from "../types/types";

const initial = {
    asistenciasSemaforo:[],
    asistenciasCitas:[]
}
export const semaforoReducer = (state = initial, action) => {
  switch (action.type) {

    case types.storeAsistenciasCitas:
      return {
        ...state,
        asistenciasCitas: action.payload,
      };

    case types.storeAsistenciasSemaforo:
      return {
        ...state,
        asistenciasSemaforo: action.payload,
      };

    default:
      return state;
  }
};
