import { Modal } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetAsistenciasByValidador1 } from "../../../context/actions/detalleActions";
import { ModalRelacionarDatos } from "./ModalRelacionarDatos";

export const ModalRelacionar = ({ show, close }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetAsistenciasByValidador1());
  }, []);

  return (
    <Modal
      title="Relacionar Servicio"
      open={show}
      onOk={() => close()}
      onCancel={() => close()}
    >
      <ModalRelacionarDatos />
    </Modal>
  );
};
