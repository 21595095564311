import { getMonthNow } from "../../utils/getMonthNow";
import { getYearNow } from "../../utils/getYearNow";
import { types } from "../types/types";

const init = {
  elAguilaAuth: false,
  citasAbiertas: [],
  asistencia: {},
  proveedorCostos: {},
  asistencias: [],
  clientesCampanias: [],
  captura: {},
  capturaStep: 1,
  capturaCampos: [],
  servicios: [],
  validaInfo: {
    loading: true,
  },
  misServicios: [],
  serviciosByMes: [],
  newLatLng: { lat: 19.3887859, lng: -99.06231179999999 },
  esperandoSMS: 0,
  filtroAsistencia: {
    mes: getMonthNow() + 1,
    anio: getYearNow(),
  },
};
export const asistenciasReducer = (state = init, action) => {
  switch (action.type) {
    case types.setElAguilaAuth:
      return {
        ...state,
        elAguilaAuth: action.payload,
      };

    case types.storeCitasAbiertas:
      return {
        ...state,
        citasAbiertas: action.payload,
      };

    case types.storeAsistenciaID:
      return {
        ...state,
        captura: {
          ...state.captura,
          asistenciaID: action.payload,
        },
      };
    case types.setFiltroAsistencia: {
      return {
        ...state,
        filtroAsistencia: {
          ...state.filtroAsistencia,
          ...action.payload,
        },
      };
    }

    case types.setEsperandoSMS:
      return {
        ...state,
        esperandoSMS: action.payload,
      };

    case types.setNewLatLng:
      return {
        ...state,
        newLatLng: action.payload,
      };
    case types.storeServiciosByMes:
      return {
        ...state,
        serviciosByMes: action.payload,
      };

    case types.storeMisServicios:
      return {
        ...state,
        misServicios: action.payload,
      };
    case types.cleanCaptura:
      return {
        ...state,
        captura: {},
      };
    case types.storeValidadorInfo:
      return {
        ...state,
        validaInfo: { loading: false, ...action.payload },
      };

    case types.storeServicios:
      return {
        ...state,
        servicios: action.payload,
      };

    case types.setGeoDataOrigen:
      return {
        ...state,
        captura: {
          ...state.captura,
          ...action.payload,
        },
      };

    case types.setCapturaCampos:
      return {
        ...state,
        capturaCampos: action.payload,
      };
    case types.setCapturaStep:
      return {
        ...state,
        capturaStep: action.payload,
      };
    case types.setCaptura:
      return {
        ...state,
        captura: {
          ...state.captura,
          ...action.payload,
        },
      };
    case types.storeClientesCampanias:
      return {
        ...state,
        clientesCampanias: action.payload,
      };

    default:
      return state;
  }
};

////////////
