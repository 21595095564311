import dayjs from "dayjs";
import { normalizarValores } from "./ObjetoSinAcentos";

function toCamelCase(inputString) {
  const camelCased = inputString.replace(/(?:^|\s)(\w)/g, function (match, firstLetter) {
    return firstLetter.toUpperCase();
  });

  // Convertir la primera letra a minúscula
  return camelCased.charAt(0).toLowerCase() + camelCased.slice(1);
}

const subEventoEscotelToHDI = ({id},) => {
  const eventosEscotelToHDI = {
    // Escotel -> HDI
    1: 1, // Arrastre de grúa -> Grúa
    2: 2, // Paso de corriente → Paso de corriente
    // 3: 2, // Grúa Equipo Pesado
    4: 4, // Suministro de gasolina → Gasolina también está con 20 en HDI
    5: 19, // Cambio de llanta → Cambio de llanta
    6: 5, // Cerrajería → Cerrajero
    30: 12, // Información,  -> Asesoría Telefónica
    32: 6, // Auxilio Mecánico, -> Auxilio Mecánico
    31: 17, // Arrastre de grúa -> Grua
    69: 8, // Taxí de emergencia FIXME: Existe uno de traslado por estado de ebriedad
  }
  return eventosEscotelToHDI[id]
}

export const createHDIBody = (values, destinoProps) => {
  console.log({values, destinoProps})
  const {
    direccionDestino,
    direccionOrigen,
    estadoOrigen,
    municipioOrigen,
    fechaCita,
    fechaCitaCadena,
    fechaLlamadaIniciada,
    fechaCapturaTerminada,
    asistenciaID,
    numeroReporteHdi,
    nombreLlamada,
    apellidoPaternoLlamada,
    apellidoMaternoLlamada,
    telefono,
    ...rest

  } = values
  const campos = Object.entries(rest).filter(([key, value]) => key.startsWith('campo') && value !== null && value !== '')
  const formatDateString = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
  // solo los valores de los campos
  const camposValues = campos.map(([_, value]) => value)
  // objeto con los valores de los campos
  let informacionVial = {
    // FIXME: preguntar
    // el estos datos se agregan en la asignación del proveedor
    color: "",
    fechaArribo: "",
    marca: "",
    modelo: "",
    nombreConductor: "",
    numeroSerie: "",
    placas: "",
    sFechaArribo: "",
    subMarca: "",
    telefonoConductor: "",
    tiempoArribo: 0
  }
  camposValues.forEach((value) => {
    const [key, val] = value.split(':')
    informacionVial[normalizarValores(toCamelCase(key))] = val.trim()
  })

  informacionVial = {
    ...informacionVial,
    marca: informacionVial?.marcaDelVehiculo ?? "",
    subMarca: informacionVial?.tipoDeVehiculo ?? ""
  }


  return {
    comentarios: [
      {
        comentario: "Creación de reporte",
        fecha:  dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      }
    ],
    // FIXME: preguntar
    datosPoliza: {
      idPoliza: values.asegurado.validador1,
      numCis: 0,
      oficinaAtencion: ""
    },

    fechaCita: fechaCita || "",
    fechaCitaCadena: fechaCita || '',

    fechaFinCaptura: fechaCapturaTerminada ?? dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    fechaFinCapturaCadena: fechaCapturaTerminada ?? new Date(fechaLlamadaIniciada).toLocaleDateString("es-Mx"),

    fechaInicioCaptura: fechaLlamadaIniciada ?? dayjs().format(formatDateString),
    fechaInicioCapturaCadena: fechaLlamadaIniciada ?  new Date(fechaLlamadaIniciada).toLocaleDateString("es-Mx") : dayjs().format(formatDateString),

    idProveedor: 7, // Dato que dio nachito de HDI

    idSubEvento: subEventoEscotelToHDI(values.servicio),
    // FIXME: preguntar
    idTipoTiempoSubEvento: 0,

    infomacionVial: informacionVial,
    montoExcedente: 0,
    motivoExcedente: "",
    nombreProveedor: "",

    nombreSolicitante: `${nombreLlamada} ${apellidoPaternoLlamada} ${apellidoMaternoLlamada}`,

    numeroReporteHdi: numeroReporteHdi,
    numeroReporteProveedor: asistenciaID,
    telefonoSolicitante: telefono,
    ubicacion: {
      colonia: "",
      destino: destinoProps.direccion,
      direccion: direccionOrigen,
      entidadFederativa: estadoOrigen,
      esquina: "",
      municipio: municipioOrigen,
      referenciaLugar: `origen: ${values.latOrigen},${values.lngOrigen}, destino: ${destinoProps.lat},${destinoProps.lng}`
    }
  }

}




