import { useHistory } from "react-router-dom";
import { Col, Row } from 'antd';
import styles from './Botons.module.css'

export const BotonGnpApi = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.flex}>

        <div onClick={() => history.push("/GnpApi")} className={`${styles.gnp} ${styles.card}`}>
          Visor GNP
        </div>

        <div className={`${styles.hdi} ${styles.card}`} onClick={()=> history.push('/hdiApi')}>
          Visor HDI
        </div>

      </div>
    </>
  )
    ;
};
