import React from "react";
import CardPage from "../../../../layout/CardPage";
import { BotonFase2 } from "./BotonFase2";
import { Fase2Forma } from "./Fase2Forma";

export const Fase2 = () => {
  return (
    <CardPage>
      <Fase2Forma />
      <BotonFase2 />

    </CardPage>
  );
};
