import React from 'react'
import { Switch } from 'antd'


export const Conexion = ({ AgregaDatos }) => {

    return (
        <Switch
            checkedChildren="Es conexión"
            unCheckedChildren="No es conexión"
            onChange={(val) => AgregaDatos({ esConexion: val })}
        />
    )
}
