import { Button, Image, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL_BASE_ASISTENCIAS } from "../../constants/url";
import axios from "axios";

export const UploadPlacas = ({ url, setUrl }) => {
  const [ file, setFile ] = useState([]);
  // const [ url, setUrl ] = useState(null)
  const [ uploading, setUploading ] = useState(false)
  const userId = useSelector(state => state.userReducer.user.usuarioID)
  const { asistenciaID, urlPlaca } = useSelector(state => state.detalleReducer.asistencia)
  const beforeUpload = (file) => {
    setFile(file);
    const url = URL.createObjectURL(file)
    setUrl(url)
    return false;
  }
  const upload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('usuarioId', userId)
    formData.append("asistenciaId", asistenciaID)
    try {
      await axios.post(`${URL_BASE_ASISTENCIAS}/postPlaca`, formData)
      message.success('Placa subida correctamente')
    } catch (e) {
      console.log(e)
      message.error('Error al subir la placa')
    } finally {
      setUploading(false)
    }
  }
  return (
    <>
      <Upload
        accept='images/*'
        beforeUpload={beforeUpload}
        maxCount={1}
        showUploadList={false}
        listType='picture'
        // fileList={[ file ]}
      >
        <Button
          icon={<UploadOutlined />}
        >Cargar Placas</Button>
      </Upload>

      <Button
        icon={<UploadOutlined />}
        loading={uploading}
        onClick={upload}

      >Guardar Cambios</Button>

    </>
  );
};