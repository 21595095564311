import { Input } from "antd";
import React from "react";

export default function LabelTextarea({
  label,
  name,
  onChange,
  value,
  placeholder,
}) {
  return (
    <>
      <div className="labelImput">{label}</div>
      <Input.TextArea
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </>
  );
}
