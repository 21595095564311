import { Avatar, BackTop } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormularioMensajes from "./FormularioMensaje";
import Enviado from "./mensajes/enviado/Enviado";
import Recibido from "./mensajes/recibido/Recibido";
import "./chat.css";

const Chat = ({ titulo, width, subtitulo, id }) => {
  const { asistenciaID, estatusID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const { connection } = useSelector((state) => state.chatReducer);
  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const recibirMensaje = (message) => {
      if (
        message.asistenciaId !== asistenciaID &&
        message.asistenciaId !== titulo
      ) {
        return;
      }

      setMessages([...messages, message]);

      const final = document.getElementById("principio");
      final.scrollTop = final.scrollHeight;

      connection?.send("CountChatsCabinaNoLeidos")
    };
    connection?.on("message", recibirMensaje);

    const loadMensajes = (messages) => {
      setMessages(messages.mensajes);
    };
    connection?.on("loadMensajes", loadMensajes);

    return () => {
      connection?.off("message", recibirMensaje);
      connection?.off("loadMensajes", loadMensajes);
    };
  }, [messages]);

  return (
    <div className="main-chat" id="chat">
      <div className="card__contenido header-chat">
        <Avatar
          className="avatar-chat"
          src="https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/151efb5d798e28e7d95090087b38d49f"
        />

        <div className="header-titles">
          <span style={{ fontSize: "100%" }}>
            Asistencia {titulo ?? asistenciaID}
          </span>
          <span className="chat-subtitle" id="subtitleChat">
            {subtitulo}
          </span>
        </div>
      </div>

      <div className="messages-chat card__contenido" id="principio">
        {messages.map((x, i) => {
          //? Revisamos que el anterior mensaje sea del mismo emisor
          const mismoEmisorAnterior = messages[i - 1]?.emisorId === x.emisorId;

          //? Revisamos que el siguiente mensaje sea del mismo emisor
          const mismoEmisorSiguiente = messages[i + 1]?.emisorId === x.emisorId;

          return x.emisorId === usuarioID || x.emisorId === 'Cabina' ? (
            <Enviado
              m={x}
              key={i}
              mismoEmisorAnterior={mismoEmisorAnterior}
              mismoEmisorSiguiente={mismoEmisorSiguiente}
            />
          ) : (
            <Recibido
              m={x}
              key={i}
              mismoEmisorAnterior={mismoEmisorAnterior}
              mismoEmisorSiguiente={mismoEmisorSiguiente}
            />
          );
        })}

        <BackTop
          target={() => document.getElementById("principio")}
          style={{ marginBottom: 30, marginRight: -60 }}
        />
      </div>

      {estatusID !== 6 && (
        <div className="card__contenido footer-chat">
          <FormularioMensajes
            messages={messages}
            setMessages={setMessages}
            asistenciaID={asistenciaID || titulo}
            id={id}
            width={width}
          />
        </div>
      )}
    </div>
  );
};

export default Chat;
