import { Col, Row, Spin } from 'antd';
import { LabelValor } from '../../../components/label/LabelValor';
import { useEffect, useState } from 'react';
import { useData } from './useData';
import { hdiClient } from '../../../utils/hdiApiClient';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHdiService } from '../../../context/actions/hdiActions';

export const Datos = () => {

  const data = useSelector(state => state.hdiReducer.detailsService)
  const [loading, setLoading] = useState(true)

  const {generalData} = useData(data)

  const {id} = useParams()

 const dispatch = useDispatch()
  const getDetails = async () => {
    try {
      void  dispatch(getHdiService(id))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    void getDetails()
  }, []);

  if (loading) return <Spin/>
  return (
    <>

      <Row gutter={[10, 10]}>
        {generalData?.map((item, index) => (
          <Col span={4} key={index}>
            <LabelValor label={item.label} valor={item.value} key={index}/>
          </Col>
        ))}
      </Row>
    </>
  )
};
