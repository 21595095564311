import { useState } from "react";
import { Button, Space } from "antd";
import ModalAgregarPdf from "./ModalAgregarCondicion";
import { ModalEliminarPDF } from "./ModalEliminarPDF";
import { useSelector } from "react-redux";

export const LibraryControls = () => {
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { user } = useSelector((state) => state.userReducer);

  if (!user.Backoffice_AdminLibrary) return <></>;

  return (
    <>
      <Space style={{ marginBottom: 10 }}>
        <Button onClick={() => setOpen(true)} type="primary" size="small">
          Agregar Documento
        </Button>
        <Button
          onClick={() => setOpenDeleteModal(true)}
          type="primary"
          size="small"
          danger
        >
          Eliminar Documento
        </Button>
      </Space>

      <ModalAgregarPdf open={open} setOpen={setOpen} />
      <ModalEliminarPDF open={openDeleteModal} setOpen={setOpenDeleteModal} />
    </>
  );
};
