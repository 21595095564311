import { Rate } from "antd";
import React, { useEffect } from "react";

export const Estrellas = ({ estrellas }) => {
  useEffect(() => {}, [estrellas]);

  return (
    <>
      <Rate value={estrellas} disabled />
    </>
  );
};
