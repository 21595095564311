import { Col, Row } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export const SiniestroItem = ({ siniestro }) => {
  const {
    folioAsignacion,
    horaReporte,
    nombreReportante,
    apellidoPaternoReportante,
    cuentaEspecial,
    clienteVip,
  } = siniestro;

  const history = useHistory();

  const handleClick = () => history.push(`GnpApi/catch/${folioAsignacion}`);

  //////
  return (
    <Row
      gutter={[10, 10]}
      style={{ marginBottom: 10 }}
      className="rowAsistencias"
      onClick={handleClick}
    >
      <Col span={4}>{folioAsignacion}</Col>
      <Col span={3}>{horaReporte}</Col>
      <Col span={7}>
        {nombreReportante} {apellidoPaternoReportante}
      </Col>
      <Col span={3} style={{ marginLeft: 15, fontSize: 15, color: "#ca2228" }}>
        {cuentaEspecial === 1 ? <CheckCircleOutlined /> : ""}
      </Col>
      <Col span={3} style={{ marginLeft: 15, fontSize: 15, color: "#ca2228" }}>
        {clienteVip === 1 ? <CheckCircleOutlined /> : ""}
      </Col>
    </Row>
  );
};
