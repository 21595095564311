import { asistenciasReducer } from "../reducers/asistenciasReducer";
import { authErrReducer } from "../reducers/authErrReducer";
import { chatReducer } from "../reducers/chatReducer";
import { clientesReducer } from "../reducers/clientesReducer";
import { detalleReducer } from "../reducers/detalleReducer";
import { erroresReducer } from "../reducers/erroresReducer";
import { gnpApiReducer } from "../reducers/gnpApiReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { polizarioReducer } from "../reducers/polizarioReducer";
import { semaforoReducer } from "../reducers/semaforoReducer";
import { userReducer } from "../reducers/userReducer";
import { carreteroReducer } from "../reducers/carreteroReducer";
import { libraryReducer } from '../reducers/libraryPDFReducer'
import { hdiReducer } from '../reducers/hdiReducer'
import { desgloseReducer } from "../reducers/desgloseReducer";
import { appReducer } from "../reducers/appReducer";

export const allReducers = {
	authErrReducer: authErrReducer,
	userReducer: userReducer,
	notificationReducer: notificationReducer,
	clientesReducer: clientesReducer,
	asistenciasReducer: asistenciasReducer,
	detalleReducer: detalleReducer,
	erroresReducer: erroresReducer,
	gnpApiReducer: gnpApiReducer,
	semaforoReducer: semaforoReducer,
	polizarioReducer: polizarioReducer,
	chatReducer: chatReducer,
	carreteroReducer,
	libraryReducer,
	hdiReducer,
	desgloseReducer,
	appReducer,
};
