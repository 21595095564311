import { Button, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_BASE } from "../../constants/url";
import { post } from "../../utils/fetch";
import { getDesglose } from "../../context/actions/desgloseActions";

const options = [
  {
    label: "1er arrastre",
    value: "1er arrastre",
  },
  {
    label: "2do arrastre",
    value: "2do arrastre",
  },
  {
    label: "3er arrastre",
    value: "3er arrastre",
  },
  {
    label: "4to arrastre",
    value: "4to arrastre",
  },
  {
    label: "km recorrido",
    value: "km recorrido",
  },
  {
    label: "Banderazo",
    value: "Banderazo",
  },
  {
    label: "Maniobras fuera de camino",
    value: "Maniobras fuera de camino",
  },
  {
    label: "Maniobras dentro del camino",
    value: "Maniobras dentro del camino",
  },
  {
    label: "Abanderamiento con grúa",
    value: "Abanderamiento con grúa",
  },
  {
    label: "Abanderamiento manual",
    value: "Abanderamiento manual",
  },
  {
    label: "acondicionamiento",
    value: "acondicionamiento",
  },
  {
    label: "Custodia",
    value: "Custodia",
  },
  {
    label: "Pensión",
    value: "Pensión",
  },
  {
    label: "Concesionado no ubica unidad",
    value: "Concesionado no ubica unidad",
  },
]
export const FormAddCosto = () => {
  const usuarioId = useSelector(state => state.userReducer.user.usuarioID)
  const {asistenciaID} = useParams()
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    values.usuarioID = usuarioId
    values.asistenciaID = asistenciaID
    const result = await post(`${URL_BASE}/api/DesgloseCabina/AddDesglose`, values)
    if (result.code === 0) {
      notification.success({message: result.data})
      void dispatch(getDesglose(asistenciaID))
    } else {
      notification.error({message: result.error})
    }
  }
  return (
    <Form
      layout='vertical'
      requiredMark={false}
      onFinish={onFinish}
    >

      <Form.Item
        label={"Tipo de item"}
        name={'tipoCosto'}
        rules={[{required: true, message: 'El tipo de item es requerido'}]}
      >
        <Select
          options={options} placeholder={"Selecciona un tipo de costo"}
          showSearch
        />
      </Form.Item>

      <Form.Item
        name={'Costo'}
        label={'Costo'}
        rules={[{required: true, message: 'Ingrese el costo del item'}]}
      >
        <Input placeholder={'Costo'} type={'number'} min={0}/>
      </Form.Item>

      <Button type={"primary"} htmlType='submit'>Agregar</Button>

    </Form>
  );
};