import { Col, Row, Steps } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import CardPage from "../../../layout/CardPage";
import { BotonCancelar } from "./BotonCancelar";
import { LlamadaIniciada } from "./LlamadaIniciada";
import { CapturaTerminada, Step1a } from "./CapturaTerminada";
import { Asignado, Step2 } from "./Asignado";
import { Arribo, Step3 } from "./Arribo";
import { Contacto, Step4 } from "./Contacto";
import { Concluido, Step5 } from "./Concluido";

const { Step } = Steps;

export const Etapas = () => {
  const { asistencia } = useSelector((state) => state.detalleReducer);
  const paso = asistencia.estatusID;

  return (
    <CardPage>
      <Row>
        <Col span={21}>
          <Steps current={paso}>
            <Step
              title='Llamada iniciada'
              description={<LlamadaIniciada asistencia={asistencia} />}
            />

            <Step
              title={paso === 1 ? "Falta destino" : "Captura terminada"}
              description={<CapturaTerminada asistencia={asistencia} />}
            />
            <Step
              title={paso === 2 ? "Buscando prov." : "Asignado"}
              description={<Asignado asistencia={asistencia} />}
            />
            <Step
              title={paso === 3 ? "En trayecto" : "Arribo"}
              description={<Arribo asistencia={asistencia} />}
            />
            <Step
              title={paso === 4 ? "Por contactar" : "Contacto"}
              description={<Contacto asistencia={asistencia} />}
            />
            <Step
              title={paso === 5 ? "En proceso" : "Concluido"}
              description={<Concluido asistencia={asistencia} />}
            />
          </Steps>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <BotonCancelar />
        </Col>
      </Row>
    </CardPage>
  );
};
