import { types } from "../types/types";
import { get } from "../../utils/fetch";
import { URL_BASE } from "../../constants/url";
import { notification } from "./notificationActions";
import { composeErrorNotification } from "../../utils/composeNotification";
import axios from "axios";


export const getConditions = () => {
  return async dispatch => {
    const result = await get(`${URL_BASE}/api/Clientes/GetCondiciones`)
    if (result.code === 0) {
      dispatch(saveConditions(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get lista",
            `${result.error}`
          )
        )
      );
    }
  }
}


const saveConditions = (conditions) => ({
  payload: conditions,
  type: types.saveConditions
})

export const setPDFInScreen = (title) => ({
  payload: title,
  type: types.setPDFInScreen
})

export const setCurrentPDFId = (id) => ({
  payload: id,
  type: types.setCurrentPDFId
})


export const getPDFUrl = (idPDF) => {
  return async dispatch => {
    try {
      const {data} = await axios.get(`${URL_BASE}/api/Clientes/GetCondicionesDocumento/${idPDF}`, {responseType: 'blob'})
      const url = window.URL.createObjectURL(data)
      dispatch(setCurrentUrlPDF(url))

    } catch (e) {
      dispatch(composeErrorNotification("Error al obtener el archivo", e.toString()))
    }
  }
}

export const openModalMain = (payload) => ({
  type: types.openModalMain,
  payload
})

export const setCurrentUrlPDF = (url) => ({
  type: types.setCurrentUrlPDF,
  payload: url
})


export const minimizedFile = () => {
  return (dispatch, getState) => {
    const {currentPDFTitleInScreen, currentPDFId} = getState().libraryReducer
    const file = {
      title: currentPDFTitleInScreen,
      id: currentPDFId
    }
    dispatch(saveMinimezedFiles(file))
  }
}

const saveMinimezedFiles = (file) => ({
  type: types.saveMinimezedFiles,
  payload: file
})

export const removeMinimizedFile = (id) => ({
  type: types.removeMinimizedFile,
  payload: id
})