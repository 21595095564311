import React from "react";
import { useDispatch } from "react-redux";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { startPostStep3 } from "../../../../context/actions/capturaActions";


export const BotonFase2 = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(startPostStep3(3));
  };
  return <BotonSubmit tituloBoton="Continuar" onClick={handleClick} />;
};
