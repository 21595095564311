import { Spin } from "antd";
import React from "react";

export const Spinner = ({ size }) => {
  return (
    <div style={{ paddingTop: 50, textAlign: "center" }}>
      <Spin size={size} />
    </div>
  );
};
