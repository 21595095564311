import { Col, Row } from "antd";
import React from "react";
import { dateFromString } from "../../../utils/dateFromString";
import { LocalCarreteroIcon } from "../../../components/localCarretero/LocalCarreteroIcon";
import { ServicioAgregarActividad } from "./_ServicioAgregarActividad";
import { ServiciosActividades } from "./_ServiciosActividades";

export const Servicio = ({ servicio }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={`TableRow ${open && "TableRowSelected"}`}>
      <Row onClick={() => setOpen(!open)}>
        <Col span={2}>{servicio.asistenciaID}</Col>
        <Col span={7}>{servicio.proveedor}</Col>
        <Col span={3}>{servicio.servicio}</Col>
        <Col span={2}>{dateFromString(servicio.fechaAsignacion).dateStep}</Col>
        <Col span={2}>
          {dateFromString(servicio.fechaTiempoLlegada).dateStep}
        </Col>
        <Col span={1}>
          <LocalCarreteroIcon localCarretero={servicio.localCarretero} />
        </Col>
        <Col span={7}>{servicio.ubicacion}</Col>
      </Row>
      {open && (
        <>
          <ServicioAgregarActividad  servicio={servicio}/>
          <ServiciosActividades />
        </>
      )}
    </div>
  );
};
