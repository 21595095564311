import { types } from "../types/types";

const init = {
  loginErr: null,
  registerDeviceErr: null

};

export const authErrReducer = (state = init, action) => {
  switch (action.type) {

    case types.setRegisterDeviceErr:
      return {
        ...state,
        registerDeviceErr: action.payload,
      };

    case types.setLoginErr:
      return {
        ...state,
        loginErr: action.payload,
      };

    default:
      return state;
  }
};
