import { Col, Divider, Row, Switch } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelTextarea from "../../../../../../components/input/LabelTextarea";
import { DatosSucursal } from "../../DatosSucursal";
import BotonDivLeft2 from "../../../../../../components/Boton/BotonDivLeft2";
import LabelSelect from "../../../../../../components/select/LabelSelect";
import LabelNumber from "../../../../../../components/input/LabelNumber";
import LabelText from "../../../../../../components/input/LabelText";
import { cuentaEspecialGNPOpciones } from "../../../../../../constants/cons";
 import { AsignacionApi } from "../../../asignacionApi/AsignacionApi";
import { useForma } from "./useForma";

const localOpciones = [
  { texto: "local", valor: "local" },
  { texto: "carretero", valor: "carretero" },
];
/////
export const Forma = ({ handleVerMapa, handleHistorial }) => {

  //
  const {
    datos,
    esGnpApi,
    estatusFinalID,
    handleChange,
    handleChangeSelect,
    handleCuentaEspecial,
    handleSwitch,
    handleClick,
    err,
  } = useForma();

  ///////////////////
  return (
    <div style={{ width: 350 }}>
      <div style={{ marginBottom: 10 }}>
        <Row style={{ marginBottom: 20 }}>
          <Col span={12}>
            <BotonDivLeft2
              onClick={handleHistorial}
              tituloBoton="Historial de Asignación"
            />
          </Col>
          <Col span={12}>
            <BotonDivLeft2
              onClick={handleVerMapa}
              tituloBoton="Asignar Proveedor (mapa)"
            />
          </Col>
        </Row>
      </div>

      <AsignacionApi />
      
      <Divider />
      <DatosSucursal />

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <Switch
            checkedChildren="Si pago directo"
            unCheckedChildren="No pago directo"
            onChange={(val) => handleSwitch(val, "esPagoDirecto")}
          />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        {esGnpApi && (
          <Col span={12}>
            <LabelSelect
              label="Cuenta Especial GNP"
              name="cuentaEspecialGNP"
              value={datos.cuentaEspecialGNP}
              onChange={handleCuentaEspecial}
              opciones={cuentaEspecialGNPOpciones}
            />
          </Col>
        )}
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={12}>
          {" "}
          <LabelSelect
            label="Local / Carretero"
            name="localCarretero"
            onChange={handleChangeSelect}
            opciones={localOpciones}
          />
        </Col>

        <Col span={12}>
          <LabelNumber
            name="KM"
            label="KM"
            onChange={handleChange}
            value={datos.KM}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="tiempoLlegada"
            value={datos.tiempoLlegada}
            label="Tiempo llegada (mins)"
            onChange={handleChange}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="costo"
            label="Costo"
            onChange={handleChange}
            value={datos.costo}
          />
        </Col>
        <Col span={12} style={{ marginTop: "22px" }}>
          <LabelNumber
            name="costoCliente"
            label="Costo cliente"
            onChange={handleChange}
            value={datos.costoCliente}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="costoAutorizado"
            label="Costo autorizado (excedente)"
            onChange={handleChange}
            value={datos.costoAutorizado}
          />
        </Col>
        <Col span={12}>
          <LabelText
            name="quienAutoriza"
            label="Quien autoriza"
            onChange={handleChange}
            value={datos.quienAutoriza}
          />
        </Col>
      </Row>

      <div
        style={{
          marginBottom: "22px",
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1rem",
        }}
      >
        <div>
          <LabelTextarea
            name="movitoExcedente"
            value={datos.motivoExcedente}
            label="Comentario excedente"
            onChange={handleChange}
          />
        </div>

        <div>
          <LabelTextarea
            name="comentarios"
            value={datos.comentarios}
            label="Comentario de asignación"
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Row gutter={10}>
          <Col>
            <BotonSubmit
              disabled={estatusFinalID !== 1}
              onClick={() => handleClick(0)}
              tituloBoton="No asignar"
              icon={<CloseCircleOutlined />}
            />
          </Col>
          <Col>
            <BotonSubmit
              disabled={estatusFinalID !== 1}
              onClick={() => handleClick(1)}
              tituloBoton="Asignar"
              icon={<CheckCircleOutlined />}
            />
          </Col>
          <Col>
            <div style={{ marginTop: 5, color: "#ca2228" }}>{err}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
