import { Loader } from "@googlemaps/js-api-loader"
import { MAP_KEY } from "../constants/cons";


// Reemplaza 'YOUR_API_KEY' con tu clave de API de Google Maps
const apiKey =  MAP_KEY


export const getDistance = async (origin, destination) => {

  const loader = new Loader({
    apiKey,
    version: "weekly",
  });
  const google = await loader.load()
  await loader.importLibrary('maps')
  await loader.importLibrary('places')
  const service = new google.maps.DistanceMatrixService();

  const request = {
    origins: [origin],
    destinations: [destination],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
    avoidHighways: false,
    avoidTolls: false,
  };

  const response = await service.getDistanceMatrix(request)
  return (response?.rows[0].elements[0]?.distance?.value / 1000) ?? 0

}