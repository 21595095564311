import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startGetCitasAbiertas } from '../../context/actions/asistenciasActions'
import CardPage from '../../layout/CardPage'
import { Listado } from './Listado'

export const CitasAbiertas = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(startGetCitasAbiertas())
    }, [])
    return (
        <CardPage titulo="Citas Abiertas">
            <Listado />
        </CardPage>
    )
}
