export  function normalizarValores(valor) {
  if (typeof valor === "string") {
    // Eliminar acentos, caracteres especiales y los símbolos +, -, * del valor
    return valor.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0300\u036fñ#`+@*;']/g, "");
  } else if (typeof valor === "object") {
    if (Array.isArray(valor)) {
      // Si el valor es un array, recorrer sus elementos de manera recursiva
      return valor.map(normalizarValores);
    } else {
      // Si el valor es un objeto, recorrer sus propiedades de manera recursiva
      const nuevoObjeto = {};
      for (let key in valor) {
        if (valor.hasOwnProperty(key)) {
          nuevoObjeto[key] = normalizarValores(valor[key]);
        }
      }
      return nuevoObjeto;
    }
  }
  return valor;
}


