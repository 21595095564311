import { Avatar, Badge } from "antd";
import Meta from "antd/lib/card/Meta";
import "./ChatIndividual.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ChatIndividual = ({ message, setAsistencia, setSubtituloChat, setId, leido }) => {
  const { connection } = useSelector((state) => state.chatReducer);

  const history = useHistory();

  const [escribiendo, setEscribiendo] = useState(null);
  const [mensaje, setMensaje] = useState(message.mensaje.texto);
  const [chatNoLeido, setChatNoLeido] = useState(leido);

  useEffect(() => {
    const recibirMensaje = (newMessage) => {
      if (newMessage.asistenciaId !== message.asistenciaId) {
        return;
      }

      setMensaje(newMessage.texto);
      setChatNoLeido(true);
    };
    connection?.on("message", recibirMensaje);

    const alguienEscribiendo = (userName, asistenciaId) => {
      if (asistenciaId !== message.asistenciaId) {
        return;
      }

      setEscribiendo(`${userName} esta escibiendo...`);
    };
    connection?.on("alguienEscribiendo", alguienEscribiendo);

    const stopTyping = (asistenciaId) => {
      if (asistenciaId !== message.asistenciaId) {
        return;
      }

      setEscribiendo(null);
    };
    connection?.on("stopTyping", stopTyping);

    return () => {
      connection?.off("message", recibirMensaje);
      connection?.off("alguienEscribiendo", alguienEscribiendo);
      connection?.off("stopTyping", stopTyping);
    };
  });

  const onClick = () => {
    // setAsistencia(message.asistenciaId);
    history.push(`/detalleAsistencia/${message.asistenciaId}`)
    setSubtituloChat("");
    setId(message.id);
    setChatNoLeido(false);

    connection.send("LoadMensajes", message.id);
    connection.send("UpdateChatCabinaNoLeido", message.asistenciaId)
    connection.send("CountChatsCabinaNoLeidos")
  };

  const divEscribiendo = (
    <div style={{ color: "#31c686", fontSize: 12 }}>{escribiendo}</div>
  );

  const divMensaje = (
    <div>
      <div style={{ color: "#8696a0", fontSize: 13, display: "block" }}>
        {mensaje?.substring(0, 40)}
      </div>
    </div>
  );

  const divTitulo = (
    <div style={{ fontSize: 15 }}>{`Asistencia ${message.asistenciaId}`}</div>
  );

  return (
    <div
      className="chat-individual"
      style={{ marginBottom: 16, direction: "ltr" }}
      onClick={onClick}
    >
      <Badge dot={chatNoLeido}>
        <Meta
          style={{ height: 60, width: 220 }}
          avatar={
            <Avatar src="https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/151efb5d798e28e7d95090087b38d49f" />
          }
          title={divTitulo}
          description={escribiendo ? divEscribiendo : divMensaje}
        />
      </Badge>
    </div>
  );
};

export default ChatIndividual;
