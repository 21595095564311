export const customObjet = (array) => {
  const groupedData = {};
  array.forEach(item => {
    if (!groupedData[item.campaniaID]) {
      groupedData[item.campaniaID] = {};
    }
    if (!groupedData[item.campaniaID][item.tipoAsistenciaID]) {
      groupedData[item.campaniaID][item.tipoAsistenciaID] = [];
    }
    groupedData[item.campaniaID][item.tipoAsistenciaID].push(item);
  });
  return groupedData;

}