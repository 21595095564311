import { types } from "../types/types";
import { URL_BASE } from "../../constants/url";
import { get } from "../../utils/fetch";
import { composeErrorNotification } from "../../utils/composeNotification";


export const getDesglose = (id) => {
  return async dispatch => {
    const result = await get(`${URL_BASE}/api/DesgloseCabina/GetDesgloseByAsistenciaID/${id}`)
    if (result.code === 0) {
      dispatch(actionSaveCostos(result.data))
    } else {
      dispatch(composeErrorNotification(result.error))
    }
  }

}

const actionSaveCostos = (payload) => ({
  type: types.saveCostos,
  payload

})