import { consLogged } from "../../constants/consLogged";
import { types } from "../types/types";

const init = {
  detailsService: {},
  fieldsToCapture: []
};

export const hdiReducer = (state = init, action) => {
  switch (action.type) {

    case types.saveHdiDetailsService:
      return {
        ...state,
        detailsService: action.payload
      }
      case types.saveHDIFieldsToCapture:
        return {
          ...state,
          fieldsToCapture: action.payload
        }
    default:
      return state;
  }
};
