import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SiniestroItem } from "./SiniestroItem";

export const SiniestrosLista = () => {
  const { ultimaVerificacion, siniestros } = useSelector(
    (state) => state.gnpApiReducer
  );
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={4} className="tituloColumna">
          Folio
        </Col>
        <Col span={3} className="tituloColumna">
          Hora
        </Col>
        <Col span={7} className="tituloColumna">
          Nombre
        </Col>
        <Col span={3} className="tituloColumna">
          Especial
        </Col>
        <Col span={3} className="tituloColumna">
          VIP
        </Col>
      </Row>
      {siniestros.map((s, i) => (
        <SiniestroItem key={i} siniestro={s} />
      ))}
    </div>
  );
};
