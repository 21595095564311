import { getCarreteros } from "../../context/actions/carreteroActions";
import { useDispatch } from "react-redux";
import { CardCarreteo } from "./CardCarretero";
import styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const Carretero = () => {
  const dispatch = useDispatch();
  const { carreteros } = useSelector((state) => state.carreteroReducer);
  useEffect(() => {
    dispatch(getCarreteros());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getCarreteros());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);
  // const carreteros = []
  if (!carreteros.length) return <div>No hay servicios por asignar</div>;
  return (
    <div className={styles.servicios}>
      {carreteros?.map((carretero, i) => (
        <div key={i}>
          <div>
            <h2>{carretero.asistencia}</h2>
            <div className={styles.cards}>
              {carretero?.proveedores?.map((proveedor, index) => (
                <div key={index}>
                  <CardCarreteo
                    proveedorSucursalId={proveedor.proveedorSucursalId}
                    proveedor={proveedor.proveedor}
                    tiempo={proveedor.tiempo}
                    costo={proveedor.costo}
                    asistenciaId={carretero.asistencia}
                    proveedorId={proveedor.proveedorId}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
