import dayjs from "dayjs";
import { CLIENTES_IDS } from "../../constants/cons";
import { URL_BASE_ASISTENCIAS, URL_BASE_HDI, ver } from "../../constants/url";
import { composeErrorNotification, composeSuccessNotification, } from "../../utils/composeNotification";
import { createHDIBody } from "../../utils/createHdiBody";
import { get, post, put } from "../../utils/fetch";
import { normalizarValores } from "../../utils/ObjetoSinAcentos";
import { notification } from "./notificationActions";
import { types } from '../types/types';
import { hdiClient } from '../../utils/hdiApiClient';


export const createHDIService = (body) => {
  return async (dispatch, getState) => {
    const clienteId = getState().detalleReducer.asistencia.asegurado.campania.clienteID
    if (CLIENTES_IDS.HDI !== clienteId) {
      return
    }
    const detallesAssistance = getState().detalleReducer.asistencia;
    const bodyHdi = normalizarValores(createHDIBody(detallesAssistance, body))
    const result = await post(`${URL_BASE_HDI}/asistencias`, bodyHdi)
    if (result.code === 0) {
      if (result.data.completado === true) {
        return dispatch(notification(composeSuccessNotification('Reporte HDI Creado')));
      }
      return dispatch(notification(composeErrorNotification("Error en HDI-SIGSA", `${result.data?.mensaje}`)));

    } else {
      dispatch(
        notification(
          composeErrorNotification("Error en HDI-SIGSA", `${result.error}`)
        )
      );
    }

  }
}

export const hdiTipoFechas = {
  fechaAsignacion: 17,
  fechaArribo: 18,
  fechaTermino: 19,
  fechaCancelado: 20,
  fechaCita: 21
}


export const putNotification = (tipoFecha, datos = {}) => {
  return async (dispatch, getState) => {
    const clienteId = getState().detalleReducer.asistencia.asegurado.campania.clienteID
    const nombreComercial = getState().detalleReducer.proveedorCostos.proveedorSucursal?.proveedor?.nombreComercial
    if (CLIENTES_IDS.HDI !== clienteId) {
      return
    }
    const {asistenciaID, numeroReporteHdi} = getState().detalleReducer.asistencia;

    const fecha = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    let hdiBody = {
      numeroReporteHdi,
      cliente: 'cabina',
      version: ver,
      numeroReporteProveedor: asistenciaID,
      tipoFecha: tipoFecha,
      fecha: datos?.fechaCita ? dayjs(datos?.fechaCita).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : fecha,
      comentario: {
        comentario: 'Actualizando servicio',
        fecha,
      }
    }

    if (tipoFecha === hdiTipoFechas.fechaAsignacion) {


      hdiBody.informacionVial = {
        nombreComercial,
        idTipoTiempoSubEvento: datos.localCarretero === 'local' ? 32 : datos.localCarretero === 'carretero' ? 33 : '',
        fechaArribo: fecha.toString(),
        sFechaArribo: fecha.toString(),
        montoExcedente: datos?.costoAutorizado ?? 0,
        motivoExcedente: datos?.movitoExcedente ?? '',
        tiempoArribo: datos?.tiempoLlegada ?? 0 ,
      }


    }


    const bodyNormalize = normalizarValores(hdiBody)
    console.log({hdiBody})


    const result = await put(`${URL_BASE_HDI}/notificacion`, bodyNormalize)


    try {
      if (result.completado) {
        dispatch(notification(composeSuccessNotification('Fecha actualizada en HDI')))
      } else {
        dispatch(notification(composeErrorNotification("Error en HDI-SIGSA", `${result.mensaje}`)))
      }
    } catch (e) {
      dispatch(notification(composeErrorNotification("Error en HDI-SIGSA Desconocido", `${e}`)))
    }
  }

}


/// Implementació de la api de HDI
export const getHdiService = (asistenciaID) => {
  return async (dispatch) => {
    try {
      const {data} = await hdiClient(`/reporte/${asistenciaID}`)
      dispatch(saveDetailsService(data))
    } catch (error) {
      dispatch(notification(composeErrorNotification("Error en HDI-SIGSA", `${error}`)))
    }
  }
}
const saveDetailsService = (detailsService) => ({
  type: types.saveHdiDetailsService,
  payload: detailsService
})

export const getFieldsHdiToCapture = (serviceId) => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_ASISTENCIAS}/GetCampos/${serviceId}`);

    if (result.code === 0) {
      dispatch(saveFieldsToCapture(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexión - Get campos",
            `${result.error}`
          )
        )
      );
    }
  }
}

const saveFieldsToCapture = (fieldsToCapture) => ({
  type: types.saveHDIFieldsToCapture,
  payload: fieldsToCapture
})
