import {Button, Table, Tooltip} from "antd";
import styles from './Proveedor.module.css'
import {useEffect, useState} from "react";
import {get} from "../../../utils/fetch";
import {URL_PROVEEDORES_ACTIVOS} from "../../../constants/url";
import dayjs from "dayjs";
import {ReloadOutlined} from "@ant-design/icons";

export const ProveedoresActivos = () => {

    const columns = [

        {
            title: 'Nombre Proveedor',
            dataIndex:
                'nombreProveedor',
            key:
                'nombreProveedor',
        }
        ,

        {
            title: "ID último servicio",
            dataIndex: "idUltimoServicio",
            key: "proveedorId",
        },

        {
            title: "Fecha último servicio",
            dataIndex: "ultimoServicio",
            key: "ultimoServicio",
            render: (text, record) => (<span>{dayjs(record.ultimoServicio).format('DD-MM-YYYY')}</span>)

        },
        {
            title: "Hora",
            render: record => (<span>{dayjs(record.ultimoServicio).format("HH:mm")}</span>)
        },
        {
            title: "Total: servicios/día",
            key: "serviciosDelDia",
            dataIndex: "serviciosDelDia"
        }
    ]
    const getProveedores = async () => {
        const data = await get(URL_PROVEEDORES_ACTIVOS)
        setSource(data.data)
    }
    const [source, setSource] = useState(null);


    useEffect(() => {
        getProveedores()
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
                getProveedores()
                console.log("en el intervalo")
            }
            , 1.8e+6);
        return () => clearInterval(interval);
    }, []);


    return (
        <>
            <h1 className='card__titulo'>Proveedores Activos</h1>
            <Table
                columns={columns}
                dataSource={source}
            >

            < /Table>
            <Tooltip title="Actualizar">
                <Button
                    onClick={() => getProveedores()} href="#" className={styles.float} size="large" type="primary"
                    icon={<ReloadOutlined/>} shape="circle"
                />
            </Tooltip>

        </>
    );
};