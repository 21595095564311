import { Col, Input, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import BotonSubmit from '../../../components/Boton/BotonSubmit'
import { startAutorizarServicio } from '../../../context/actions/capturaActions'

export const RequiereAuth = ({ handleNuevo }) => {
    const dispatch = useDispatch()
    const [claveAutorizacion, setClaveAutorizacion] = useState("")

    const autorizar = () => {
        dispatch(startAutorizarServicio(claveAutorizacion))
            .then(resul => {
                if (resul.code === 0)
                    handleNuevo()
            })
    }
    return (<>


        <Row gutter={10} style={{ marginTop: 20 }}>
            <Col span={8}>
                <Input.Password
                    value={claveAutorizacion}
                    onChange={({ target }) => setClaveAutorizacion(target.value)}
                    placeholder='Clave Supervisor'
                    visibilityToggle={false}
                />
            </Col>
            <Col span={8}>
                <BotonSubmit
                    onClick={autorizar}
                    tituloBoton="Authorizar y Crear Servicio"
                />
            </Col>
        </Row>


    </>)
}
