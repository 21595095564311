import { types } from "../types/types";

const init = {
  connection: null,
  connectionProveedores: null
};

export const chatReducer = (state = init, action) => {
  switch (action.type){

    case types.setConnection:
      return {
        ...state,
        connection: action.payload,
      };
    case types.setConnectionProveedores:
      return {
        ...state,
        connectionProveedores: action.payload,
      }
    default:
      return state;
  }
};