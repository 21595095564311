import { Col, Row } from "antd";
import React from "react";
import { IncidenciasAsignacion } from "./_IncidenciasAsignacion";
import { IncidenciasArribo } from "./_IncidenciasArribo";
import { BotonListadoIncidencias } from "./_BotonListadoIncidencias";
import { useSelector } from "react-redux";

export const Incidencias = () => {
  const { asistencia } = useSelector((state) => state.detalleReducer);

  return (
    <Row style={{ padding: 15 }}>
      {asistencia.estatusID === 2 && asistencia.estatusFinalID === 1 && (
        <Col span={6}>
          <IncidenciasAsignacion />
        </Col>
      )}
      {asistencia.estatusID > 2 && asistencia.estatusFinalID === 1 && (
        <Col span={6}>
          <IncidenciasArribo />
        </Col>
      )}
      {asistencia.estatusID > 1 && (
      <Col span={6}>
        <BotonListadoIncidencias />
      </Col>
    )}
    </Row>
  );
};
