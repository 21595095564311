import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { ServiciosSelect } from "../../../../components/select/ServiciosSelect";
import { startCrearServicioSigsa } from "../../../../context/actions/gnpApiActions";

export const BotonConfirmar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [servicioID, setServicioID] = useState();
  const [showErr, setShowErr] = useState(false);
  const { tipoServicio } = useSelector(
    (state) => state.gnpApiReducer.siniestro
  );
  const { catServiciosGNP } = useSelector((state) => state.gnpApiReducer);

  const handleClick = () => {
    if (!servicioID) {
      setShowErr(true);
      return;
    }

    dispatch(startCrearServicioSigsa(servicioID)).then((asistenciaID) => {
      if (asistenciaID)
        history.push(`/detalleAsistencia/${asistenciaID}`);
    });
  };

  const handleChange = (val) => setServicioID(val);

  ///////////
  return (
    <Row gutter={10} style={{ marginTop: 30 }}>
      <Col span={6}>
        <ServiciosSelect
          arr={catServiciosGNP}
          onChange={handleChange}
          value={servicioID}
          label={`Servicio: ${tipoServicio}`}
        />
      </Col>
      <Col span={6} style={{ paddingTop: 22 }}>
        <BotonSubmit
          disabled={!servicioID}
          onClick={handleClick}
          tituloBoton="Confirmar Servicio"
        />
      </Col>
      <Col span={6} style={{ paddingTop: 22 }}>
        {showErr && (
          <span style={{ color: "#ca2228" }}>Escoge el servicio</span>
        )}
      </Col>
    </Row>
  );
};
