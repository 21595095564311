import React from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { startDesBloquearServicio } from "../../../context/actions/detalleActions";
import CardPage from "../../../layout/CardPage";
import { Conclusion } from "../conclusion/Conclusion";
import { Datos } from "./Datos";

export const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { asistenciaID } = useParams();
  const regresar = () => {
    dispatch(startDesBloquearServicio(asistenciaID));
    setTimeout(() => history.push("/misServicios"), 200);
  };
  return (
    <CardPage
      titulo={`Detalle de servicio`}
      tituloBoton="Regresar"
      accionBoton={regresar}
    >
      <Conclusion />
      <Datos />
    </CardPage>
  );
};
