import React from "react";
import { useSelector } from "react-redux";
import { RenglonSemaforo } from "../RenglonSemaforo";
import { TituloSemaforo } from "../TituloSemaforo";

export const CapturandoDatos = () => {
  const { asistenciasSemaforo } = useSelector((state) => state.semaforoReducer);
  const asistencias = asistenciasSemaforo.filter((a) => a.estatusID === 1);
  return (
    <>
      <TituloSemaforo titulo="Capturando Datos" count={asistencias.length} />
      {asistencias.map((a, i) => (
        <RenglonSemaforo
          key={i}
          asistencia={a}
          fecha={a.fechaLlamadaIniciada}
        />
      ))}
    </>
  );
};
