import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import LabelTextarea from "../../../../components/input/LabelTextarea";
import { TipoHistorialSelect } from "../../../../components/select/TipoHistorialSelect";
import { startPostComentarios } from "../../../../context/actions/detalleActions";

export const Create = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const [comentarios, setComentarios] = useState("");
  const [tipo, setTipo] = useState("");

  const handleClick = () => {
    if (comentarios !== "" && tipo !== "") {
      const body = { asistenciaID, comentarios, tipo };

      dispatch(startPostComentarios(body));
      setComentarios("");
      setTipo("");
    }
  };
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <TipoHistorialSelect value={tipo} onChange={(val) => setTipo(val)} />
      </div>
      <div style={{ marginBottom: 10 }}>
        <LabelTextarea
          value={comentarios}
          name="comentarios"
          label="Comentarios"
          onChange={({ target }) => setComentarios(target.value)}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <BotonSubmit onClick={handleClick} tituloBoton="Agregar Comentario" />
      </div>
    </div>
  );
};
