import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { startQueryQualitas } from "../../../../../context/actions/polizarioActions"
import { SpinCentro } from "../../../../../components/spin/SpinCentro"

export const PolizarioQualitas = () => {
    const dispatch = useDispatch()
    const { loadingPolizario, qualitasResponse } = useSelector(state => state.polizarioReducer)

    useEffect(() => {
        dispatch(startQueryQualitas())
    }, [])

    if (loadingPolizario) return <SpinCentro />
    if (Object.keys(qualitasResponse).length === 0) return <></>

    return <div>
        <div style={{color: "#ca2228", fontWeight: 600, marginBottom: 15}}>Respuesta Qualitas - Equipo Pesado</div>
        <div>Estatus Póliza: {qualitasResponse?.estatusPoliza ? qualitasResponse?.estatusPoliza : "NO PROCEDE"}</div>
        <div>Mensaje: {qualitasResponse?.mensaje}</div>
    </div>
}

