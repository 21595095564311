import { Col, Row } from "antd";
import { LabelValor } from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import { DatosGeneral } from "./DatosGeneral";

export const DatosDestino = () => {
  const { ubicacionDestino } = useSelector(
    (state) => state.gnpApiReducer.siniestro
  );

  const {
    estadoDestino,
    municipioDestino,
    coloniaDestino,
    calleDestino,
    numeroExteriorDestino,
    codigoPostalDestino,
    latitudDestino,
    longitudDestino,
    referenciasDestino,
  } = ubicacionDestino;

  return (
    <>
      <div className="subTitulo">Destino</div>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor
            label="Direccion"
            valor={`${calleDestino} ${numeroExteriorDestino}`}
          />
        </Col>

        <Col span={4}>
          <LabelValor label="Colonia" valor={coloniaDestino} />
        </Col>
        <Col span={4}>
          <LabelValor label="Municipio" valor={municipioDestino} />
        </Col>
        <Col span={4}>
          <LabelValor label="Estado" valor={estadoDestino} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor label="Referencias" valor={referenciasDestino} />
        </Col>
        <Col span={4}>
          <LabelValor label="Cp" valor={codigoPostalDestino} />
        </Col>
        <Col span={4}>
          <LabelValor label="Latitud" valor={latitudDestino} />
        </Col>
        <Col span={4}>
          <LabelValor label="Longitud" valor={longitudDestino} />
        </Col>
      </Row>
    </>
  );
};
