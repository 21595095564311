import { Tag } from "antd";

////
export const TagSelector = ({
  tag: { estatusID, estatus, estatusFinal, estatusFinalID },
}) => {
  ///
  let color = "";
  let texto = "";

  ///
  if (estatusFinalID === 4 || estatusFinalID === 5) {
    color = "#ca2228";
    texto = estatusFinal;
  } else {
    texto = estatus;
    if (estatusID === 1) color = "";
    if (estatusID === 2) color = "#ccc";
    if (estatusID === 3) color = "#595fcf";
    if (estatusID === 4) color = "#b9e3b3";
    if (estatusID === 5) color = "#65a35d";
    if (estatusID === 6) color = "#0d6302";
  }

  ////
  return (
    <Tag
      color={color}
      style={{ width: "100%", textAlign: "center", borderRadius: 5 }}
    >
      {texto}
    </Tag>
  );
};
