import React, { useEffect, useState } from 'react';

const Counter = ({ targetDate, showDays =false, showHours=true, showMinutes=true, showSeconds=false }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    if(!difference) return ({days: 0, hours: 0, minutes: 0, seconds: 0})
    let timeLeft = {};

    if (difference > 0) {
      if (showDays) {
        timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      }
      if (showHours) {
        timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      }
      if (showMinutes) {
        timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
      }
      if (showSeconds) {
        timeLeft.seconds = Math.floor((difference / 1000) % 60);
      }
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  const getColor = () => {
    const minutes = timeLeft.minutes || 0;
    if(minutes === 0){
      return {color: 'red', className: 'blink_text'}
    }
    else if (minutes <= 10) {
      return {color: 'red', className: ''}
    } else if (minutes <= 20) {
      return {color: 'orange', className: ''}
    }else {
      return {color: 'green', className: ''}
    }
  };

  return (
    <div style={{ color: getColor()?.color || 'green' }} className={getColor()?.className}>
      {/*{showDays && <span>{formatTime(timeLeft.days)}:</span>}*/}
      {showHours && <span>{formatTime(timeLeft.hours)}:</span>}
      {showMinutes && <span>{formatTime(timeLeft.minutes)}</span>}
      {/*{showSeconds && <span>{formatTime(timeLeft.seconds)}</span>}*/}
      {/*Solo mostrar hora y minutos*/}

    </div>
  );
};

export default Counter;
