import React from "react";
import { useSelector } from "react-redux";

export const ContadorResultados = () => {
  const { servicios } = useSelector(
    (state) => state.asistenciasReducer.validaInfo
  );


  return (
    <div className="contadorResultados">
      Servicios previos: {servicios.length}
    </div>
  );
};
