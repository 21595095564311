export const consLogged = {
  INICIANDO: "iniciando",
  LOGGED: "logged",
  NOTLOGGED: "not logged",
};

export const CAMPO_TIPO = {
  TEXTO: "texto",
  LISTA: "lista",
};
