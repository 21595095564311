import { types } from "../types/types";

const init = {
  folderPagina: "proveedor",
  asistenciaDetalle: {},
  asistencia: {},
  distanceInKmBetweenPlacesByApiGoogle: 0,
  loadingDetalle: true,
  sucursalesByServicio: [],
  asistenciaHistorial: [],
  proveedorGeos: {},
  proveedorCostos: {},
  historialAsignacion: [],
  asistenciasByValidador1: [],
  asistenciaFiles: [],
};
export const detalleReducer = (state = init, action) => {
  switch (action.type) {
    case types.saveDistance:
      return {
        ...state,
        distanceInKmBetweenPlacesByApiGoogle: action.payload,
      }
    case types.storeAsistenciaFiles:
      return {
        ...state,
        asistenciaFiles: action.payload,
      };

    case types.clearProveedorCostos:
      return {
        ...state,
        proveedorCostos: {},
      };

    case types.storeAsistenciasByValidador1:
      return {
        ...state,
        asistenciasByValidador1: action.payload,
      };
    case types.storeHistorialAsignacion:
      return {
        ...state,
        historialAsignacion: action.payload,
      };

    case types.storeAsistenciaHistorial:
      return {
        ...state,
        asistenciaHistorial: action.payload,
      };
    case types.storeProveedorCostos: {
      return {
        ...state,
        proveedorCostos: action.payload,
      };
    }

    case types.clearProveedorGeos:
      return {
        ...state,
        proveedorGeos: {},
      };

    case types.setProveedorGeos:
      return {
        ...state,
        proveedorGeos: action.payload,
      };

    case types.storeSucursalesByServicio:
      return {
        ...state,
        sucursalesByServicio: action.payload,
      };

    case types.setLoadingDetalle:
      return {
        ...state,
        loadingDetalle: action.payload,
      };
    case types.storeAsistencia:
      return {
        ...state,
        asistencia: action.payload,
      };
    case types.setFolderPagina:
      return {
        ...state,
        folderPagina: action.payload,
      };
    case types.setAsistenciaDetalle:
      return {
        ...state,
        asistenciaDetalle: action.payload,
      };

    default:
      return state;
  }
};
