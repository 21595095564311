export const addGeos = ({ mapProveedores, proveedorGeos }) => {
  if (
    proveedorGeos.geo1 !== null &&
    proveedorGeos.geo1 !== undefined &&
    proveedorGeos.geo1 !== null
  ) {
    var fill = mapProveedores.current.getLayer("fillGeo1");
    if (typeof fill === "undefined") {
      mapProveedores.current.addSource("sourceGeo1", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: validateEmpty(proveedorGeos.geo1),
          },
        },
      });

      ///
      mapProveedores.current.addLayer({
        id: "fillGeo1",
        type: "fill",
        source: "sourceGeo1", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#676cf5",
          "fill-opacity": 0.5,
        },
      });
    }
  }

  ////
  if (
    proveedorGeos.geo2 !== null &&
    proveedorGeos.geo2 !== undefined &&
    proveedorGeos.geo2 !== null
  ) {
    var fill = mapProveedores.current.getLayer("fillGeo2");
    if (typeof fill === "undefined") {
      mapProveedores.current.addSource("sourceGeo2", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: validateEmpty(proveedorGeos.geo2),
          },
        },
      });

      ///
      mapProveedores.current.addLayer({
        id: "fillGeo2",
        type: "fill",
        source: "sourceGeo2", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#ba1155",
          "fill-opacity": 0.5,
        },
      });
    }
  }
};

const validateEmpty = (string) => {
  if (string !== undefined && string !== "" && string !== null)
    return JSON.parse(string);

  return [];
};
