import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BASE, ver } from "../constants/url";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalVersionShow,
  setValidVersion,
} from "../context/actions/appActions";
import { Button, Modal } from "antd";

export const ValidateVersion = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const showModal = useSelector((state) => state.appReducer.showModal);

  useEffect(() => {
    axios.get(`${URL_BASE}/api/ver/GetFrontCabinaVer`).then((res) => {
      if (res.data !== ver) {
        dispatch(setValidVersion(false));
        setOpen(showModal === "show");
      }
    });
  }, []);

  const onOk = () => window.location.reload();

  const onCancel = () => {
    dispatch(setModalVersionShow(false));
    setOpen(false);
  };
  return (
    <>
      <Modal
        onCancel={() => setOpen(false)}
        title={"Nueva Versión de Cabina"}
        open={open}
        footer={null}
      >
        <div>Existe una nueva versión de SIGSA-CABINA disponible</div>
        <div>
          Si estas capturando un servicio escoge "no", con calma después de tu
          captura puedes recargar la página
        </div>
        <div>
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Button
              style={{ width: 70 }}
              size="small"
              onClick={onCancel}
              type="primary"
              danger
            >
              No
            </Button>
            <Button
              style={{ width: 70 }}
              size="small"
              onClick={onOk}
              type="primary"
            >
              Si
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
