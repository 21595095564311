import React from "react";

export const useForm = (servicio) => {
  const [body, setBody] = React.useState({
    asistenciaID: servicio.asistenciaID,
  });

  const handleChange = ({ target: { name, value } }) => {
    setBody({
      ...body,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    setBody({
      ...body,
      [name]: value,
    });
  };

  const handleClick = () => {
    console.log(body);
  };

  return { body, handleChange, handleChangeSelect, handleClick };
};
