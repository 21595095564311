import { EyeOutlined, LockOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useHistory } from "react-router-dom";

export const useColumns = () => {
  const history = useHistory();

  const handleClick = ({ asistenciaID }) =>
    history.push(`/detalleAsistencia/${asistenciaID}`);

  const columns = [
    {
      title: "AsistenciaID",
      dataIndex: "asistenciaID",
      key: "asistenciaID",
    },
    {
      title: "Fecha",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Validador",
      dataIndex: "validador1",
      key: "validador1",
    },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      key: "estatus",
    },
    // {
    //   title: "",
    //   width: 30,
    //   render: (text, record) => {
    //     return (
    //       <EyeOutlined
    //         onClick={() => handleClick(record)}
    //         style={{ color: "#ca2228" }}
    //         className="eyeIcon"
    //       />
    //     );
    //   },
    // },
  ];

  return { columns };
};
