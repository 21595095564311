import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useClienteCampania = (campaniaID) => {
  let inicial = {
    clienteNombre: "--",
    campaniaNombre: "--",
    campaniaID: 0,
    clienteID: "",
  };

  const [data, setData] = useState(inicial);
  const { clientesCampanias } = useSelector(
    (state) => state.asistenciasReducer
  );

  useEffect(() => {
    clientesCampanias.map((cliente) => {
      //
      if (cliente.campanias && cliente.campanias.length > 0) {
        cliente.campanias.map((campania) => {
          //
          if (campania.campaniaID == campaniaID) {
            let salida = {};
            salida.clienteID = cliente.clienteID;
            salida.clienteNombre = cliente.clienteNombre;
            salida.campaniaNombre = campania.campaniaNombre;
            salida.campaniaID = campania.campaniaID;
            setData(salida);
          }
        });
      }
    });
  }, [clientesCampanias]);

  return data;
};
