import { types } from "../types/types";

const initialState = {
  conditions: [],
  currentPDFTitleInScreen: null,
  currentPDFId: null,
  currenPdfUrl: null,
  modalMainOpen: false,
  filesMinimized: []
};

export const libraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.saveConditions:
      console.log(action)
      return {
        ...state,
        conditions: action.payload
      }
    case types.setCurrentUrlPDF:
      return {
        ...state,
        currenPdfUrl: action.payload
      }
    case types.setPDFInScreen:
      return {
        ...state,
        currentPDFTitleInScreen: action.payload
      }
    case types.setCurrentPDFId:
      return {
        ...state,
        currentPDFId: action.payload
      }
    case types.saveMinimezedFiles:
      return {
        ...state,
        filesMinimized: state.filesMinimized.find(file => file.id === action.payload.id)
          ? [...state.filesMinimized]
          : [...state.filesMinimized, action.payload]
      }
      case types.removeMinimizedFile:
      return {
        ...state,
        filesMinimized: state.filesMinimized.filter(file => file.id !== action.payload)
      }
    case types.openModalMain:
      return {
        ...state,
        modalMainOpen: action.payload
      }
    default:
      return state;
  }
};

