import { Row, Col, Rate, Divider } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getProveedorCostos } from "../../../../context/actions/detalleActions";
import { Estrellas } from "./Estrellas";
import ModalForm from "../../../../components/modalForm/ModalForm";

export const DatosSucursal = () => {
  const dispatch = useDispatch();
  //
  const { lp1, lp2, lp1m, lp2m, banderazo, xkm, proveedorSucursal } =
    useSelector((state) => state.detalleReducer.proveedorCostos);

  const { asistencia } = useSelector((state) => state.detalleReducer);
  const paso = asistencia.estatusID;
  const phoneCliente  = asistencia?.telefono || null
  const phoneProveedor = asistencia?.proveedorSucursal?.proveedor?.contactoTelefono1 || null
  const id = asistencia.asistenciaID
  //
  useEffect(() => dispatch(getProveedorCostos()), []);

  if (!proveedorSucursal) return <div></div>;

  const {
    nombre,
    domicilio,
    telefono1,
    telefono2,
    telefono3,
    proveedor,
    comentarios,
  } = proveedorSucursal;

  return (
    <div>
      <div className="subTitulo">{proveedor?.nombreComercial}</div>

      {/* {paso >= 2 && paso !== 5 && <ModalForm phone={phoneCliente}  asistenciaID={id} phoneProveedor={phoneProveedor}/>} */}

      <Estrellas estrellas={proveedor?.estrellas} />
      <div style={{ marginTop: 10 }}>Sucursal {nombre}</div>
      <div>{domicilio}</div>
      <div>Horario de Atención: {proveedorSucursal.horarioAtencion}</div>
      <div>
        {telefono1} {telefono2} {telefono3}{" "}
      </div>
      <div>{comentarios}</div>
      <Row style={{ marginTop: 10, marginBottom: 15 }}>
        <Col span={12}>
          <span className="subTitulo">LP1: </span>
          {lp1}
        </Col>
        <Col span={12}>
          <span className="subTitulo">LP2:</span> {lp2}
        </Col>
        <Col span={12}>
          <span className="subTitulo">LP1m:</span> {lp1m}
        </Col>
        <Col span={12}>
          <span className="subTitulo">LP2m:</span> {lp2m}
        </Col>
        <Col span={12}>
          <span className="subTitulo">Banderazo:</span> {banderazo}
        </Col>
        <Col span={12}>
          <span className="subTitulo">X Km:</span> {xkm}
        </Col>
      </Row>

      <Divider />
    </div>
  );
};
