import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startSetIncidencia } from "../../../../context/actions/incidenciasActions";

export const useIncidenciaForm = (tipo) => {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState({});
  const { asistenciaID } = useParams();

  const handleChange = (value) => {
    if (tipo === 1) {
      setBody({ motivoIncidenciaAsignacion: value });
    } else if (tipo === 2) {
      setBody({ motivoIncidenciaArribo: value });
    }
  };

  const handleSubmit = () => {
    let post = { asistenciaID, momentoIncidencia: tipo };
    if (tipo === 1) {
      post = { ...post, motivoIncidencia: body.motivoIncidenciaAsignacion };
    } else {
      post = { ...post, motivoIncidencia: body.motivoIncidenciaArribo };
    }
    dispatch(startSetIncidencia(post));
    setBody({});
  };

  return { body, handleChange, handleSubmit };
};
