import { types } from "../types/types";

export const  setValidVersion = (isValid) => {
  return dispatch  => {
    dispatch(actionValidVersion(isValid))
  }
}

export const setModalVersionShow  = (showModal) => {
  return dispatch => {
    dispatch(actionHidenModal(showModal))
  }
}
const actionValidVersion = (payload) => ({
  type: types.changeValidationVersion,
  payload

})

const actionHidenModal = (payload) => ({
  type: types.showModalValidVersion,
  payload
})
