export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGF0OTAwMCIsImEiOiJjbDNhaXRnbzEwMXk1M2Vwa3F0MHFrNHFkIn0.1Y9DYByZSC-BkeaKFHpMZw";

export const MAP_KEY = "AIzaSyA-j0i2vMQUKpmYph5mUn1C85UkCT6VRvg";

export const mapOptions = {
  zoom: 12,
  center: {
    lat: 19.3887859,
    lng: -99.06231179999999,
  },
};

export const PAGINA_DETALLE = {
  proveedor: "proveedor",
  mapa: "mapa",
  archivos: "archivos",
  hisotrial: "historial",
  chat: "chat",
  llamadas: "llamadas",
  // placas: "placas",
};

/// estatus del servicio vivo
export const ESTATUS = {
  iniciado: 1,
  asignado: 2,
  arribo: 3,
  concluido: 4,
};

/// estatus del servicvio en lo general
export const ESTATUS_FINAL = {
  abierto: 1,
  concluido: 2,
  otorgado: 3,
  canceladoConCosto: 4,
  canceladoSinCosto: 5,
};

export const mesesOptions = [
  { texto: "Ene", valor: 1 },
  { texto: "Feb", valor: 2 },
  { texto: "Mar", valor: 3 },
  { texto: "Abr", valor: 4 },
  { texto: "May", valor: 5 },
  { texto: "Jun", valor: 6 },
  { texto: "Jul", valor: 7 },
  { texto: "Ago", valor: 8 },
  { texto: "Sep", valor: 9 },
  { texto: "Oct", valor: 10 },
  { texto: "Nov", valor: 11 },
  { texto: "Dic", valor: 12 },
];

export const CLIENTES_IDS = {
  EL_AGUILA: "ve7SE85lpL",
  EL_AGUILADev: "pgqSExjHvc",

  HDI: "D2pceuqnx8",
  gnp: "Eprtn7jQhx",
  QUALITAS: "vwFMCVA2IX",
  QUALITAS_EP_CAMPANIAID: 60,
  QUALITAS_EP_CAMPANIAID_DEV: 58,
};

export const cuentaEspecialGNPOpciones = [
  { texto: "DACERO", valor: "DACERO" },
  { texto: "EL ORO", valor: "EL ORO" },
  { texto: "SHCP", valor: "SHCP" },
  { texto: "RENT A MATIC ITZA", valor: "RENT A MATIC ITZA" },
  { texto: "TEQUILA SAUZA", valor: "TEQUILA SAUZA" },
  { texto: "AUTOFLETES CHIHUAHUA", valor: "AUTOFLETES CHIHUAHUA" },
  { texto: "ELEMENT", valor: "ELEMENT" },
  { texto: "CONSERVAS LA COSTEÑA", valor: "CONSERVAS LA COSTEÑA" },
  { texto: "ALPURA", valor: "ALPURA" },
];

export const motivoIncidenciaAsignacionOpciones = [
  { label: "Pendiente autrización", value: "Pendiente autrización" },
  { label: "Zona Roja", value: "Zona Roja" },
  { label: "Servicio especial", value: "Servicio especial" },
  { label: "Problemas climátios", value: "Problemas climátios" },
];

export const motivoIncidenciaArriboOpciones = [
  { label: "Zona Roja", value: "Zona Roja" },
  { label: "Servicio especial", value: "Servicio especial" },
  {
    label: "Usuario no encontrado en lugar",
    value: "Usuario no encontrado en lugar",
  },
  {
    label: "Datos geolocalización incorrectos",
    value: "Datos geolocalización incorrectos",
  },
  { label: "Problemas climátios", value: "Problemas climátios" },
  { label: "Plantón / marcha", value: "Plantón / marcha" },
  { label: "Calle cerrada", value: "Calle cerrada" },
];
