import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, } from "react-router-dom";
import { startGetCatServiciosGNP, startLoadServicio } from "../../../../context/actions/gnpApiActions";
import CardPage from "../../../../layout/CardPage";
import { Datos } from "./Datos";

export const Catch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { folioAsignacion } = useParams();
  useEffect(() => {
    dispatch(startLoadServicio(folioAsignacion));
    dispatch(startGetCatServiciosGNP());
  }, []);

  return (
    <CardPage
      titulo={`Confirmar Servicio`}
      tituloBoton="Regresar"
      accionBoton={() => history.push("/GnpApi")}
    >
      <Datos />
    </CardPage>
  );
};
