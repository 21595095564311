import { Col, Row } from "antd";
import React from "react";
import { Create } from "./Create";
import { Listado } from "./Listado";

export const Historial = () => {
  return (
    <Row gutter={10}>
      <Col span={18}>
        <Listado />
      </Col>
      <Col span={6}>
        <Create />
      </Col>
    </Row>
  );
};
