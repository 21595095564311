import React from "react";
import { useSelector } from "react-redux";
import Item from "./Item";

export default function ListItems() {
   const { asistenciaFiles } = useSelector((state) => state.detalleReducer);
   console.log(asistenciaFiles);

  return (
    <div>
      {asistenciaFiles.map((f, i) => (
        <Item key={i} file={f} />
      ))}
    </div>
  );
}
