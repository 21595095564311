import { URL_BASE_POLIZARIO, URL_BASE_POLIZARIO_QUALITAS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";

import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetLista = (validador1) => {
  return async (dispatch) => {
    dispatch(setLoadingPolizario(true));
    // const url = `${URL_BASE_POLIZARIO}/Elaguila/GetLista/${validador1}`;
    const url = `${URL_BASE_POLIZARIO}/Elaguila/GetByPolizaExacta/${validador1}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeElAguilaLista(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get lista",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setVistaResultados(1));
    dispatch(setLoadingPolizario(false));
  };
};

export const startGetDetalle = (elaguilaID) => {
  return async (dispatch) => {
    dispatch(setLoadingPolizario(true));
    const url = `${URL_BASE_POLIZARIO}/Elaguila/GetDetalle/${elaguilaID}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeElAguilaPoliza(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get detalle",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setVistaResultados(2));
    dispatch(setLoadingPolizario(false));
  };
};

const storeElAguilaLista = (lista) => ({
  type: types.storeElAguilaLista,
  payload: lista,
});

const storeElAguilaPoliza = (poliza) => ({
  type: types.storeElAguilaPoliza,
  payload: poliza,
});

export const setClienteID = (clienteID) => ({
  type: types.setClienteID,
  payload: clienteID,
});

export const setLoadingPolizario = (state) => ({
  type: types.setLoadingPolizario,
  payload: state,
});

export const setVistaResultados = (vista) => ({
  type: types.setVistaResultados,
  payload: vista,
});


/////// QUALITAS

export const startQueryQualitas = () => {
  return async (dispatch, getState) => {
    const { validador1 } = getState().asistenciasReducer.captura
    const validador = validador1.split("-")

    if(validador.length !== 2) return;

    dispatch(setLoadingPolizario(true));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toISOString().split('T')[0];

    const body = {
      "poliza": validador[0],
      "inciso": validador[1],
      "fecha": fechaFormateada
    }

    const url = `${URL_BASE_POLIZARIO_QUALITAS}/api/query`
    const result = await post(url, body);
    dispatch(setLoadingPolizario(false));

    dispatch(setQualitasResponse(result.data))
  }
}

const setQualitasResponse = (payload) => ({
  type: types.setQualitasResponse,
  payload
})