import { Badge, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


import {
  BookOutlined,
  CarOutlined,
  FolderViewOutlined,
  InsertRowAboveOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  UnorderedListOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { startLogoff } from "../context/actions/loginActions";
import { useEffect, useState } from "react";
import { ModalTag } from "../views/library/components/ModalTag";

export default function MenuLayout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [chatsSinLeer, setChatsSinLeer] = useState(0);

  //const {connection} = useSelector((state) => state.chatReducer);

  const handleClick = ({key}) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };

  // useEffect(() => {
  //   connection?.send("CountChatsCabinaNoLeidos")
  // });

  // useEffect(() => {
  //   const countChats = (count) => {
  //     setChatsSinLeer(count);
  //   };
  //   connection?.on("CountChatsCabinaNoLeidos", countChats);

  //   return (() => {
  //     connection?.off("CountChatsCabinaNoLeidos", countChats);
  //   })
  // });

  return (
    <>
      <Menu
        onClick={handleClick}
        style={{marginTop: 10}}
        theme='light'
        defaultSelectedKeys={["0"]}
        mode='inline'
      >
        <Menu.Item key='/crearServicio' icon={<PlusCircleOutlined/>}>
          Crear Servicio
        </Menu.Item>
        {/* <Menu.Item key="/misServicios" icon={<OrderedListOutlined />}>
        Mis Servicios
      </Menu.Item> */}
        <Menu.Item key='/todosServicios' icon={<UnorderedListOutlined/>}>
          Todos los servicios
        </Menu.Item>
        <Menu.Item key='/citasAbiertas' icon={<UnorderedListOutlined/>}>
          Citas
        </Menu.Item>
        <Menu.Item key='/recursos' icon={<FolderViewOutlined/>}>
          Recursos
        </Menu.Item>

        <Menu.Item key='/library' icon={<BookOutlined/>}>
          Biblioteca
        </Menu.Item>

        {/* <Menu.Item key='/listadoChats' icon={<WechatOutlined/>}>
          <Badge count={chatsSinLeer} offset={[15, 0]} size='small'>
            Chat
          </Badge>
        </Menu.Item> */}

        <Menu.Item key='/semaforoHome' icon={<InsertRowAboveOutlined/>}>
          Semaforo
        </Menu.Item>
        <Menu.Item key={"/provedoresActivos"} icon={<PoweroffOutlined/>}>
          Proveedores Activos
        </Menu.Item>
        <Menu.Item key='/carretero' icon={<CarOutlined/>}>
          Carretero
        </Menu.Item>

        <Menu.Item key='/logoff' icon={<PoweroffOutlined/>}>
          Cerrar sesión
        </Menu.Item>
      </Menu>
      <ModalTag/>
    </>
  );
}
