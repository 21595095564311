import { useEffect, useState } from "react";
import styles from "./HDI.module.css";
import { hdiClient } from "../../utils/hdiApiClient";
import CardPage from "../../layout/CardPage";
import { Col, Divider, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { DayjsConfig } from "../../utils/configDayjs";
import dayjs from "dayjs";

const style = {
  display: "grid",
  gap: "40px",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr",
  textAlign: "center",
};

//
export const HdiApi = () => {
  const history = useHistory();
  const format = DayjsConfig("DD-MM-YYYY");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getServiciosAbierto = async () => {
    try {
      const { data } = await hdiClient.get("/Reporte/ReportesAbiertos");
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getServiciosAbierto().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      void getServiciosAbierto();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //
  return (
    <CardPage titulo={"Servicios HDI - API"}>
      <div style={{ display: "flex", gap: "50px" }}>
        <Col span={6}>
          <div className={styles["card-services"]}>
            <p style={{ textAlign: "center", margin: "0" }}>{data.length}</p>
          </div>
        </Col>
        <div>
          <div style={{ ...style, fontWeight: "bold" }}>
            <p>Id</p>
            <p>Numero reporte HDI</p>
            <p>Evento</p>
            <p>Lugar de Asistencia</p>
            <p>Vigencia</p>
          </div>
          <Divider
            type="horizontal"
            style={{ padding: "0px", margin: "0px", marginBottom: "5px" }}
          />

          {data.map((item, index) => {
            if (loading) return <Spin key={index} />;
            return (
              <div
                style={style}
                key={index}
                onClick={() =>
                  history.push({
                    pathname: `detallesHdiApi/${item.id}`,
                    state: {
                      dateOnClick: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    },
                  })
                }
                className={styles.item_hdi}
              >
                <p>{item.id}</p>
                <p>{item.infoGeneral_NoReporteHDI}</p>
                <p>{item.subEvento_DescripcionSubEvento}</p>
                <p>{item.lugarAsistencia_Municipio}</p>
                <p>
                  {format(item.ludatosPoliza_FechaInicioVigencia)} -{" "}
                  {format(item.datosPoliza_FechaFinVigencia)}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </CardPage>
  );
};
