import { CheckCircleOutlined } from '@ant-design/icons'
import React from 'react'

export const Check = ({ mostrar }) => {

    if (mostrar !== true) return <></>
    return (
        <CheckCircleOutlined style={{ fontSize: 18, color: 'green' }} />
    )
}
