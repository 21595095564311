import React from "react";
import { useSelector } from "react-redux";

export const SiniestrosNumero = ({ clave }) => {
  const { siniestros, ultimaVerificacion } = useSelector(
    (state) => state.gnpApiReducer
  );
  return (
    <div>
      <div
        className={
          siniestros.length > 0
            ? "siniestros tieneSineistros"
            : "siniestros sinSineistros"
        }
      >
        {siniestros.length}
      </div>
      <div className="ultimaVerificacion">
        {" "}
        {ultimaVerificacion} {clave}
      </div>
    </div>
  );
};
