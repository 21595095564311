import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENTES_IDS } from "../../../../constants/cons";
import { PolizarioAguila } from "./polizarioAguila/PolizarioAguila";
import { PolizarioQualitas } from "./polizarioQualitas/PolizarioQualitas";



export const Polizarios = ({ clienteID }) => {
    const { campaniaID } = useParams()
    if (CLIENTES_IDS.EL_AGUILA === clienteID ||
        CLIENTES_IDS.EL_AGUILADev === clienteID)
        return (<PolizarioAguila />)

    if (CLIENTES_IDS.QUALITAS_EP_CAMPANIAID == campaniaID ||
        CLIENTES_IDS.QUALITAS_EP_CAMPANIAID_DEV == campaniaID)
        return (<PolizarioQualitas />)

    return (<>{CLIENTES_IDS.QUALITAS_EP_CAMPANIAID_DEV}</>);
}