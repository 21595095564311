import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  startGetAsistenciasCitas,
  startGetServicioParaSemaforo,
} from "../../../context/actions/semaforoActions";

export const useGetSemaforo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetServicioParaSemaforo());
    dispatch(startGetAsistenciasCitas());

    const interval = setInterval(() => {
      dispatch(startGetServicioParaSemaforo());
      dispatch(startGetAsistenciasCitas());
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch]);
};
