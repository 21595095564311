import { Check } from "../../components/check/Check";
import { TagSelector } from "../../components/tagSelector/TagSelector";
import { dateFromString } from '../../utils/dateFromString'

export const useColumns = () => {
  const columns = [
    {
      title: "AsistenciaID",
      dataIndex: "asistenciaID",
      key: "asistenciaID",
      width: 150
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    }, {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Fecha Llamada",
      dataIndex: "fechaLlamadaIniciada",
      key: "fechaLlamadaIniciada",
      render: (row, record) => dateFromString(row).soloFecha
    },
    {
      title: "Fecha Cita",
      dataIndex: "fechaCita",
      key: "fechaCita",
      render: (row, record) => dateFromString(row).soloFecha
    },
    {
      title: "Cita",
      dataIndex: "esCita",
      key: "esCita",
      render: (row, record) => <Check mostrar={row} />
    },

    {
      title: "Estatus",
      width: 155,
      render: (tag, record) => <TagSelector tag={tag} />,
    },
  ];

  return { columns };
};
