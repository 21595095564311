import React from "react";
import List from "./List";
import FormUploadFile from "./FormUploadFile";

export const Archivos = () => {
  return (
    <>

      <FormUploadFile />
      <List />
    </>
  );
};
