import { Spin } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";

export const SimboloSMS = () => {
  const { esperandoSMS } = useSelector((state) => state.asistenciasReducer);

  if (esperandoSMS == 2)
    return (
      <CheckCircleOutlined
        style={{ marginTop: 5, fontSize: 20, color: "green" }}
      />
    );

  if (esperandoSMS == 1)
    return <Spin style={{ marginTop: 6, marginLeft: 5 }} />;

  return <></>;
};
