import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BotonDivLeft2 from "../../components/Boton/BotonDivLeft2";
import LabelSelect from "../../components/select/LabelSelect";
import { mesesOptions } from "../../constants/cons";
import {
  setFiltroAsistencia,
  startLoadServicios,
} from "../../context/actions/asistenciasActions";
import { aniosOptions } from "../../utils/aniosOptions";
import { getYearNow } from "../../utils/getYearNow";

export const Controles = ({ filter }) => {
  const [anios, setAnios] = useState([]);
  const { mes, anio } = useSelector(
    (state) => state.asistenciasReducer.filtroAsistencia
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const a = aniosOptions(2022, getYearNow());
    setAnios(a);
  }, []);

  const onChange = (body) => {
    dispatch(setFiltroAsistencia(body));
  };

  const onSubmit = () => dispatch(startLoadServicios());

  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={4}>
        <Input placeholder="buscar..." onChange={filter} />
      </Col>
      <Col span={10}></Col>
      <Col span={4}>
        <LabelSelect
          value={mes}
          onChange={(value) => onChange({ mes: value })}
          opciones={mesesOptions}
        />
      </Col>
      <Col span={4}>
        <LabelSelect
          value={anio}
          onChange={(value) => onChange({ anio: value })}
          opciones={anios}
        />
      </Col>
      <Col span={2} style={{ paddingTop: 3 }}>
        <BotonDivLeft2 onClick={onSubmit} tituloBoton="Filtrar" />
      </Col>
    </Row>
  );
};
