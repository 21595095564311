export const useData = (object) => {

  const generalData = [
    {label: 'Id', value: object.id},
    {label: "Id Evento", value: object.evento_IdEvento},
    {label: "Evento", value: object.evento_DescripcionEvento},
    {label: "Sub Evento", value: object.subEvento_dSubEvento},
    {label: "Descripcion Sub Evento", value: object.subEvento_DescripcionSubEvento},
    {label: "No Reporte HDI", value: object.infoGeneral_NoReporteHDI},
    {label: "Estatus", value: object.infoGeneral_Estatus},
    {label: "Agencia Atiende", value: object.infoGeneral_AgenciaAtiende},
    {label: "Fecha Creacion", value: object.infoGeneral_FechaCreacion},
    {label: "Linea Negocio", value: object.datosPoliza_LineaNegocio},
    {label: "Asegurado", value: object.datosPoliza_Asegurado},
    {label: "Oficina Emision", value: object.datosPoliza_OficinaEmision},
    {label: "Poliza", value: object.datosPoliza_Poliza},
    {label: "Certificado", value: object.datosPoliza_Certificado},
    {label: "Fecha Inicio Vigencia", value: object.datosPoliza_FechaInicioVigencia},
    {label: "Fecha Fin Vigencia", value: object.datosPoliza_FechaFinVigencia},
    {label: "Marca", value: object.datosVehiculo_Marca},
    {label: "Tipo Vehiculo", value: object.datosVehiculo_TipoVehiculo},
    {label: "Modelo", value: object.datosVehiculo_Modelo},
    {label: "Vin", value: object.datosVehiculo_Vin},
    {label: "Placas", value: object.datosVehiculo_Placas},
    {label: "Color", value: object.datosVehiculo_Color},
    {label: "Tipo Equipo Pesado", value: object.datosVehiculo_TipoEquipoPesado},
    {label: "Reporta", value: object.reporta_reporta},
    {label: "Conductor", value: object.reporta_Conductor},
    {label: "Apellido Paterno", value: object.reporta_ApellidoP},
    {label: "Apellido Materno", value: object.reporta_ApellidoM},
    {label: "Telefono Reporte", value: object.reporta_TelefonoReporte},
    {label: "Telefono Contacto", value: object.reporta_TelefonoContacto},
    {label: "Estado", value: object.lugarAsistencia_Estado},
    {label: "Municipio", value: object.lugarAsistencia_Municipio},
    {label: "Tipo Falla", value: object.lugarAsistencia_TipoFalla},
    {label: "Lugar Asistencia", value: object.lugarAsistencia_LugarAsistencia1},
    {label: "Esta Registrado", value: object.estaRegistrado ? 'Si' : 'No'}
  ]
  return {generalData}

};
