import { Col, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BotonDivLeft2 from "../../../../components/Boton/BotonDivLeft2";
import { dateFromString } from "../../../../utils/dateFromString";
import { DatosSucursal } from "./DatosSucursal";

//
export const DatosProveedor = ({
  handleVerMapa,
  handleHistorial,
  handleDesgloseCostos,
}) => {
  const {
    fechaTiempoLlegada,
    costo,
    costoCliente,
    costoAutorizado,
    motivoExcedente,
    quienAutoriza,
    localCarretero,
    cuentaEspecialGNP,
    esGnpApi,
    esPagoDirecto,
    esConexion,
    km,
  } = useSelector((state) => state.detalleReducer.asistencia);
  //

  return (
    <>
      <Row>
        <Col span={8}>
          <BotonDivLeft2
            onClick={handleHistorial}
            tituloBoton="Historial de Asignación"
          />
        </Col>
        <Col span={8}>
          <BotonDivLeft2 onClick={handleVerMapa} tituloBoton="Mapa" />
        </Col>

        <Col span={8}>
          <BotonDivLeft2
            onClick={handleDesgloseCostos}
            tituloBoton="Deglose de costos"
          />
        </Col>
      </Row>
      <Divider />

      <DatosSucursal />

      {esGnpApi && (
        <div>
          <span className="subTitulo">Cuenta especial: </span>{" "}
          {cuentaEspecialGNP ? cuentaEspecialGNP : "-"}
        </div>
      )}
      <div>
        <span className="subTitulo">Servicio: </span> {localCarretero}
      </div>
      <div>
        <span className="subTitulo">Km: </span> {km}
      </div>
      <div>
        <span className="subTitulo">Pago directo: </span>{" "}
        {esPagoDirecto === true ? "Si" : "No"}
      </div>
      <div>
        <span className="subTitulo">Conexion: </span>{" "}
        {esConexion === true ? "Si" : "No"}
      </div>
      <div>
        <span className="subTitulo">Costo:</span> {costo}
      </div>
      <div>
        <span className="subTitulo">Costo cliente:</span> {costoCliente}
      </div>
      <div>
        <span className="subTitulo">Costo autorizado:</span> {costoAutorizado}
      </div>
      <div>
        <span className="subTitulo">Quien autoriza:</span> {quienAutoriza}
      </div>

      <div>
        <span className="subTitulo">Motivo Excedente</span> {motivoExcedente}
      </div>

      <div>
        <span className="subTitulo">Tiempo llegada:</span>{" "}
        {dateFromString(fechaTiempoLlegada).dateStep}
      </div>
    </>
  );
};
