import { Col, DatePicker, Row, Switch } from 'antd'
import React, { useState } from 'react'

export const Cita = ({ AgregaDatos, esCita }) => {

    const [fechaCita, setFechaCita] = useState(false)

    const handleFecha = (val, strVal) => {
        AgregaDatos({ fechaCita: strVal })
    }

  
    return (
        <Row>
            <Col span={7}>
                <Switch
                    checkedChildren="Es cita"
                    unCheckedChildren="No es cita"
                    onChange={(val) => AgregaDatos({ esCita: val })}
                />
            </Col>
            <Col span={17}>
                {esCita && (<DatePicker onChange={handleFecha} showTime style={{ width: "100%" }} />)}
            </Col>
        </Row>
    )
}
