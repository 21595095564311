import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import { useEffect, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { MAPBOX_TOKEN } from "../../../../constants/cons";
import { getLat, getLng } from "../../../../utils/geoVerify";
import { addGeos } from "./addGeos";
import { removeGeo } from "./removeGeos";
import { useMarkers } from "./useMarkers";
mapboxgl.accessToken = MAPBOX_TOKEN;

export const MapaProveedores = () => {
  const { setMarkers, sucursalSelected } = useMarkers();
  const mapProveedores = useRef(null);
  const mapContainerP = useRef(null);

  const markerP = useRef(null);
  const { sucursalesByServicio, proveedorGeos } = useSelector(
    (state) => state.detalleReducer
  );
  const {
    direccionOrigen,
    latOrigen,
    lngOrigen,
    direccionDestino,
    latDestino,
    lngDestino,
    referenciaVisualOrigen,
  } = useSelector((state) => state.detalleReducer.asistencia);

  /// INICIAL
  //////////////////////////////////////////////

  useLayoutEffect(() => {
    if (mapProveedores.current) return; // initialize map only once

    mapProveedores.current = new mapboxgl.Map({
      controls: true,
      container: mapContainerP.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [getLng(lngOrigen), getLat(latOrigen)],
      zoom: 10,
    });
    mapProveedores.current.addControl(new mapboxgl.NavigationControl());

    /// Origen
    markerP.current = new mapboxgl.Marker({})
      .setLngLat([getLng(lngOrigen), getLat(latOrigen)])
      .setPopup(
        new mapboxgl.Popup().setHTML(`
          <h1 style="color: red">Origen</h1>
              <h3>${direccionOrigen}</h3>
              <div>${referenciaVisualOrigen}</div>
              `)
      )
      .addTo(mapProveedores.current);

    if ((lngDestino !== null) & (lngDestino !== "")) {
      var el = document.createElement("div");

      el.className = "marker";

      //// destino
      markerP.current = new mapboxgl.Marker(el)
        .setLngLat([getLng(lngDestino), getLat(latDestino)])
        .setPopup(
          new mapboxgl.Popup().setHTML(`
          <h1 style="color: red">Destino</h1>
          <h3>${direccionDestino}</h3>
                `)
        )
        .addTo(mapProveedores.current);

      //// proveedores
      mapProveedores.current.on("load", () => {
        mapProveedores.current.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [
                [lngOrigen, latOrigen],
                [lngDestino, latDestino],
              ],
            },
          },
        });

        ///
        mapProveedores.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#888",
            "line-width": 4,
          },
        });
      });

      ///
    }

    setMarkers(sucursalesByServicio, mapProveedores);
  }, []);

  useEffect(() => {
    removeGeo(mapProveedores);
    addGeos({ proveedorGeos, mapProveedores });
  }, [proveedorGeos]);

  //////////////////////////////////////////////
  return (
    <div>
      <div ref={mapContainerP} style={styles.mapContainer}>
        Container
      </div>
    </div>
  );
};

const styles = {
  mapContainer: {
    height: 470,
    width: "90%",
    marginBottom: 10,
    float: "right",
  },
};
