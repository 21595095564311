import axios from 'axios';
import { URL_HDI } from '../constants/url';

const getToken = async () => {
    const { data} = await axios.post(`${URL_HDI}/l`, {
        "usuarioID": "ESC.01",
        "password": "JsuB2&ws"
    })
    return data
}

export const hdiClient = axios.create({
    baseURL: URL_HDI,
})

hdiClient.interceptors.request.use(async config => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => {
    return Promise.reject(error);
});