import {
  CheckCircleOutlined,
  IdcardOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import Counter from "../../components/tagSelector/countDown/Counter";
import { getMinutesFromNow } from "../../utils/getMinutesFromNow";
import { LocalCarretero } from "./LocalCarretero";
import { getColor } from "./getColor";
import { useState } from "react";
import { dateWithName } from "../../utils/dateWithName";
import { PlacaHoverModal } from "../../components/placaHoverModal/PlacaHoverModal";

export const RenglonSemaforo = ({
  asistencia: {
    estatusID,
    campania,
    ubicacion,
    servicio,
    operador,
    asistenciaID,
    proveedor,
    localCarretero,
    esCita,
    esConexion,
    esGnpApi,
    fechaSugiereProveedor,
    fechaProveedorValidado,
    fechaTiempoLlegada,
    fechaInicioAsignacionAsistencia,
    usuarioInicioAsignacionAsistencia,
    countPropuestas,
    tienePlaca,
    urlPlaca,
  },
  fecha,
}) => {
  ///////

  const history = useHistory();
  const { minutos, lapso } = getMinutesFromNow(fecha);
  const [hover, setHover] = useState(false);

  const color = getColor(
    minutos,
    estatusID,
    localCarretero,
    esCita,
    fechaTiempoLlegada
  );

  const handleClick = () => {
    history.push(`/detalleAsistencia/${asistenciaID}`);
  };

  const claseApi = esGnpApi ? "esGnpApi" : "";
  return (
    <div className={`semaforo__renglon ${color}`} onClick={handleClick}>
      <Row>
        <Col span="21">
          <div>
            <span className={claseApi}>{asistenciaID}</span> | {operador} |{" "}
            {servicio}
          </div>
          <div>
            <span></span>
          </div>
          {estatusID > 1 && <div>{ubicacion}</div>}
          {estatusID > 2 && (
            <div style={{ display: "flex", gap: 140 }}>{proveedor}</div>
          )}
          <div>{campania}</div>
        </Col>
        <Col span="3" style={{ textAlign: "center" }}>
          <LocalCarretero
            localCarretero={localCarretero}
            estatusID={estatusID}
          />

          <div style={{ fontSize: 16, color: "#999" }}>
            {estatusID === 3 ? (
              <Counter targetDate={fechaTiempoLlegada} showHours showMinutes />
            ) : (
              <>{lapso}</>
            )}
          </div>
          {esConexion && (
            <PullRequestOutlined
              style={{ marginTop: 2, fontSize: 25, color: "#999" }}
            />
          )}

          {estatusID > 2 && fechaProveedorValidado && (
            <CheckCircleOutlined style={{ fontSize: 22, color: "#999" }} />
          )}
        </Col>
      </Row>
      {estatusID === 2 && fechaSugiereProveedor && (
        <>
          <div className="esperando">Esperando Proveedor</div>
          {localCarretero === "carretero" && (
            <div className="esperando">{`${countPropuestas} propuestas`}</div>
          )}
        </>
      )}
      {estatusID > 1 &&
        usuarioInicioAsignacionAsistencia &&
        fechaInicioAsignacionAsistencia && (
          <p>
            Asignado desde cabina:{" "}
            {dateWithName(
              fechaInicioAsignacionAsistencia,
              usuarioInicioAsignacionAsistencia
            )}{" "}
          </p>
        )}

      {tienePlaca && (
        <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          <Tooltip title={"Placa cargada correctamente"}>
            <IdcardOutlined
              style={{
                fontSize: "23px",
              }}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
          </Tooltip>
        </div>
      )}

      {hover && <PlacaHoverModal urlPlaca={urlPlaca} />}
    </div>
  );
};
