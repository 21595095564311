import {
  ClockCircleOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Col, DatePicker, Modal, Radio, Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LabelText from "../../../components/input/LabelText";
import LabelTextarea from "../../../components/input/LabelTextarea";
import { ESTATUS_FINAL } from "../../../constants/cons";
import {
  startPostConcluirServicio,
  startUpdateFecha,
} from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";

const options = [
  { label: "Concluido", value: ESTATUS_FINAL.concluido },
  { label: "Otorgado", value: ESTATUS_FINAL.otorgado },
];

export const Concluido = ({ asistencia: { estatusID, fechaConcluido } }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState("");
  const [err, setErr] = useState("");
  const { estatusFinalID, folioGnp, esGnpApi, proveedorSucursal } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  //const { connection } = useSelector((state) => state.chatReducer);

  const {
    asistencia: { costo },
  } = useSelector((state) => state.detalleReducer);

  const { usuarioID } = useSelector((state) => state.userReducer.user);

  ///
  const { asistenciaID } = useParams();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ costo, estatusFinal: 2 });

  useEffect(() => {
    setData({
      ...data,
      costo,
    });
  }, [costo]);

  //
  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  const handleOk = () => {
    if (!data.estatusFinal) {
    }

    const body = {
      ...data,
      costo: data.costo,
      asistenciaID,
      usuarioID,
      folioGnp,
      esGnpApi,
    };
    dispatch(startPostConcluirServicio(body));
    setData({});
    //connection?.send("CerrarChat", asistenciaID, proveedorSucursal?.proveedorID)
    setShow(false);
  };

  const handleCambiarFecha = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 3, // conclusion
    };
    dispatch(startUpdateFecha(body));

    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);

  ////////////////////////////
  if (estatusID === 5 && estatusFinalID === 1)
    return (
      <>
        <div onClick={() => setShow(true)} className="marcarStep">
          <PauseCircleOutlined /> Marcar concluido
        </div>

        <Modal
          title="Concluir Servicio"
          open={show}
          onOk={handleOk}
          onCancel={() => setShow(false)}
        >
          <Row>
            <Col
              span={6}
              style={{ color: "green", textAlign: "center", paddingTop: 10 }}
            >
              <CheckCircleOutlined style={{ fontSize: 60 }} />
            </Col>
            <Col span={18}>
              <div style={{ marginBottom: 10 }}>
                <Radio.Group
                  name="estatusFinal"
                  value={data.estatusFinal}
                  options={options}
                  onChange={handleChange}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
              <div>
                <LabelText
                  onChange={handleChange}
                  label="Costo final"
                  name="costo"
                  value={data.costo}
                />
              </div>
              <div>
                <LabelTextarea
                  value={data.comentarios}
                  onChange={handleChange}
                  label="Comentarios finales"
                  name="comentarios"
                />
              </div>
            </Col>
          </Row>
        </Modal>
      </>
    );

  ////////////////////////////
  const date = dateFromString(fechaConcluido);

  if (estatusID > 5)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Conlcuido"
          open={showModal}
          onOk={handleCambiarFecha}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
      </div>
    );

  ////////////////////////////
  return <div></div>;
};
