// import formatDistance from "date-fns/formatDistance";
// import { es } from "date-fns/locale";
export const getMinutesFromNow = (date) => {
  if (!date) return 0;
  var ahora = new Date();
  var antes = new Date(date);
  var diffMs = ahora - antes; // milliseconds between now & Christmas
  var minutos = Math.round(diffMs / 60000);
  let lapso = "";

  if (minutos < 60) 
    lapso = `${minutos}m`;

  if (minutos >= 60 && minutos < 60 * 24)
    lapso = `${parseFloat(minutos / 60).toFixed(1)}h`;

  if (minutos >= 60 * 24) 
    lapso = `${parseFloat(minutos / 60 / 24).toFixed(1)}d`;

  return { minutos, lapso };
  // var diffDays = Math.floor(diffMs / 86400000); // days
  // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
};
