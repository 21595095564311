import React, { useEffect, useRef, useState } from 'react'
import { useGoogleMap } from "@ubilabs/google-maps-react-hooks"

export const Marker = ({ latLng, setLatLng }) => {

    const map = useGoogleMap();
    const markerRef = useRef()

    useEffect(() => {
        if (!map) return;
        markerRef.current = new window.google.maps.Marker({ map, draggable: true })

        markerRef.current.addListener("dragend", () => {
            const pos = (markerRef.current.getPosition())
            setLatLng({
                lat: pos.lat(),
                lng: pos.lng()
            })


        });

        return () => markerRef.current.removeListener("dragend")

    }, [map])

    useEffect(() => {
        if (!markerRef.current) return;

        markerRef.current.setPosition(latLng)
        map.panTo(latLng)

    }, [latLng, map])




    return (<></>)
}