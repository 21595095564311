import React from 'react'
import BotonDivLeft2 from "../../../components/Boton/BotonDivLeft2";
import { get } from "../../../utils/fetch";
import { URL_BASE_ASISTENCIAS } from "../../../constants/url";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { startLoadAsistencia } from "../../../context/actions/detalleActions";

export const SeccionConcluir = () => {
    const {asistenciaID} = useParams()
    const dispatch = useDispatch()
    const handleConcluir = async () => {
        await get(`${URL_BASE_ASISTENCIAS}/CambioEstatusFinal/${asistenciaID}`).then((data) => {
            if (data.code === 0) {

                dispatch(startLoadAsistencia(asistenciaID))
                message.success('Status cambiado correctamente')
            } else {
                dispatch(startLoadAsistencia(asistenciaID))
                message.error(data.error)
            }
        })

    }
    return (<>
        <BotonDivLeft2
            tituloBoton="Concluir Servicio"
            onClick={handleConcluir}
        />
    </>)
}