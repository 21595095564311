import { HomeOutlined, NodeIndexOutlined } from "@ant-design/icons";
import React from "react";

export const LocalCarreteroIcon = ({ localCarretero }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {localCarretero === "carretero" ? (
        <NodeIndexOutlined style={{ fontSize: 25, color: "#999" }} />
      ) : (
        <HomeOutlined style={{ fontSize: 25, color: "#999" }} />
      )}
    </div>
  );
};
