import React from "react";

export const MapaImagen = () => {
  return (
    <img
      style={{ width: 1000, height: 400 }}
      src="https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(-99.129264,19.381126)/-99.1297,19.3816,14,0/1000x400?before_layer=admin-0-boundary&access_token=pk.eyJ1IjoicGF0OTAwMCIsImEiOiJja3VoaXI5NDcyZTh2Mm9vM2M4MHZlaHgyIn0.h2R7TP1XR6UFNDBq4DCrYw"
    />
  );
};
