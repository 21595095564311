import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, DatePicker } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { startUpdateFecha } from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";
import { useDispatch } from "react-redux";

export const Asignado = ({ asistencia: { estatusID, fechaAsignacion } }) => {
  ////////////////////////////
  const dispatch = useDispatch();

  const date = dateFromString(fechaAsignacion);
  const { asistenciaID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState("");

  const handleModalOK = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 7, // Asignacion
    };
    dispatch(startUpdateFecha(body));
    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);

  if (estatusID > 2)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Asignación"
          open={showModal}
          onOk={handleModalOK}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
      </div>
    );

  ////////////////////////////
  return <div></div>;
};
