import { Col, Row } from "antd";
import { LabelValor } from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import { DatosGeneral } from "./DatosGeneral";

export const DatosOrigen = () => {
  const { ubicacionOrigen } = useSelector(
    (state) => state.gnpApiReducer.siniestro
  );

  const {
    estadoOrigen,
    municipioOrigen,
    coloniaOrigen,
    calleOrigen,
    numeroExteriorOrigen,
    codigoPostalOrigen,
    latitudOrigen,
    longitudOrigen,
    referenciasOrigen,
  } = ubicacionOrigen;

  return (
    <>
      <div className="subTitulo">Origen</div>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor
            label="Direccion"
            valor={`${calleOrigen} ${numeroExteriorOrigen}`}
          />
        </Col>

        <Col span={4}>
          <LabelValor label="Colonia" valor={coloniaOrigen} />
        </Col>
        <Col span={4}>
          <LabelValor label="Municipio" valor={municipioOrigen} />
        </Col>
        <Col span={4}>
          <LabelValor label="Estado" valor={estadoOrigen} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor label="Referencias" valor={referenciasOrigen} />
        </Col>
        <Col span={4}>
          <LabelValor label="Cp" valor={codigoPostalOrigen} />
        </Col>
        <Col span={4}>
          <LabelValor label="Latitud" valor={latitudOrigen} />
        </Col>
        <Col span={4}>
          <LabelValor label="Longitud" valor={longitudOrigen} />
        </Col>
      </Row>
    </>
  );
};
