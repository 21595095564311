import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getConditions } from "../../context/actions/libraryActions";
import { LibraryControls } from "./components/LibraryControls";
import { ConditionsList } from "./components/ConditionsList";
import CardPage from "../../layout/CardPage";

export const Library = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    void dispatch(getConditions());
  }, []);

  return (
    <CardPage titulo={"Biblioteca"}>
      <LibraryControls />
      <ConditionsList />
    </CardPage>
  );
};
