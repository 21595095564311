import React from "react";
import CardPage from "../../../layout/CardPage";
import { useGetServicios } from "./useGetServicios";
import { Servicio } from "./_Servicio";
import { useGetSemaforo } from "./useGetSemaforo";
import { Col, Row } from "antd";

export const SemaforoSeguimiento = () => {
  useGetSemaforo();

  const { serviciosPorArribar } = useGetServicios();
  return (
    <CardPage titulo="Semáforo Seguimiento">
      <div className="subTitulo">{serviciosPorArribar.length} casos</div>
      <Row className="TituloTabla">
        <Col span={2}>ID</Col>
        <Col span={7}>Proveedor</Col>
        <Col span={3}>Servicio</Col>
        <Col span={2}>Asignación</Col>
        <Col span={2}>Llega</Col>
        <Col span={1}>L / C</Col>
        <Col span={7}>Origen</Col>
      </Row>

      {serviciosPorArribar.map((s, i) => (
        <Servicio key={i} servicio={s} />
      ))}
    </CardPage>
  );
};
