import dayjs from "dayjs";
import css from "./enviado.module.css";

const Enviado = ({ m, mismoEmisorAnterior, mismoEmisorSiguiente }) => {
  return (
    <div style={{ display: "block" }}>
      <div style={{ position: "relative" }}>
        <div
          className={css.messageOut}
          style={{ marginBottom: mismoEmisorSiguiente ? 2 : 12 }}
        >
          <span></span>

          <div className={css.messageContainer}>
            {!mismoEmisorAnterior ? (
              <span className={css.messageContainerSpan}>
                <svg
                  viewBox="0 0 8 13"
                  height="13"
                  width="8"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ display: "block", pointerEvents: "none" }}
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 8 13"
                  xmlSpace="preserve"
                >
                  <path
                    opacity="0.13"
                    d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"
                  ></path>
                </svg>
              </span>
            ) : (
              <></>
            )}

            <div
              className={css.messageSquareContainer}
              style={{
                borderRadius: !mismoEmisorAnterior
                  ? "7.5px 0px 7.5px 7.5px"
                  : "7.5px",
              }}
            >
              <div className={css.messageContainerElements}>
                <div className={css.messageContainerText}>
                  <span>{m.texto}</span>
                  <span>
                    <span className={css.messageContainerTextSpan}>
                      <span
                        style={{ flexGrow: 0, width: 19, flexShrink: 0 }}
                      ></span>
                      <span style={{ flexGrow: 0, flexShrink: 0 }}>
                        12:45pm
                      </span>
                    </span>
                  </span>
                </div>

                <div className={css.messageContainerHora}>
                  <div>
                    <span>{`${dayjs(m.fechaMensaje).format('h:mm a')}`}</span>
                  </div>
                </div>
              </div>
              <span></span>
              <div style={{ right: 5, position: "absolute", top: 7 }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enviado;
