import { Menu } from "antd";
import {
  BankOutlined,
  DollarCircleOutlined,
  MessageOutlined,
  PhoneOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFolderPagina } from "../../../context/actions/detalleActions";
import { PAGINA_DETALLE } from "../../../constants/cons";

export default function MenuDetalle() {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("sucursales");

  //const {connection} = useSelector((state) => state.chatReducer);
  const { asistenciaID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );
  const proveedorSucursal = useSelector(
    (state) => state.detalleReducer.asistencia.proveedorSucursal
  );

  const handleClick = ({ key }) => {
    setCurrent(key);
    dispatch(setFolderPagina(key));
  };

  // const onClickChat = () => {
  // 	connection?.send("LoadMensajesChatAsistencia", asistenciaID);
  // 	connection?.send("UpdateChatCabinaNoLeido", asistenciaID)
  // 	connection?.send("CountChatsCabinaNoLeidos")
  // };

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        style={{ marginBottom: 15 }}
      >
        <Menu.Item key={PAGINA_DETALLE.proveedor} icon={<BankOutlined />}>
          Proveedor
        </Menu.Item>
        {/* <Menu.Item key={PAGINA_DETALLE.mapa} icon={<BankOutlined />}>
        Mapa
      </Menu.Item> */}

        <Menu.Item key={PAGINA_DETALLE.archivos} icon={<ToolOutlined />}>
          Archivos
        </Menu.Item>

        <Menu.Item
          key={PAGINA_DETALLE.hisotrial}
          icon={<DollarCircleOutlined />}
        >
          Historial
        </Menu.Item>

        <Menu.Item key={PAGINA_DETALLE.llamadas} icon={<PhoneOutlined />}>
          Agregar llamada
        </Menu.Item>
      </Menu>
    </>
  );
}
