import { useSelector } from "react-redux";
import { HistorialAsignacionItem } from "./HistorialAsignacionItem";

export const HistorialAsignacion = () => {
  const { historialAsignacion } = useSelector((state) => state.detalleReducer);
  return (
    <div>
      {historialAsignacion.map((h, i) => (
        <HistorialAsignacionItem key={i} historial={h} />
      ))}
    </div>
  );
};
