import { notificationInitial } from "../initial/notificationInitial";
import { types } from "../types/types";

const init = {
  clientes: [],
};
export const clientesReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeClientes:
      return {
        ...state,
        clientes: action.payload,
      };

    default:
      return state;
  }
};
