import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { consLogged } from "../constants/consLogged";
import { ValidateVersion } from "../shared/ValidVersion";
import { startLoadInitial } from "../context/actions/initialActions";
import { useDispatch } from "react-redux";

////////////////////////////////////////////////////
export default function PrivateRoute({
  isAuthenticated,
  component: Component,
  ...rest
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoadInitial());
  }, []);

  ////////////////////////////////////////////////////
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated === consLogged.LOGGED ? (
          <>
            {" "}
            <ValidateVersion /> <Component {...props} />{" "}
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
