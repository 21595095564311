export const removeGeo = (mapProveedores) => {
  let fill = mapProveedores.current.getLayer("fillGeo1");

  if (typeof fill !== "undefined") {
    mapProveedores.current.removeLayer("fillGeo1");
    mapProveedores.current.removeSource("sourceGeo1");
  }

  let fill1 = mapProveedores.current.getLayer("fillGeo2");

  if (typeof fill1 !== "undefined") {
    mapProveedores.current.removeLayer("fillGeo2");
    mapProveedores.current.removeSource("sourceGeo2");
  }
};
