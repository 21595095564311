export const keyPairToFields = (obj) => {
  const keys = Object.keys(obj)
  const values = Object.values(obj)
  if (keys.length === 0) {
    return {}
  }
  const fields= {}
  keys.forEach((key, index) => {
    fields[`campo${index + 1}`] = `${key}: ${values[index]}`
  })
  return fields
}
