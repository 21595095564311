import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearProveedorCostos,
  clearProveedorGeos,
  setFolderPagina,
  startGetAsistenciaFiles,
  startLoadAsistencia,
} from "../../context/actions/detalleActions";
import { Spinner } from "../../layout/Spinner";
import { Folder } from "./folder/Folder";
import { Header } from "./header/Header";
import { Etapas } from "./step/Etapas";
import { PAGINA_DETALLE } from "../../constants/cons";
import { useReloadDetalle } from "../../hooks/useReloadDetalle";

export const DetalleServicio = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const { loadingDetalle } = useSelector((state) => state.detalleReducer);

  useEffect(() => {
    dispatch(startLoadAsistencia(asistenciaID));
    dispatch(startGetAsistenciaFiles(asistenciaID));
    dispatch(setFolderPagina(PAGINA_DETALLE.proveedor));
    dispatch(clearProveedorGeos());
    dispatch(clearProveedorCostos());
  }, []);

  useReloadDetalle()

  if (loadingDetalle) return <Spinner size="large" />;

  return (
    <>
      <Header />
      <Etapas />
      <Folder />
    </>
  );
};
