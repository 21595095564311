import { Col, Row } from "antd";
import { LabelValor } from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";

export const DatosVehiculo = () => {
  const { vehiculo } = useSelector((state) => state.gnpApiReducer.siniestro);

  if (!vehiculo) return <></>;
  const {
    tipoVehiculo,
    marca,
    descripcionVehiculo,
    modelo,
    numeroSerie,
    placas,
    color,
  } = vehiculo;

  return (
    <>
      <div className="subTitulo">Vehículo</div>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor label="Tipo de vehículo" valor={tipoVehiculo} />
        </Col>
        <Col span={4}>
          <LabelValor label="Marca" valor={marca} />
        </Col>
        <Col span={4}>
          <LabelValor label="Modelo" valor={modelo} />
        </Col>
        <Col span={4}>
          <LabelValor label="Color" valor={color} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <LabelValor label="Descripción" valor={descripcionVehiculo} />
        </Col>
        <Col span={4}>
          <LabelValor label="Placas" valor={placas} />
        </Col>
        <Col span={4}>
          <LabelValor label="Serie" valor={numeroSerie} />
        </Col>
      </Row>
    </>
  );
};
