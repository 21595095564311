import { Button } from "antd";

export default function BotonSubmit({ tituloBoton, onClick, disabled, icon }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className="botonSubmit"
      icon={icon}
    >
      {tituloBoton}
    </Button>
  );
}
