export const formatCurrency = (number) => {
  if (!number) return "";

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(number);
};
