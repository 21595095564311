import dayjs from "dayjs";
import css from "./recibido.module.css";

const Recibido = ({ m, mismoEmisorAnterior, mismoEmisorSiguiente }) => {
  return (
    <div style={{ display: "block" }}>
      <div style={{ position: "relative" }}>
        <div
          className={css.messageIn}
          style={{ marginBottom: mismoEmisorSiguiente ? 2 : 12 }}
        >
          <span></span>

          <div className={css.messageContainer}>
            {!mismoEmisorAnterior ? (
              <span className={css.messageContainerSpan}>
                <svg
                  viewBox="0 0 8 13"
                  height="13"
                  width="8"
                  preserveAspectRatio="xMidYMid meet"
                  style={{ display: "block", pointerEvents: "none" }}
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 8 13"
                  xmlSpace="preserve"
                >
                  <path
                    opacity="0.13"
                    fill="#0000000"
                    d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"
                  ></path>
                </svg>
              </span>
            ) : (
              <></>
            )}

            {!mismoEmisorAnterior ? (
              <div className={css.profilePicture}>
                <img
                  src={
                    m.tipoUsuario === 2
                      ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBaEdDBmobW4yjRaK26JgcIjtGpo-0y4seEA&usqp=CAU"
                      : "https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/151efb5d798e28e7d95090087b38d49f"
                  }
                />
              </div>
            ) : (
              <></>
            )}

            <div
              className={css.messageSquareContainer}
              style={{
                borderRadius: !mismoEmisorAnterior
                  ? "0px 7.5px 7.5px 7.5px"
                  : "7.5px",
              }}
            >
              <div className={css.messageContainerElements}>
                {!mismoEmisorAnterior ? (
                  <div className={css.messageNamePersona}>
                    <span>{m.emisorNombre}</span>
                  </div>
                ) : (
                  <></>
                )}

                <div className={css.messageContainerText}>
                  <span>{m.texto}</span>
                  <span>
                    <span className={css.messageContainerTextSpan}>
                      <span>12:45pm</span>
                    </span>
                  </span>
                </div>

                <div className={css.messageContainerHora}>
                  <div>
                    <span>{`${dayjs(m.fechaMensaje).format('h:mm a')}`}</span>
                  </div>
                </div>
              </div>
              <span></span>
              <div style={{ right: 5, position: "absolute", top: 7 }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recibido;
