import React from "react";
import { useSelector } from "react-redux";
import { Col_24_12_8_6 } from "../../../../components/col/Col_24_12_8_6";
import { MyRow } from "../../../../components/row/MyRow";
import { Campo } from "./Campo";

export const Fase2Forma = () => {
  const { capturaCampos } = useSelector((state) => state.asistenciasReducer);

  if (capturaCampos.length === 0) return <div>sin campos</div>;
  return (
    <>
      <div className='subTitulo'>Datos de Servicio</div>
      <MyRow>
        {capturaCampos.map((campo, i) => (
          // eslint-disable-next-line react/jsx-pascal-case
          <Col_24_12_8_6 key={i}>
            <Campo campo={campo} i={i} />
          </Col_24_12_8_6>
        ))}
      </MyRow>
    </>
  );
};
