import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  cleanCaptura,
  setCapturaStep,
  setEsperandoSMS,
} from "../../../context/actions/capturaActions";
import { setClienteID } from "../../../context/actions/polizarioActions";
import { BuscaValidador } from "./BuscaValidador";
import { setElAguilaAuth } from "../../../context/actions/asistenciasActions";
export const CampaniaItem = ({ campania, clienteID }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const openDialog = () => {
    dispatch(setElAguilaAuth(false));
    dispatch(setEsperandoSMS(0));
    dispatch(setCapturaStep(1));
    dispatch(cleanCaptura());
    dispatch(setClienteID(clienteID));
    setIsVisible(true);
  };

  return (
    <>
      <div className="campaniaItem" onClick={openDialog}>
        {campania.campaniaNombre}
      </div>
      <Modal
        title={`Buscar Validador en ${campania.campaniaNombre}`}
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            Cerrar
          </Button>,
        ]}
      >
        <BuscaValidador
          campaniaID={campania.campaniaID}
          clienteID={clienteID}
        />
      </Modal>
    </>
  );
};
