import { Col, Row } from "antd";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { startGetAsistenciaFile } from "../../../../context/actions/detalleActions";
//import { startDeleteFileProveedor, startDownloadDocumentoProveedor } from "../../../../../context/actions/proveedoresFilesActions";
//
export default function Item({ file }) {
  const dispatch = useDispatch();
  const { id, tipo, nombre, url } = file;

  const handleDownload = () => {

    let name = "";

    if(!nombre.includes('.')){
      const ext = url.split("/").pop().split(".").pop();
      name = `${nombre}.${ext}`
      dispatch(startGetAsistenciaFile(id, tipo, name));
      return;
    }

    dispatch(startGetAsistenciaFile(id, tipo, nombre));
  }

  return (
    <Row gutter={10} className="rowTabla">
      <Col span={4}>{nombre}</Col>

      <Col span={3}>{tipo}</Col>

      <Col span={3}>
        <CloudDownloadOutlined
          onClick={handleDownload}
          className="icon"
          style={{ color: "blue" }}
        />
        {/* <DeleteOutlined
          onClick={handleDelete}
          className="icon"
          style={{ color: "#ca2228" }}
        /> */}
      </Col>
    </Row>
  );
}
