export const dateWithName = (date, name) => {
  try {
    if (date) {
      const newDate = new Date(date);
      const stringFormat = newDate.toLocaleDateString("es-MX", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      const time = newDate.toLocaleTimeString("es-MX", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${name || ""} ${stringFormat} : ${time}   `;
    }
    return name || "";
  } catch (e) {
    throw new Error(e);
  }
};

export const getTimeLeft = (fechaLLegada) => {
  if(!fechaLLegada) return console.log('no hay fecha')
  const newDate = new Date();

  const newFechaLLegada = new Date(fechaLLegada);

  const diff = (  newFechaLLegada - newDate);
  // if(diff < 0 ) return ''
  // const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) 

  return `${minutes} minutos`; 
};


export const getOnlyMinutes = (fechaLLegada) => {
  if(!fechaLLegada) return 0 
  
  const newDate = new Date();

  const newFechaLLegada = new Date(fechaLLegada);

  const diff = (  newFechaLLegada - newDate);
  // if(diff < 0 ) return '00:00'

  const minutes = Math.floor(diff / 1000 / 60) ;

  return minutes

}