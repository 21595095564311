import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RequiereAuth } from "./RequiereAuth";
import { NoRequiereAuth } from "./NoRequiereAuth";

export const ResultadosLista = ({ handleNuevo }) => {
  const history = useHistory();

  const { asegurado, servicios } = useSelector(
    (state) => state.asistenciasReducer.validaInfo
  );

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <div><b>Validador 1: </b>{asegurado.validador1}</div>
        <div><b>Validador 2: </b>{asegurado.validador2}</div>
      </div>
      <div style={{ marginTop: 5, color: '#ca2228' }}><b>Servicios</b></div>
      {servicios.map((s, i) => (
        <div
          key={i}
          className='renglon'
          onClick={() => history.push(`/detalleAsistencia/${s.asistenciaID}`)}
        >
          {s.asistenciaID} | {s.fechaCreacion} | {s.tipoAsistencia}-{s.servicio}{" "}
          | {s.estatus}
        </div>
      ))}

      {servicios.length > 0 ? <RequiereAuth handleNuevo={handleNuevo} /> : <NoRequiereAuth handleNuevo={handleNuevo} />}


    </div>
  );
};
