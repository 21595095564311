import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapaGoogle } from "../../../../components/mapaGoogle/MapaGoogle";
import { setGeoDataOrigen } from "../../../../context/actions/capturaActions";


export const Fase3Forma = () => {

  const [latLng, setLatLng] = useState({ lat: 19.364612026633605, lng: -99.18838994738087 })

  const { newLatLng } = useSelector((state) => state.asistenciasReducer);

  // useEffect(() => {
  //   setLat(Number(newLatLng.lat))
  //   setLng(Number(newLatLng.lng))
  // }, [newLatLng])

  const dispatch = useDispatch()
  const getDatos = datos => {
    const body = {
      direccionOrigen: datos.direccionCompleta,
      municipioOrigen: datos.municipio,
      estadoOrigen: datos.estado,
      cpOrigen: datos.cpOrigen,
      latOrigen: datos.lat.toString(),
      lngOrigen: datos.lng.toString(),
    }
    dispatch(setGeoDataOrigen(body));
  }

  //
  return (
    <>
      <div className="subTitulo">Geo-Datos</div>
      <MapaGoogle
        getDatos={getDatos}
        latLng={latLng}
        setLatLng={setLatLng}
      />

    </>
  );
};
