import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Radio, Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LabelText from "../../../components/input/LabelText";
import LabelTextarea from "../../../components/input/LabelTextarea";
import { ESTATUS_FINAL } from "../../../constants/cons";
import { startPostConcluirServicio } from "../../../context/actions/detalleActions";
import { formatCurrency } from "../../../utils/formatCurrency";

const options = [
  { label: "Con Costo", value: ESTATUS_FINAL.canceladoConCosto },
  { label: "Sin Costo", value: ESTATUS_FINAL.canceladoSinCosto },
];

///
export const BotonCancelar = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();

  const {
    asistencia: { costo, folioGnp, esGnpApi },
    proveedorCostos: { lp1m, lp2m },
  } = useSelector((state) => state.detalleReducer);

  const { usuarioID } = useSelector((state) => state.userReducer.user);

  const [show, setShow] = useState(false);
  const [data, setData] = useState({ costo, estatusFinal: 4 });

  const handleChange = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      costo,
    });
  }, [costo]);

  const handleOk = () => {
    const body = {
      ...data,
      costo: data.costo,
      usuarioID,
      asistenciaID,
      folioGnp, esGnpApi
    };

    setData({});
    setShow(false);
    dispatch(startPostConcluirServicio(body));
  };

  const { estatusFinalID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  if (estatusFinalID > 1) return <></>;
  return (
    <>
      <div onClick={() => setShow(true)} className="botonCancelar">
        Cancelar
      </div>

      <Modal
        title={<div style={{ color: "red" }}>Cancelar Servicio</div>}
        open={show}
        onOk={handleOk}
        onCancel={() => setShow(false)}
      >
        <Row>
          <Col
            span={6}
            style={{ color: "red", textAlign: "center", paddingTop: 10 }}
          >
            <CloseCircleOutlined style={{ fontSize: 60 }} />
          </Col>
          <Col span={18}>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                name="estatusFinal"
                value={data.estatusFinal}
                options={options}
                onChange={handleChange}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div>
              {`LP1m: ${formatCurrency(lp1m)} - LP2m: ${formatCurrency(lp2m)}`}
            </div>
            <div>
              <LabelText
                label="Costo final"
                name="costo"
                onChange={handleChange}
                value={data.costo}
              />
            </div>
            <div>
              <LabelTextarea
                value={data.comentarios}
                onChange={handleChange}
                label="Comentarios de cancelación"
                name="comentarios"
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
