import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getProveedorCostos,
  setProveedorGeos,
} from "../../../../context/actions/detalleActions";

export const useMarkers = () => {
  const dispatch = useDispatch();
  const [sucursalSelected, setSucursalSelected] = useState({});

  useEffect(() => {
    dispatch(getProveedorCostos(sucursalSelected.proveedorSucursalID));
    dispatch(
      setProveedorGeos({
        geo1: sucursalSelected.geo1,
        geo2: sucursalSelected.geo2,
      })
    );
  }, [sucursalSelected]);

  const setMarkers = (proveedorSucursales, map) => {
    let markers = null;
    let arregloPopups = [];

    ////
    proveedorSucursales.map((s, i) => {
      const {
        lat,
        lng,
        nombre,

        nombreComercial,
        proveedorSucursalID,
      } = s;

      ///
      if (lat != 0 && lng != 0) {
        var el = document.createElement("div");
        el.innerHTML = "MarkerInner_" + proveedorSucursalID;
        el.id = "marker_" + proveedorSucursalID;

        if (markers === null)
          markers = [new mapboxgl.LngLat(Number(lng), Number(lat))];
        else
          markers = [...markers, new mapboxgl.LngLat(Number(lng), Number(lat))];

        //

        arregloPopups[i] = new mapboxgl.Popup().setHTML(`
          <h1 style="color: red">${nombreComercial}</h1>
          <h3>Sucursal: ${nombre}</h3>
  
          `);

        arregloPopups[i].on("open", () => {
          setSucursalSelected(s);
        });
        ///
        new mapboxgl.Marker({
          color: "red",
          draggable: true,
        })
          .setLngLat([Number(lng), Number(lat)])
          .setPopup(arregloPopups[i])
          .addTo(map.current);
      }
    });
  };

  return { setMarkers, sucursalSelected };
};
