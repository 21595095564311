import { Card, Col } from "antd";
import React from "react";
import { URL_BASE_CLIENTES } from "../../../constants/url";
import { CampaniaItem } from "./CampaniaItem";
import { BotonElAguila } from "./botonElAguila/BotonElAguila";
const { Meta } = Card;

export const Items = ({ cliente }) => {
  const campanias = cliente.campanias && cliente.campanias;
  if (campanias.length === 0) return <></>;

  const imgUrl = `${URL_BASE_CLIENTES}/GetLogo/${cliente.clienteID}`;
  /////////////////
  return (
    <Col>
      <Meta
        title={cliente.clienteNombre}
        style={{ marginBottom: 10, textAlign: "center" }}
      />
      <Card
        hoverable
        style={{ width: 240, textAlign: "center" }}
        cover={
          <img
            style={{ height: 100, width: 210, marginTop: 10, marginLeft: 10 }}
            alt="example"
            src={imgUrl}
          />
        }
      >
        {campanias.map((c, i) => (
          <CampaniaItem key={i} campania={c} clienteID={cliente.clienteID} />
        ))}

        {cliente.clienteID == "ve7SE85lpL" && <BotonElAguila />}
      </Card>
    </Col>
  );
};
